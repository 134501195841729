<template>
  <svg
    role="button"
    :viewBox="`0 0 ${size} ${size}`"
    :width="size"
    :height="size"
    loading="lazy"
  >
    <image :href="img" :width="size" :height="size" :alt="alt" />
  </svg>
</template>
<script setup>
import { toRefs, computed } from "vue";

const props = defineProps({
  img: {
    type: String,
    default: undefined
  },
  size: {
    type: Number,
    default: 50
  },
  alt: {
    type: String,
    default: "Social media logo"
  }
});

const px = computed(() => `${size.value}px`);
const { img, size } = toRefs(props);
</script>
<style lang="scss" scoped></style>
