import { defineStore } from "pinia";
import MomentApi from "@/api/MomentApi";
import GeneralApi from "@/api/GeneralApi";

export const useMomentStore = defineStore("moment", {
  state: () => {
    return {
      moments: [],
      searchOptions: [],
      selectedMoment: {},
      sendingMoment: false,
      currentPage: 1,
      artistsTags: {},
      venuesTags: {},
      momentCount: 0,
      nextPage: null
    };
  },
  actions: {
    async getMoments(params) {
      let result = await MomentApi.getMoments({
        count: true,
        page_size: params.page_size ? params.page_size : 20,
        ...params
      });
      this.moments = result.moments;
      // this.currentPage = result.currentPage;
      this.nextPage = result.next_page;
      this.momentCount = result.count;
    },
    async getMoment(params) {
      this.selectedMoment = await MomentApi.getMoment(params);
    },
    async getArtistsMoment(params) {
      this.artistsTags = await MomentApi.getMomentTag(params);
    },
    async getVenuesMoment(params) {
      this.venuesTags = await MomentApi.getMomentTag(params);
    },
    async removeMoment(momentId) {
      await MomentApi.removeMoment(momentId);
      this.moments = this.moments.filter(({ id }) => id !== momentId);
    },
    async getSuggestions(params) {
      this.searchOptions =
        (await GeneralApi.getSuggestions(params)).lista || [];
    },
    async postMoment(params) {
      this.moments.unshift(await MomentApi.postMoment(params));
    },
    async postComment(params) {
      let comment = await MomentApi.postComment(params);
      this.selectedMoment.comments?.unshift(comment);
    },
    async postLike(params) {
      await MomentApi.postLike(params);
    },
    async reportMoment(params) {
      await MomentApi.reportMoment(params);
      const index = this.moments.findIndex(
        objeto => objeto.id === params.moment
      );
      if (index !== -1) {
        this.moments.splice(index, 1);
      }
    },
    selectMoment(moment) {
      this.selectedMoment = moment;
    }
  }
});
