<template>
  <modal-template
    v-if="previewModal === IMAGE_PREVIEW"
    size="none"
    :preview-index="imgIndex"
    :preview-id="images[imgIndex]?.id ? images[imgIndex]?.id : images.id"
    :last-index="imgIndex === images.length - 1 ? true : false"
    :is-preview="true"
    class="modal-image-preview-container"
    @close="closePreviewModal"
    @next="nextImg()"
    @previous="previousImg()"
  >
    <img
      v-if="!isVideo(images[imgIndex])"
      :src="
        images[imgIndex].thumbnails?.l_dt
          ? images[imgIndex].thumbnails?.l_dt
          : require('@/assets/images/placeholder-gallery.svg')
      "
    />
    <iframe
      v-else
      loading="lazy"
      :src="
        images[imgIndex]?.video_url
          ? images[imgIndex].video_url
          : liveVideo(images[imgIndex]?.extra_video[0]?.media_url)
      "
      :title="title"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
      class="w-full h-full"
    />
  </modal-template>
</template>
<script setup>
import ModalTemplate from "./ModalTemplate.vue";
import { useModalHandler } from "@/plugins/useModalHandler";
import { onMounted, ref, watch } from "vue";

const props = defineProps({
  index: { type: String, default: undefined },
  img: { type: String, default: undefined },
  images: { type: Array, default: undefined },
  video: { type: String, default: undefined },
  title: { type: String, default: undefined }
});
const { closePreviewModal, previewModal, IMAGE_PREVIEW } = useModalHandler();
const imgIndex = ref(0);
const liveVideo = url => {
  let id = url?.split("v=");
  return `https://www.youtube.com/embed/${id[1]}`;
};

watch(previewModal, (newValue, oldValue) => {
  if (newValue === "image-preview") {
    imgIndex.value = props.index;
  }
});
const previousImg = () => {
  if (imgIndex.value > 0) {
    imgIndex.value--;
  }
};
const isVideo = image => {
  if (image?.type === 0 && image?.video_url === "") {
    return false;
  } else if (image?.extra_video && image?.extra_video?.length === 0) {
    return false;
  } else {
    return true;
  }
};
const nextImg = () => {
  if (imgIndex.value < props.images.length) imgIndex.value++;
};
</script>
<style lang="scss">
.modal-image-preview-container {
  img {
    max-width: 100%;
    max-height: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .modal-template-body {
    width: 100%;
  }
}
</style>
