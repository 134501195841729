<template>
  <modal-template
    :size="size"
    :success-error-dialog="successErrorDialog"
    @close="$emit('close')"
  >
    <div class="row modal-success">
      <div class="flex justify-center items-center mb-10 mt-16">
        <img
          :src="require('@/assets/images/ic-report-moment.svg')"
          class="lazyload"
          alt="Modal error"
        />
        <h2 class="ml-4">{{ $t("wegow.moment.remove") }}</h2>
      </div>
      <div class="w-full text-center px-12">
        <p>{{ $t("wegow.moment.confirmRemove") }}</p>
      </div>
      <div class="buttons paragraph space-x-8 flex justify-center mb-14">
        <wegow-button
          button-id="square-banner"
          background="opacity"
          color="topaz"
          type="button"
          :text="$t('general.accept')"
          @click="
            removeMoment(moment.id);
            $emit('close');
          "
        />
        <wegow-button
          button-id="square-banner__paragraph--cancel"
          color="topaz"
          type="button"
          :text="$t('general.cancel')"
          @click="$emit('close')"
        />
      </div>
    </div>
  </modal-template>
</template>

<script setup>
import WegowButton from "@/components/general/forms/WegowButton";
import ModalTemplate from "@/components/general/modals/ModalTemplate";

import { useMoment } from "@/composables/useMoment";
const { removeMoment } = useMoment();

const props = defineProps({
  moment: {
    type: Object,
    required: true
  },
  successErrorDialog: {
    type: String,
    required: false,
    default: "success-error-dialog"
  },
  size: {
    type: String,
    required: false,
    default: "intermedium-small"
  }
});
const emit = defineEmits(["close", "accept"]);
</script>

<style scoped>
h2 {
  font-weight: 600;
  font-size: 40px !important;
  line-height: 48px;
}
p {
  list-style: auto;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
}
</style>
