<template>
  <template v-if="theme === 'button-base'">
    <wegow-button
      :button-id="`follow-${item.id}`"
      background="opacity"
      :text="buttonText"
      color="topaz-light"
      type="button"
      @click="async () => await buttonClick(type, action, item)"
    />
  </template>
  <template v-else-if="theme === 'button-xl'">
    <wegow-button
      :button-id="`follow-${item.id}`"
      size="xl"
      background="opacity"
      :text="buttonText"
      color="topaz-light"
      type="button"
      @click="async () => await buttonClick(type, action, item)"
    />
  </template>
  <template v-else-if="theme === 'button-xxl'">
    <wegow-button
      :button-id="`follow-${item.id}`"
      size="xxl"
      background="opacity"
      :text="buttonText"
      color="topaz-light"
      type="button"
      @click="async () => await buttonClick(type, action, item)"
    />
  </template>
  <template v-else-if="theme === 'follow-profile-btn'">
    <wegow-button
      :button-id="`follow-${item.id}`"
      size="follow-profile-btn"
      background="transparent"
      :text="buttonText"
      color="white"
      border="topaz"
      type="button"
      @click="async () => await buttonClick(type, action, item)"
    />
  </template>
  <template v-else-if="theme === 'text'">
    <p
      class="cursor-pointer flex items-center justify-end"
      @click="async () => await buttonClick(type, action, item)"
    >
      {{ buttonText }}
      <img
        :src="require(`@/assets/images/${buttonImage}`)"
        :alt="$t('wegow.altIcon')"
        class="ml-2"
        :class="{ 'w-3 h-3': size === 'small', 'w-4 h-4': size === 'base' }"
        loading="lazy"
      />
    </p>
  </template>
</template>
<script setup>
import WegowButton from "@/components/general/forms/WegowButton";

import { useArtist } from "@/composables/useArtist";
import { useCampaign } from "@/composables/useCampaign";
import { useCompany } from "@/composables/useCompany";
import { useEvent } from "@/composables/useEvent";
import { useUser } from "@/composables/useUser";
import { useVenue } from "@/composables/useVenue";
import { useModalHandler } from "@/plugins/useModalHandler";
import { computed, toRefs } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const props = defineProps({
  item: {
    type: Object,
    default: () => {}
  },
  type: {
    type: String,
    default: undefined
  },
  theme: {
    type: String,
    default: "button-xl"
  },
  size: {
    type: String,
    default: "base"
  }
});

const { type, item } = toRefs(props);
const { openModal, SIGN_IN } = useModalHandler();

const { isAuth } = useUser();
const { followEvent, unfollowEvent } = useEvent();
const { followCampaign, unfollowCampaign } = useCampaign();
const { followArtist, unfollowArtist } = useArtist();
const { followVenue, unfollowVenue } = useVenue();
const { followCompany, unfollowCompany } = useCompany();

const isFollowing = computed(() => item.value?.user?.following);
const buttonText = computed(() =>
  isFollowing.value
    ? t("general.buttons.followed")
    : t("general.buttons.follow")
);
const buttonImage = computed(() =>
  isFollowing.value ? "ic-unfollow.svg" : "ic-follow.svg"
);
const action = computed(() => (isFollowing.value ? "unfollow" : "follow"));

const actions = {
  event: { follow: followEvent, unfollow: unfollowEvent },
  campaign: { follow: followCampaign, unfollow: unfollowCampaign },
  artist: { follow: followArtist, unfollow: unfollowArtist },
  venue: { follow: followVenue, unfollow: unfollowVenue },
  company: { follow: followCompany, unfollow: unfollowCompany }
};

const buttonClick = async (type, action, item) => {
  if (!isAuth.value) openModal(SIGN_IN);
  if (isAuth.value) await actions[type]?.[action](item);
};

// watchEffect(() => (isFollowing.value = item.value?.user.following));
</script>
<style lang="scss" scoped></style>
