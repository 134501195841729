import apiService from "@/api/ApiService";

const LOCATION_SEARCH_URL = "/location-search/";
const GENRE_URL = "/genres/";
const REGIONS_URL = "/regions/";
const FOOTER_URL = "/footer/";
const NAVBAR_URL = "/menu-config/";
const CREATE_IMPORTS_URL = "/import/{0}/start/";
const IMPORT_ALL_URL = "/import/{0}/all/";
const SUGGESTION_URL = "/suggestion/";
const EVENT_VALIDATIONS_URL = "/totals-validation/";

// TODO: move to string utils before sockets update
const format = (template, ...args) => {
  return template.replace(/{([0-9]+)}/g, function(match, index) {
    return typeof args[index] === "undefined" ? match : args[index];
  });
};

export class GeneralService {
  async getLocations(params) {
    return (await apiService.get(LOCATION_SEARCH_URL, { params: params })).data;
  }

  async deleteLocation(params) {
    return (await apiService.delete(LOCATION_URL, { params: params })).data;
  }

  async getGenres(params) {
    return (await apiService.get(GENRE_URL, { params: params })).data;
  }

  async getSuggestions(params) {
    return (await apiService.get(SUGGESTION_URL, { params: params })).data;
  }

  async getRegions(params) {
    return (await apiService.get(REGIONS_URL, { params: params })).data;
  }

  async getNavbar() {
    return (await apiService.get(NAVBAR_URL)).data;
  }

  async getFooter() {
    return (await apiService.get(FOOTER_URL)).data;
  }

  async getSpotifyImports({ params, query }) {
    return await apiService.post(
      format(CREATE_IMPORTS_URL, "spotify"),
      params,
      {
        params: query
      }
    );
  }

  async importAllSpotify() {
    return await apiService.post(format(IMPORT_ALL_URL, "spotify"));
  }

  async getEventValidation(validationCode) {
    return await apiService.get(`${EVENT_VALIDATIONS_URL}${validationCode}`);
  }
}

const generalService = new GeneralService();

export default generalService;
