<template>
  <div />
</template>

<script setup>
import { onBeforeMount } from "vue";
import { useUser } from "@/composables/useUser";
import sha256 from "crypto-js/sha256";

const { userDetail } = useUser();

const props = defineProps({
  pixel: {
    type: Object,
    default: undefined
  },
  type: {
    type: String,
    default: undefined
  }
});

const addPixel = () => {
  ttq.track(props.type, JSON.parse(JSON.stringify(props.pixel)));
  ttq.track("ViewContent", JSON.parse(JSON.stringify(props.pixel)));
  if (userDetail.value.id) {
    ttq.identify({
      external_id: sha256(userDetail.value.id)
    });
  }
};

onBeforeMount(() => {
  addPixel();
});
</script>

<style></style>
