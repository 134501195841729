<template>
  <div class="artist-card flex" :class="type">
    <div class="card-image">
      <w-navigation
        :object="artist"
        :params="{
          name: 'ArtistProfile',
          params: {
            slug: artist.slug
          }
        }"
        :blank="openExternal"
        :is-link="isLink"
        class="h-full"
      >
        <template #link>
          <img
            :src="getImageUrl(artist)"
            :alt="artist.name"
            class="main-image"
            loading="lazy"
            @error="
              $event.target.src = require('@/assets/images/artist-placeholder.svg')
            "
          />
        </template>
      </w-navigation>
    </div>
    <div
      class="card-info w-1/2 flex flex-col justify-between pl-3 pt-3 pb-2 flex-1 text-left"
    >
      <div class="info">
        <w-navigation
          :object="artist"
          :params="{
            name: 'ArtistProfile',
            params: {
              slug: artist.slug
            }
          }"
          :blank="openExternal"
          :is-link="isLink"
        >
          <template #link>
            <h3 class="name text-start font-museo-700">
              {{ artist.name }}
            </h3>
          </template>
        </w-navigation>
        <p
          class="artist-concerts text-start font-museo-500 text-color-grayLight"
          :class="type === 'small' ? 'mt-0' : 'mt-2'"
        >
          {{ artist.events_count }} {{ $t("general.concerts") }}
        </p>
      </div>
      <div class="actions font-museo-500 text-color-topaz-light mr-2">
        <follow-button :item="artist" type="artist" theme="text" :size="type" />
      </div>
    </div>
  </div>
</template>

<script setup>
import FollowButton from "@/components/general/FollowButton.vue";
import WNavigation from "@/components/general/ui/WNavigation";

// TODO: añadir los composables necesarios
defineProps({
  isLink: {
    type: Boolean,
    default: true
  },
  artist: {
    type: Object,
    default: undefined
  },
  type: {
    type: String,
    default: "base"
  },
  openExternal: {
    type: Boolean,
    default: false
  }
});
defineEmits(["follow"]);

const followArtist = () => {
  // TODO:
};

const unfollowArtist = () => {
  // TODO:
};

const getImageUrl = element => {
  if (element?.thumbnails_v4?.s_cd) return element.thumbnails_v4.s_cd;
  if (element?.thumbnails?.s_cd) return element.thumbnails.s_cd;
  if (element?.image_url) return element.image_url;
  return require("@/assets/images/artist-placeholder.svg");
};
</script>

<style scoped lang="scss">
.artist-card {
  background-color: #1c1d29;
  overflow: hidden;
  border-radius: 6px;
  @media screen and (max-width: $screen-md) {
    width: 100%;
    height: 90%;
    font-size: 11px !important;
  }
  .card-image {
    @media screen and (max-width: $screen-md) {
      width: 40%;
    }
    img {
      object-fit: cover;
      height: 158px;
      width: 158px;
      @media screen and (max-width: $screen-md) {
        width: 100%;
        height: 100%;
      }
    }
  }
  .name {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: start;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  .card-info {
    color: $white;
    font-size: 1rem;
    @media screen and (max-width: $screen-md) {
      font-size: 11px;
    }
    .actions p {
      color: $topaz;
    }
  }
  &.base {
    width: 378px;
    height: 158px;
  }
  &.medium {
    width: 283px;
    height: 120px;
    img {
      height: 120px;
      width: 120px;
    }
    .card-info {
      font-size: 12px;
      padding-bottom: 1rem;
      p {
        font-size: 10px;
      }
    }
  }
  &.small {
    height: 96px;
    width: 250px;
    .card-info {
      font-size: 12px;
    }
    img {
      height: 96px;
      width: 104px;
    }
    @media screen and (max-width: $screen-md) {
      width: 197px;
      height: 78px;
      img {
        height: 78px;
        width: 78px;
      }
      .card-info {
        font-size: 11px;
      }
    }
  }
}
</style>
