import { defineStore } from "pinia";
import VenueApi from "@/api/VenueApi";

export const useVenueStore = defineStore("venue", {
  state: () => {
    return {
      details: {},
      venues: [],
      venuesRelatedToVenue: [],
      venuesRelatedToArtist: [],
      venuesRelatedToUser: [],
      venuesRelatedToEvent: [],
      media: []
    };
  },
  actions: {
    async getVenueMedia(id) {
      this.media = await VenueApi.getVenueMedia(id);
    },
    async getDetails(slug) {
      this.details = await VenueApi.getVenue(slug);
    },
    async getVenues(params) {
      this.venues = await VenueApi.getVenues(params);
    },
    async getVenuesRelatedToVenue(params) {
      this.venuesRelatedToVenue = (await VenueApi.getVenues(params)).venues;
    },
    async getVenuesRelatedToEvent(params) {
      this.venuesRelatedToEvent = (await VenueApi.getVenues(params)).venues;
    },
    async followVenue(params) {
      await VenueApi.followVenue(params);
    },
    async unfollowVenue(params) {
      await VenueApi.unfollowVenue(params);
    }
  }
});
