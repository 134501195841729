import apiService from "@/api/ApiService";

const CHATS = "/chats/";
const MEMBERS = "/chat-members/";
const MESSAGES = "/chat-messages/";

export class ChatService {
  async getChat(id) {
    return (await apiService.get(`${CHATS}${id}`)).data;
  }

  async getChats(params) {
    return (await apiService.get(CHATS, { params })).data;
  }

  async getChatMembers(params) {
    return (await apiService.get(MEMBERS, { params })).data;
  }

  async createChat(params) {
    return (await apiService.post(CHATS, params)).data;
  }

  async removeChat(params) {
    return (await apiService.delete(MEMBERS, { params })).data;
  }

  async getChatMessages(params) {
    return (await apiService.get(MESSAGES, { params })).data;
  }

  async joinChat(params) {
    return (await apiService.post(MEMBERS, params)).data;
  }
}

const chatService = new ChatService();

export default chatService;
