import { defineStore } from "pinia";
import ArtistApi from "@/api/ArtistApi";

export const useArtistStore = defineStore("artist", {
  state: () => {
    return {
      details: {},
      artists: [],
      genresTopArtists: {},
      artistsRelatedToArtist: [],
      artistsRelatedToVenue: [],
      artistsRelatedToEvent: [],
      artistsForImport: [],
      media: [],
      locations: []
    };
  },

  actions: {
    async getArtist(slug) {
      this.details = await ArtistApi.getArtist(slug);
    },
    async getArtists(params) {
      this.artists = await ArtistApi.getArtists(params);
    },
    async getInfiniteArtists(params) {
      const previousPageArtists = this.artists;
      const newPageArtists = await ArtistApi.getArtists(params);
      this.artists.artists = previousPageArtists.artists.concat(
        newPageArtists.artists
      );
    },
    async getArtistsRelatedToArtist(params) {
      this.artistsRelatedToArtist = (
        await ArtistApi.getArtists(params)
      ).artists;
    },
    async getArtistsRelatedToEvent(params) {
      this.artistsRelatedToEvent = (await ArtistApi.getArtists(params)).artists;
    },
    async getArtistMedia(params) {
      this.media = (await ArtistApi.getArtistMedia(params)).media;
    },
    async followArtist(params) {
      await ArtistApi.followArtist(params);
    },
    async unfollowArtist(params) {
      await ArtistApi.unfollowArtist(params);
    },
    async getTopGenreArtists(params) {
      this.genresTopArtists[`${params.genres}`] = (
        await ArtistApi.getArtists(params)
      ).artists;
    },
    async getArtistsForImport(params) {
      this.artistsForImport = (await ArtistApi.getArtists(params)).artists;
    },
    async getLocations(params) {
      this.locations = (await ArtistApi.getLocations(params)).locations;
    }
  }
});
