import { VueCookieNext } from "vue-cookie-next";

const $cookie = VueCookieNext;

export async function checkRegion({ to, next }) {
  try {
    if (
      $cookie.getCookie("wegow.user-region") &&
      ($cookie.getCookie("wegow.user-region").length === 2 ||
        $cookie.getCookie("wegow.user-region").includes("-"))
    ) {
      return next({
        path: "/" + $cookie.getCookie("wegow.user-region"),
        query: to.query
      });
    } else if (to.query["confirm-email"]) {
      return next({
        path: "/es",
        query: to.query
      });
    } else {
      return next();
    }
  } catch (error) {
    console.error(error);
  }
}
