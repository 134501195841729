<template>
  <modal-template
    v-if="activeModal === SIGN_UP_PURCHASE"
    size="intermedium-small"
    :is-static="false"
    @close="canCloseModal ? closeModal() : ''"
  >
    <wegow-loader v-if="loading" class="load-more-loader" :middle="true" />
    <div
      v-else
      id="containerSignup"
      class="container-onboarding pt-12 pb-8 mx-16"
    >
      <h3 class="mb-8 text-center">{{ $t("wegow.login.signupTitle") }}</h3>
      <div>
        <div>
          <form class="space-y-2" @submit.prevent="signupEmail">
            <wegow-input
              id="email-signup"
              v-model:value="model.email"
              name="email"
              type="email"
              :show-error-always="showErrorAlways"
              :placeholder="$t('wegow.login.email')"
              :error-message="valid.email ? '' : 'wegow.login.emailError'"
              :validator="validators.email"
              @valid="valid.email = $event"
            />
            <wegow-input
              id="email-confirmation-signup"
              v-model:value="model.emailConfirmation"
              name="confirmation"
              :placeholder="$t('wegow.login.confirmEmail')"
              :show-error-always="showErrorAlways"
              :error-message="
                !valid.confirmation && valid.email
                  ? 'wegow.login.confirmationError'
                  : ''
              "
              :validator="validators.confirmation"
              @valid="valid.confirmation = $event"
            />
            <wegow-input
              id="password-signup"
              v-model:value="model.password"
              name="password"
              type="password"
              :placeholder="$t('wegow.login.password')"
              :show-error-always="showErrorAlways"
              :error-message="valid.password ? '' : 'wegow.login.passwordError'"
              :validator="validators.password"
              @valid-change="valid.password = $event"
            />
            <phone-selector
              :phone-selected="model.phone"
              :error-message="errors?.phone"
              :validator="validators.phone"
              :error="showErrorAlways && !(model.phone?.phone?.length > 0)"
              @select-country="model.phone.country = $event"
              @change-phone="model.phone.phone = $event"
              @valid-change="valid.phone = $event"
              @open-search="canCloseModal = !$event"
            />

            <div class="flex space-x-2">
              <wegow-input
                id="profile-user-firstname"
                v-model:value="model.first_name"
                class="w-1/2"
                :placeholder="$t('wegow.profile.settings.firstName')"
                :show-error-always="showErrorAlways"
                :validator="validators.first_name"
                @valid="valid.first_name = $event"
              />
              <wegow-input
                id="profile-user-lastname"
                v-model:value="model.last_name"
                class="w-1/2"
                :placeholder="$t('wegow.profile.settings.lastName')"
                :show-error-always="showErrorAlways"
                :validator="validators.last_name"
                @valid="valid.last_name = $event"
              />
            </div>

            <wegow-input
              id="profile-user-birthdate"
              v-model:value="model.birthday"
              type="date"
              :error-message="valid.birthday ? '' : 'wegow.login.birthdayError'"
              :show-error-always="showErrorAlways"
              :validator="validators.birthday"
              @valid="valid.birthday = $event"
            />
            <cities-search
              id="profile-user-location"
              v-model:value="model.location"
              :placeholder="$t('wegow.profile.settings.formLocation')"
              :show-error-always="showErrorAlways"
            />
            <div class="mx-8 pt-4 flex flex-row justify-between">
              <w-radio
                id="genre_radio_male"
                v-model:group-value="model.genre"
                value="m"
                name="genre"
                label="Hombre"
              />
              <w-radio
                id="genre_radio_female"
                v-model:group-value="model.genre"
                value="f"
                name="genre"
                label="Mujer"
              />
              <w-radio
                id="genre_radio_other"
                v-model:group-value="model.genre"
                name="genre"
                label="Otro"
              />
            </div>

            <div class="pt-3 custom-control custom-checkbox">
              <wegow-checkbox
                id="terms-accepted-checkbox"
                v-model:value="model.terms_accepted"
                :html-title="true"
                :error="showErrorAlways && !model.terms_accepted"
                :title="
                  $t('wegow.login.termsAndConditions', {
                    termsUrl: termsAndConditionsUrl,
                    PrivacityUrl: PrivacyPolicyUrl
                  })
                "
              />
            </div>
            <div class="custom-control custom-checkbox pb-4">
              <wegow-checkbox
                id="terms-accepted-checkbox"
                v-model:value="model.newsletter"
                :title="$t('wegow.login.reciveNews')"
              />
            </div>
            <span v-if="signUpError" class="text-orange-errors text-center"
              >El email ya se encuentra en uso</span
            >
            <div class="flex justify-center">
              <wegow-button
                button-id="confirm-signup-button"
                background="opacity"
                color="topaz"
                :text="$t('wegow.login.signup')"
                :disabled="isDisabled"
                type="submit"
                class="btn btn-signup"
                @click-disabled="checkForm()"
              />
            </div>
          </form>
        </div>
        <!-- <div class="or-title my-5">
          <span class="or-line">{{ $t("wegow.login.or") }}</span>
        </div>
        <div class="flex justify-center space-x-4">
          <img
            :src="require('@/assets/images/login-google.svg')"
            class="external-login-icon"
            alt="SignUp con Google"
          />
          <img
            :src="require('@/assets/images/login-spotify.svg')"
            class="external-login-icon"
            alt="SignUp con Spotify"
          />
          <img
            :src="require('@/assets/images/login-youtube.svg')"
            class="external-login-icon"
            alt="SignUp con Youtube"
          />
        </div> -->
        <div class="text-center">
          <p
            class="text-signup underline cursor-pointer mt-6"
            @click="openModal(SIGN_IN_PURCHASE)"
          >
            {{ $t("wegow.login.haveAccount") }}
          </p>
        </div>
      </div>
    </div>
  </modal-template>
</template>

<script setup>
import WegowButton from "@/components/general/forms/WegowButton";
import WegowCheckbox from "@/components/general/forms/WegowCheckbox";
import WegowInput from "@/components/general/forms/WegowInput";
import CitiesSearch from "@/components/general/forms/selectors/CitiesSearch.vue";
import WRadio from "@/components/general/forms/WRadio.vue";
import PhoneSelector from "@/components/general/forms/complex-forms/PhoneSelector";
import ModalTemplate from "@/components/general/modals/ModalTemplate.vue";
import WegowLoader from "@/components/general/ui/WegowLoader";

import { useUser } from "@/composables/useUser";
import { usePurchase } from "@/composables/usePurchase";
import { COUNTRIES } from "@/constants.js";
import { useModalHandler } from "@/plugins/useModalHandler";
import { computed, onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const { signUp } = useUser();
const { createPurchase } = usePurchase();
const router = useRouter();

const {
  activeModal,
  SIGN_UP_PURCHASE,
  SIGN_IN_PURCHASE,
  openModal,
  closeModal
} = useModalHandler();

const props = defineProps({
  origin: {
    type: String,
    default: ""
  },
  newPromoter: {
    type: Boolean,
    default: false
  }
});

const modelTemplate = {
  email: undefined,
  emailConfirmation: undefined,
  password: undefined,
  phone: {
    country: {},
    phone: undefined
  },
  location: {
    id: 0,
    name: "",
    place_id: "",
    longitude: 0,
    latitude: 0
  },
  first_name: "",
  last_name: "",
  birthday: undefined,
  genre: undefined,
  newsletter: false,
  terms_accepted: false
};

const model = reactive({ ...modelTemplate });

const loading = ref(false);
const canCloseModal = ref(true);
const showErrorAlways = ref(false);

const valid = reactive({
  email: false,
  password: false,
  confirmation: false,
  phone: false,
  first_name: false,
  last_name: false,
  birthday: false
});

const validators = {
  birthday: {
    validate: birthday => {
      try {
        let fechaActual = new Date();

        let partesFecha = birthday.split("-");
        let fechaCumple = new Date(
          partesFecha[0],
          partesFecha[1] - 1,
          partesFecha[2]
        );
        let diferenciaTiempo = fechaActual - fechaCumple;

        let edadEnAnios = Math.floor(
          diferenciaTiempo / (1000 * 60 * 60 * 24 * 365.25)
        );

        if (edadEnAnios >= 0) {
          return true;
        } else {
          return false;
        }
      } catch (e) {
        return false;
      }
    }
  },
  confirmation: {
    depends: [model],
    validate: confirmation => confirmation === model.email && valid.email
  },
  email: { validate: email => EMAIL_REGEX.test(email || "") },
  first_name: { validate: first_name => first_name.length > 0 },
  last_name: { validate: last_name => last_name.length > 0 },
  password: { validate: password => /^.{8,}$/.test(password || "") },
  phone: { validate: phone => /^\d{4,}$/.test(phone) }
};

const isDisabled = computed(
  () =>
    Object.values(valid).includes(false) ||
    model.location.name === "" ||
    !model.terms_accepted
);
const termsAndConditionsUrl = computed(() => {
  return router.resolve({
    name: "TermsOfUsePage",
    params: { region: "es" }
  }).href;
});

const PrivacyPolicyUrl = computed(() => {
  return router.resolve({
    name: "PrivacyPolicyPage",
    params: { region: "es" }
  }).href;
});

const signUpError = ref(null);

const checkForm = () => {
  showErrorAlways.value = true;
};

const signupEmail = async () => {
  try {
    signUpError.value = null;
    loading.value = true;
    await signUp(model, true);
    await createPurchase(true);
    closeModal();
  } catch (error) {
    signUpError.value = error;
  }
  loading.value = false;
};

onMounted(() => {
  model.phone.country = COUNTRIES.filter(country => country.iso2 === "es")[0];
});
</script>

<style scoped lang="scss">
#containerSignup {
  h3 {
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
  }
  .or-title {
    position: relative;
    display: block;
    width: 100%;
    margin: 10px 0;
    text-align: center;
    font-size: 16px;
    z-index: 1;
    overflow: hidden;
    &:before,
    &:after {
      content: "\a0";
      position: absolute;
      top: 51%;
      width: 25%;
      height: 1px;
      background-color: $white;
      overflow: hidden;
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
  }
}
</style>
