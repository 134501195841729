<template>
  <div v-if="bigLoader" id="background-loader">
    <div class="internal-loader">
      <wegow-loader />
    </div>
  </div>
</template>

<script setup>
import WegowLoader from "@/components/general/ui/WegowLoader";
import { useModalHandler } from "@/plugins/useModalHandler";
const { bigLoader } = useModalHandler();
</script>

<style lang="scss" scoped>
#background-loader {
  position: fixed;
  background-color: $backgroundBlackOpacity5;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  .internal-loader {
    position: sticky;
    top: 50%;
  }
}
</style>
