<template>
  <div class="card-pay-container">
    <form class="pay-card-form" @submit.prevent="createCardPay">
      <slot name="top-extra"></slot>
      <fieldset class="number-fieldset">
        <wegow-input
          id="card-number"
          ref="momentInput"
          v-model:value="cardData.number"
          class="w-full card-info-input card-number"
          label-for="query"
          :placeholder="$t('wegow.payment.number')"
          border-input="border-transparent"
          :error-message="!cardNumberValid ? 'Error al introducir tarjeta' : ''"
          :show-error="true"
          @change="checkNumber()"
          @update:value="checkNumber()"
          @keyup="checkNumber()"
          @paste="checkNumber()"
        ></wegow-input>
        <span class="card-type-img">
          <img
            v-if="cardData.number === '' || !cardData.number"
            :src="require('@/assets/images/ic-card-white.svg')"
            :alt="$t('wegow.payment.paymentIconCard')"
          />
          <img
            v-if="firstChar === '4'"
            :src="require('@/assets/images/ic-visa.svg')"
            :alt="$t('wegow.payment.paymentIconVisa')"
          />
          <img
            v-if="firstChar === '5'"
            :src="require('@/assets/images/ic-mastercard.svg')"
            :alt="$t('wegow.payment.paymentIconMastercard')"
          />
        </span>
      </fieldset>
      <fieldset class="grid md:grid-cols-2 grid-cols-1 gap-1">
        <wegow-input
          id="card-date"
          ref="momentInput"
          v-model:value="cardData.date"
          class="md:col-span-1 col-span-2 card-info-input"
          label-for="query"
          :placeholder="$t('wegow.payment.cardDate')"
          min-length="5"
          max-length="5"
          border-input="border-transparent"
          :error-message="!cardDateValid ? 'Error' : ''"
          :show-error="true"
          @change="checkDate($event)"
          @keyup="checkDate($event)"
        ></wegow-input>
        <wegow-input
          id="card-cvc"
          ref="momentInput"
          v-model:value="cardData.cvc"
          class="md:col-span-1 col-span-2 card-info-input"
          label-for="query"
          min-length="3"
          max-length="3"
          :placeholder="$t('wegow.payment.cvc')"
          border-input="border-transparent"
          :error-message="!cardCVCValid ? 'Error' : ''"
          :show-error="true"
          @change="checkCVC()"
          @keyup="checkCVC()"
        ></wegow-input>
      </fieldset>
      <slot name="extra"></slot>
      <fieldset>
        <wegow-button
          type="submit"
          class="pay-btn"
          button-id="pay-btn"
          :text="$t(textButton)"
          size="xl"
          color="topaz-light"
          background="opacity"
          :disabled="loading || disabledButton"
          :styles="getPaymentButton"
        >
        </wegow-button>
      </fieldset>
    </form>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useI18n } from "vue-i18n";

import { usePayment } from "@/composables/usePayment";
import { useIframeStyle } from "@/composables/useIframeStyle";
import moment from "moment";

import WegowInput from "@/components/general/forms/WegowInput";
import WegowButton from "@/components/general/forms/WegowButton";
import { useModalHandler } from "@/plugins/useModalHandler";

const { paymentData } = usePayment();
const { openErrorModal, openBigLoader, closeBigLoader } = useModalHandler();
const { getPersonalizedButton, getPaymentButton } = useIframeStyle();

const { t } = useI18n();
const cardData = ref({
  number: "",
  date: "",
  cvc: ""
});
const cardValue = ref("");
const loading = ref(false);
const cardNumberValid = ref(false);
const cardCVCValid = ref(false);
const cardDateValid = ref(false);
const firstChar = ref("");
const mask = ref("");
const maskedValue = ref("");
const acceptedCreditCards = ref({
  visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
  mastercard: /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/,
  discover: /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/,
  diners_club: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
  jcb: /^(?:2131|1800|35[0-9]{3})[0-9]{11}$/
});

defineProps({
  textButton: {
    type: String,
    default: "wegow.payment.cardActionPay"
  },
  disabledButton: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits([
  "cardPayment",
  "update:type",
  "update:validCard",
  "update:validDate",
  "update:validCvc",
  "cardData"
]);

const handleInput = event => {
  // Remover caracteres no numéricos
  const numericValue = event?.replace(/\D/g, "");

  // Aplicar máscara
  if (numericValue?.length > 0) {
    maskedValue.value = applyCreditCardMask(numericValue);
  }

  cardData.value.number = maskedValue.value;
};
const applyCreditCardMask = value => {
  const cardNumberRegex = /(\d{1,4})/g;
  const cardNumberParts = value.match(cardNumberRegex);

  if (cardNumberParts) {
    maskedValue.value = cardNumberParts.join(" ");

    // Añadir espacios en blanco para completar los grupos de 4 dígitos
    const lastGroupLength = cardNumberParts[cardNumberParts.length - 1].length;
    if (lastGroupLength === 4) {
      maskedValue.value += " ";
    }
  } else {
    maskedValue.value = value;
  }
  return maskedValue.value;
};
const createCardPay = () => {
  if (!loading.value) {
    loading.value = true;
    openBigLoader();
    const date = cardData.value.date.split("/");
    if (cardNumberValid.value && cardDateValid.value && cardCVCValid.value) {
      paymentData.value.details = {
        pan: cardData.value.number.replace(/\D/g, ""),
        expiry_year: date[1],
        expiry_month: date[0],
        cvv2: cardData.value.cvc,
        type: "three_ds",
        save_card: false
      };
      emit("cardData", cardData);
      emit("cardPayment");
      loading.value = false;
    } else {
      loading.value = false;
      openErrorModal({
        modal: {
          title: t("wegow.error.formFieldCardError"),
          description: t("wegow.error.formFieldCardErrorDescription"),
          btn_message: t("wegow.error.accept")
        }
      });
      closeBigLoader();
    }
  }
};
// Check Number
const checkNumber = () => {
  setTimeout(() => {
    cardValue.value = cardData.value.number
      ? cardData.value.number.replace(/\D/g, "")
      : "";
    firstChar.value = cardValue.value.charAt(0);
    const value = cardValue.value;
    let sum = 0;
    let shouldDouble = false;
    for (let i = value.length - 1; i >= 0; i--) {
      let digit = parseInt(value.charAt(i));

      if (shouldDouble) {
        if ((digit *= 2) > 9) digit -= 9;
      }

      sum += digit;
      shouldDouble = !shouldDouble;
    }

    let valid = sum % 10 === 0;
    let accepted = false;
    let type = "";
    Object.keys(acceptedCreditCards.value).forEach(key => {
      let regex = acceptedCreditCards.value[key];
      if (regex.test(value)) {
        accepted = true;
        type = key;
      }
    });
    cardNumberValid.value = accepted;
    emit("update:type", accepted ? type : "");
    emit("update:validCard", true);
    if (!valid || !accepted) emit("update:validCard", false);
  }, 100);
  handleInput(cardData.value.number);
};
// Check Date
const checkDate = event => {
  let letter = "";
  if (
    event.target &&
    event.target.value.length > 2 &&
    event.code !== "Backspace" &&
    event.code !== "Delete" &&
    !cardData.value.date.includes("/")
  ) {
    for (let i = 0; i < event.target.value.length && i < 4; i++) {
      letter += event.target.value[i];
      if (i === 1) {
        letter += "/";
      }
    }
    cardData.value.date = letter;
  }
  let date = cardData.value.date.split("/");
  if (date) {
    let day = moment(`20${date[1]}-${date[0]}`)
      .endOf("month")
      .format("DD");
    let totalDate = `${date[0]}/${day}/${date[1]}`;
    if (moment().diff(totalDate, "days") <= 0) {
      cardDateValid.value = true;
      emit("update:validDate", true);
    } else {
      cardDateValid.value = false;
      emit("update:validDate", false);
    }
  }
};

// Check CVC
const checkCVC = () => {
  emit("update:validCvc", true);
  let cvc = cardData.value.cvc
    ? cardData.value.cvc.replace(/\D/g, "")
    : undefined;
  if (!/^\d{3}$/.test(cvc)) {
    emit("update:validCvc", false);
    cardCVCValid.value = false;
  } else {
    cardCVCValid.value = true;
  }
};
</script>

<style scoped>
.pay-card-form {
  @apply gap-1 flex flex-col;
  text-align: center;
}
.pay-btn {
  margin-top: 12px;
  margin-bottom: 12px;
}
.number-fieldset {
  position: relative;
}
.card-type-img {
  width: 24px;
  position: absolute;
  top: 15px;
  right: 15px;
}
.card-number {
  position: relative;
}
</style>
