<template>
  <div class="insurance-form">
    <div class="insurance-checkbox">
      <wegow-checkbox
        id="insurance-checkbox"
        v-model:value="termsAccepted"
        :title="
          $t('wegow.payment.insurance.addInsurance', {
            precio: formatCurrency(
              purchaseData.insurance_premium,
              purchaseData.currency
            )
          })
        "
      />
      <img
        class="h-7 lazyload"
        src="https://cdn.wegow.com/no-delete/wegow/ingood-seguros.png"
        alt="ingood-reale-seguros"
      />
    </div>
    <div class="data">
      <wegow-input
        :id="`${id}__insurance_id_card`"
        v-model:value="value.id_card"
        :validator="validators.id_card"
        show-error-always
        class="id_card"
        type="text"
        border-input="border-transparent"
        placeholder="NIF/NIE"
        @valid-change="(validations.id_card = $event) && insure"
      />
      <wegow-input
        :id="`${id}__insurance_postal_code`"
        v-model:value="value.postal_code"
        type="number"
        min="0"
        step="1"
        class="postal_code"
        show-error-always
        :validator="validators.postal_code"
        :placeholder="$t('wegow.payment.insurance.insuranceModal.postalCode')"
        border-input="border-transparent"
        @valid-change="(validations.postal_code = $event) && insure"
      />
    </div>
  </div>
</template>

<script setup>
import WegowInput from "@/components/general/forms/WegowInput.vue";
import WegowCheckbox from "@/components/general/forms/WegowCheckbox.vue";

import { usePurchase } from "@/composables/usePurchase";
import { toRefs, ref, watch, reactive, computed } from "vue";
import { formatCurrency } from "@/plugins/currency";
import { useInsurance } from "@/composables/useInsurance";

const { purchaseData } = usePurchase();
const { createInsurance, cancelInsurance } = useInsurance();

const NIE_LETTERS = "XYZ";
const ID_LETTERS = "TRWAGMYFPDXBNJZSQVHLCKE";

const emit = defineEmits(["update:is-insured"]);
const props = defineProps({
  id: { type: String, required: true },
  isInsured: { type: Boolean, default: false },
  dontAutoSend: { type: Boolean, default: false }
});

const { dontAutoSend } = toRefs(props);

const termsAccepted = ref(false);
const lastInsured = ref(null);
const validations = reactive({ id_card: false, postal_code: true });
const value = reactive({
  purchase: computed(() => purchaseData.value.reference),
  postal_code: ref(undefined),
  id_card: ref(undefined),
  id_card_type: ref(undefined),
  general_conditions: ref(true)
});

const validators = {
  id_card: { validate: id_card => isValidIdCard(id_card) },
  postal_code: {
    validate: postal_code => /^[0-9]{5}$/.test(postal_code)
  }
};

const validInsurance = computed(
  () => validations.postal_code && validations.id_card
);

const isValidIdCard = idCardNumber => {
  if (!idCardNumber) return false;
  const letterCount = idCardNumber.match(/[a-zA-Z]/g)?.length;
  value.id_card_type = "nif"; // NIF
  if (letterCount === 2) {
    value.id_card_type = "nie"; // NIE
    const nieLetterIndex = NIE_LETTERS.indexOf(idCardNumber[0].toUpperCase());
    if (nieLetterIndex === -1) return false;
    idCardNumber =
      nieLetterIndex + idCardNumber.substring(1, idCardNumber.length);
  }
  const idCardLetter = idCardNumber
    .charAt(idCardNumber.length - 1)
    .toUpperCase();
  const idNum = idCardNumber.substring(0, idCardNumber.length - 1);
  const letterCalc = idNum % 23;
  if (letterCalc > ID_LETTERS.length) return;
  return ID_LETTERS[letterCalc] === idCardLetter;
};

const insure = async () => {
  const payload = {
    policy: {
      postal_code: value.postal_code,
      id_card: value.id_card,
      id_card_type: value.id_card_type,
      general_conditions: true,
      purchase: value.purchase
    }
  };
  if (JSON.stringify(lastInsured.value) !== JSON.stringify(payload)) {
    lastInsured.value = payload;
    await createInsurance(payload);
    emit("update:is-insured", true);
  }
};

let timeout;
watch([termsAccepted, validations], async () => {
  if (dontAutoSend.value) return;
  if (timeout) clearTimeout(timeout);
  timeout = setTimeout(async () => {
    if (termsAccepted.value && validations.postal_code && validations.id_card) {
      await insure();
    } else if (lastInsured.value) {
      await cancelInsurance(purchaseData.value.reference);
      lastInsured.value = null;
      emit("update:is-insured", false);
    }
  }, 500);
});
defineExpose({ policy: value, valid: validInsurance });
</script>

<style scoped lang="scss">
.insurance-form {
  .insurance-checkbox {
    @apply flex flex-row justify-between mb-1;
  }
  .data {
    @apply grid lg:grid-cols-10 gap-1;
    .id_card {
      @apply lg:col-span-6;
    }
    .postal_code {
      @apply lg:col-span-4;
    }
  }
}
</style>
