import RecommendationApi from "@/api/RecommendationApi";
import { defineStore } from "pinia";

export const useRecommendationStore = defineStore("recommendation", {
  state: () => {
    return {
      recommendations: [],
      monthRecommendations: [],
      dayRecommendations: [],
      loadDay: false,
      loadMonth: false
    };
  },

  actions: {
    async getRecommendations(params) {
      this.recommendations = (
        await RecommendationApi.getRecommendations(params)
      ).recommendation.sort((a, b) => a.position - b.position);
    },
    async getDayRecommendations(params) {
      this.dayRecommendations = (
        await RecommendationApi.getDayRecommendations(params)
      ).recommendation.sort((a, b) => {
        if ((a.type || 0) !== (b.type || 0)) {
          return (a.type || 0) - (b.type || 0);
        }
        return (a.pos || 0) - (b.pos || 0);
      });
    },
    async getMonthRecommendations(params) {
      this.monthRecommendations = (
        await RecommendationApi.getDayRecommendations(params)
      ).days;
    }
  }
});
