<template>
  <modal-template
    :size="'complete'"
    :success-error-dialog="successErrorDialog"
    :overflow-hidden="true"
    :close="false"
    :external-close="true"
    @close="closeModal"
  >
    <div ref="modalRef" class="flex moment-container">
      <div class="image-container">
        <img
          :src="getImageUrl(selectedMoment)"
          :alt="selectedMoment.title"
          class="lazyload main-image"
          @error="
            $event.target.src = require('@/assets/images/placeholder-event.svg')
          "
        />
      </div>
      <div class="comments-container flex flex-col justify-between">
        <div class="container-top">
          <div class="title-container flex">
            <w-navigation
              :object="selectedMoment"
              :full="false"
              class="w-10 h-10"
              style="min-width: 40px"
            >
              <template #link>
                <img
                  :src="getUserImageUrl(selectedMoment)"
                  alt="user image"
                  class="user-image"
                  @error="
                    $event.target.src = require('@/assets/images/placeholder-user.svg')
                  "
                />
              </template>
            </w-navigation>
            <div
              class="absolute right-0 m-auto cursor-pointer"
              @click.prevent="openActions"
            >
              <div class="flex flex-row gap-0.5 px-3 py-4">
                <div class="circle bg-grayDetails rounded-full h-1 w-1"></div>
                <div class="circle bg-grayDetails rounded-full h-1 w-1"></div>
                <div class="circle bg-grayDetails rounded-full h-1 w-1"></div>
              </div>
            </div>
            <div v-if="areActionsOpen" ref="actions" class="actions">
              <div style="color: white" @click="showshareModal">
                {{ $t("wegow.moment.shared") }}
              </div>
              <div
                v-if="selectedMoment.user !== userDetail.id"
                @click="reportMoment = true"
              >
                {{ $t("wegow.moment.report") }}
              </div>
              <div
                v-if="selectedMoment.user === userDetail.id"
                @click="removeMoment = true"
              >
                {{ $t("wegow.moment.remove") }}
              </div>
            </div>
            <div class="flex flex-col ml-4">
              <h4
                class="moment-title mb-2"
                v-html="
                  getParsedTitle(selectedMoment.text, selectedMoment.user_name)
                "
              />
              <span class="date flex items-center">
                <img
                  :src="require('@/assets/images/ic-clock-white.svg')"
                  :alt="$t('wegow.altIcon')"
                  class="w-4 h-4 mr-1"
                />
                {{ getTimeFromNow(selectedMoment.created) }}</span
              >
            </div>
          </div>
          <div class="comments flex flex-col">
            <div
              v-for="comment in selectedMoment.comments"
              :key="comment.id"
              class="flex mt-3"
            >
              <w-navigation
                :object="comment"
                :full="false"
                :params="{
                  name: 'ProfilePage',
                  params: {
                    slug: comment.slug
                  }
                }"
              >
                <template #link>
                  <img
                    :src="getUserImageUrl(comment)"
                    :alt="comment.user_name"
                    class="user-comment-image"
                    @error="
                      $event.target.src = require('@/assets/images/placeholder-user.svg')
                    "
                  />
                </template>
              </w-navigation>
              <div
                class="comment-container ml-2 px-4 pt-2 pb-5"
                style="word-break: break-all; max-width: 80%"
              >
                <div v-html="decodeChatMessage(comment.comment_text)"></div>
                <span>{{ formatHourSimple(comment.created) }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="container-botom">
          <div class="info flex justify-end mb-2">
            <div class="mr-1 moment-stats flex items-center justify-end">
              <img
                :src="require('@/assets/images/ic-comment.svg')"
                class="lazyload mr-1"
                alt="comment icon"
              />
              {{ selectedMoment.comments_count }}
            </div>
            <div class="moment-stats flex items-center justify-center">
              <!-- TODO: añadir metodo de like -->
              <img
                :src="
                  selectedMoment.liked
                    ? require('@/assets/images/ic-full-heart-topaz.svg')
                    : require('@/assets/images/ic-heart.svg')
                "
                class="lazyload cursor-pointer mr-1"
                alt="Modal error"
                @click="postLike(selectedMoment)"
              />
              {{ selectedMoment.likes_count }}
            </div>
          </div>
          <hr />
          <div class="my-comment flex items-center mb-4">
            <wegow-input
              id="search-input"
              ref="momentInput"
              v-model:value="message"
              class="w-full"
              label-for="query"
              :placeholder="$t('wegow.moment.messagePlaceholder')"
              background-input="bg-transparent"
              border-input="border-transparent"
              :focus-class="false"
              :focused="true"
              @enter="sendComment()"
            />
            <div class="w-1/4 flex justify-end">
              <!-- TODO: añadir emoticonos -->
              <!-- <i class="text-2xl cursor-pointer"></i> 
              <img
                class="md:ml-8 md:mr-5 mr-4 text-2xl cursor-pointer text-color-gray"
                :src="require('@/assets/images/ic-icon.png')"
                alt="icons"
              /> -->

              <wegow-button
                id="moment-moment-button"
                type="button"
                text-type="img"
                :icon="require('@/assets/images/ic-send.svg')"
                :disabled="message === ''"
                :loading="sendingMoment"
                @click="sendComment()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal-template>
  <report-modal
    v-if="reportMoment"
    :moment="selectedMoment"
    @close="reportMoment = false"
  />
  <remove-modal
    v-if="removeMoment"
    :moment="selectedMoment"
    @close="removeMoment = false"
  />
  <Suspense>
    <template #default>
      <ShareModal
        v-if="openShareVar"
        category="event"
        :route-slug="publicationRoute(selectedMoment.id)"
        :x-position="xposition"
        :y-position="yposition"
        @close="openShareVar = false"
      ></ShareModal>
    </template>
    <template #fallback>
      <wegow-loader class="min-h-screen" />
    </template>
  </Suspense>
</template>
<script setup>
import WegowInput from "@/components/general/forms/WegowInput";
import WegowButton from "@/components/general/forms/WegowButton";
import ModalTemplate from "@/components/general/modals/ModalTemplate";
import WNavigation from "@/components/general/ui/WNavigation";
import RemoveModal from "@/components/wegow/moment/RemoveModal.vue";
import ReportModal from "@/components/wegow/moment/ReportModal";
import useDetectOutsideClick from "@/plugins/useDetectOutsideClick";
import moment from "moment";

import { useMoment } from "@/composables/useMoment";
import { formatHourSimple } from "@/plugins/date-formater";
import { onMounted, ref, defineAsyncComponent } from "vue";
import { useModalHandler } from "@/plugins/useModalHandler";
import { useUser } from "@/composables/useUser";
import { useRoute } from "vue-router";
import { useChat } from "@/composables/useChat";

const ShareModal = defineAsyncComponent(() =>
  import("@/components/general/modals/ModalShare.vue")
);

const { userDetail } = useUser();
const { decodeChatMessage } = useChat();
const {
  selectedMoment,
  sendingMoment,
  postLike,
  postComment,
  getParsedTitle
} = useMoment();

const props = defineProps({
  successErrorDialog: {
    type: String,
    required: false,
    default: "success-error-dialog"
  },
  size: {
    type: String,
    required: false,
    default: "intermedium-small"
  }
});
const emit = defineEmits(["close", "accept"]);

const { closeModal } = useModalHandler();
const route = useRoute();

const initialOutsideClick = ref(true);
const openShareVar = ref(false);
const areActionsOpen = ref(false);
const reportMoment = ref(false);
const removeMoment = ref(false);
const modalRef = ref();
const inside = ref(false);

const message = ref("");
const actions = ref(null);

const getTimeFromNow = time => {
  if (time) {
    return moment(time).fromNow();
  }
  return "";
};

const getImageUrl = element => {
  if (element.medias && element.medias[0] && element.medias[0].image) {
    return element.medias[0].image;
  }
  return require("@/assets/images/placeholder-event.svg");
};

const getUserImageUrl = element => {
  if (element && element.user_image) {
    return element.user_image;
  }
  return require("@/assets/images/placeholder-user.svg");
};

const sendComment = async () => {
  inside.value = false;
  await postComment({ moment: selectedMoment.value, message: message.value });
  message.value = "";
  inside.value = true;
};
const openActions = () => {
  areActionsOpen.value = !areActionsOpen.value;
  initialOutsideClick.value = true;
};
const showshareModal = () => {
  openShareVar.value = true;
};
const publicationRoute = moment => {
  return `${route.params.region}/wegowmoments/${moment}`;
};
</script>

<style scoped lang="scss">
.moment-container {
  width: 80vw;
  height: 80vh;
  margin-left: auto;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
  .actions {
    @apply absolute cursor-pointer rounded-l-md flex flex-col gap-2;
    background: $backgroundBlackOpacity6;
    top: 70px;
    right: 0;
    padding: 8px 15px;
    color: $red;
  }
  .image-container {
    // width: 100%;
    flex: 1;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .comments-container {
    flex: 0 0 550px;
    // display: flex;
    align-items: center;
    // justify-content: center;
    height: 100%;
    padding-top: 30px;
    // width: 550px;
    // padding: 0px 34px;
    // padding-top: 34px;
    .title-container {
      .user-image {
        height: 40px;
        width: 40px;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    .comments {
      overflow-y: auto;
      height: 55vh;
      margin-top: 10px;

      &::-webkit-scrollbar {
        display: none;
      }
      .user-comment-image {
        height: 34px;
        width: 34px;
        border-radius: 50%;
        max-width: none;
        object-fit: cover;
      }
      .comment-container {
        background: $inputLigthBackground;
        border-radius: 5px;
        position: relative;
        min-width: 50%;
        span {
          font-weight: 300;
          font-size: 10px;
          line-height: 10px;
          color: $textSubtitle;
          position: absolute;
          bottom: 3px;
          right: 3px;
        }
      }
    }
    .container-botom,
    .container-top {
      width: 90%;
      max-height: 85%;
    }
  }
}
</style>

<style lang="scss">
.moment-container .my-comment {
  i {
    color: #7e8294;
  }
  button {
    padding-bottom: 0px;
    padding-right: 0px;
  }
}
</style>
