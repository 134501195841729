import { storeToRefs } from "pinia";

import { useArtistStore } from "@/stores/modules/artist";
import { useUser } from "./useUser";
import { computed } from "vue";

export const useArtist = () => {
  const artistStore = useArtistStore();
  const {
    media,
    artists,
    details,
    genresTopArtists,
    artistsRelatedToArtist,
    artistsRelatedToEvent,
    locations,
    artistsForImport
  } = storeToRefs(artistStore);
  const { userDetail } = useUser();

  const getArtistsFollowed = async data => {
    let params = {
      page_size: data.page_size || 20,
      page: data.page || 1,
      followers: data.id,
      count: true
    };
    await artistStore.getArtists(params);
  };
  const getArtistsFollowedInfinite = async data => {
    let params = {
      page_size: data.page_size || 20,
      page: data.page || 1,
      followers: data.id,
      count: true
    };
    await artistStore.getInfiniteArtists(params);
  };

  const getDetails = async slug => {
    let payload = {
      slug,
      params: {
        search_slug: true
      }
    };
    await artistStore.getArtist(payload);
  };

  const getArtistsForImport = async ({ importType }) => {
    await artistStore.getArtistsForImport({ filter: importType });
  };

  const getArtistsRelatedToArtist = async data => {
    const params = {
      related_to_artist: data.id,
      page_size: data.page_size || 10
    };
    await artistStore.getArtistsRelatedToArtist(params);
  };
  const getArtistsRelatedToEvent = async data => {
    const params = {
      related_to_event: data.id,
      page_size: data.page_size || 10
    };
    await artistStore.getArtistsRelatedToEvent(params);
  };

  const getArtistMedia = async data => {
    const params = {
      artist: data.id
    };
    await artistStore.getArtistMedia(params);
  };

  const followArtist = async data => {
    const params = {
      artist: data.id
    };
    data.followers_count += 1;
    data.user.following = true;
    userDetail.value.artists_count += 1;
    await artistStore.followArtist(params);
  };

  const unfollowArtist = async data => {
    const params = {
      artist: data.id
    };
    data.followers_count -= 1;
    data.user.following = false;
    userDetail.value.artists_count -= 1;
    await artistStore.unfollowArtist(params);
  };

  const getGenresTopArtists = async genres => {
    genres.forEach(async genre => {
      const payload = { genres: genre, page_size: 12 };
      await artistStore.getTopGenreArtists(payload);
    });
  };

  const getLocations = async region => {
    let params = {
      iso_code: region,
      ordering: "id"
    };
    await artistStore.getLocations(params);
  };

  return {
    //! Properties
    artists,
    details,
    media,
    genresTopArtists,
    artistsRelatedToArtist,
    artistsRelatedToEvent,
    artistsForImport,
    locations,

    indexedLocations: computed(() =>
      locations.value.filter(location => location.index)
    ),
    //! Metodos
    getDetails,
    getArtistMedia,
    getArtistsForImport,
    followArtist,
    unfollowArtist,
    getGenresTopArtists,
    getArtistsFollowed,
    getArtistsRelatedToArtist,
    getArtistsRelatedToEvent,
    getLocations,
    getArtistsFollowedInfinite
  };
};
