import axios from "axios";
import {
  VALID_LANGUAGES,
  DEFAULT_LANGUAGES,
  VALID_REGIONS
} from "@/constants.js";
import { VueCookieNext } from "vue-cookie-next";
import { useUser } from "@/composables/useUser";

const $cookie = VueCookieNext;

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.params = {};
axios.defaults.timeout = 30000;
axios.defaults.headers.common["Content-Type"] =
  "application/x-www-form-urlencoded";
axios.defaults.withCredentials = true;
axios.interceptors.request.use(
  request => {
    const { maintenancePage, serverError } = useUser();
    // Maintenance
    if (maintenancePage.value || serverError.value) {
      return false;
    }
    let accept_language =
        request && request.headers && request.headers["accept-language"]
          ? request.headers["accept-language"]
              .split(",")[0]
              .toLocaleLowerCase()
              .substring(0, 2)
          : process.env.VUE_APP_NUXT_DEFAULT_LANGUAGE,
      lang = $cookie.getCookie("wegow.user-language") || accept_language,
      // lang = $cookie.getCookie("wegow.i18n-lang") || accept_language,
      access_token = $cookie.getCookie("wegow.access_token"),
      refresh_token = $cookie.getCookie("wegow.refresh_token");
    if (VALID_LANGUAGES.indexOf(lang) === -1) {
      lang = "es";
    }
    request.params = request.params || {};
    // Check if is magazine
    if (window.location.href.includes("magazine")) {
      const url = window.location.href;
      const region = url.split("/")[3] || undefined;
      if (region && VALID_REGIONS.includes(region)) {
        request.params.region = region;
      }
    } else if (
      $cookie.getCookie("wegow.user-region") &&
      ($cookie.getCookie("wegow.user-region").includes("-") ||
        $cookie.getCookie("wegow.user-region").length === 2)
    ) {
      if ($cookie.getCookie("wegow.user-region").includes("-")) {
        request.params.region = $cookie.getCookie("wegow.user-region");
      } else if (
        $cookie.getCookie("wegow.user-region") &&
        DEFAULT_LANGUAGES[$cookie.getCookie("wegow.user-region")]
      ) {
        request.params.region =
          DEFAULT_LANGUAGES[$cookie.getCookie("wegow.user-region")] +
          "-" +
          $cookie.getCookie("wegow.user-region");
      } else {
        request.params.region = "es-es";
      }
    }
    // $cookie.getCookie("wegow.user-region") +
    // "-" +
    // $cookie.getCookie("wegow.user-region");
    // if (route.query.lang && route.params.region) {
    //   lang = route.query.lang;
    //   request.params.lang = lang;
    // } else if (route.params.region && !route.query.lang) {
    //   var expresionLang = /^\/(\w+)-(\w+).+/i;
    //   var matchExpresionLang = path.match(expresionLang);
    //   var pathLang = matchExpresionLang ? matchExpresionLang[1] : false;
    //   lang = pathLang;
    //   request.params.lang = lang;
    // } else {
    request.params.lang = lang;
    // }
    request.params.mongo = true;
    request.headers.common["Accept-Language"] = lang;
    if (process.env.VUE_APP_NUXT_ENV !== "local") {
      request.headers.common["Cache-Control"] = "no-cache";
      request.headers.common.Pragma = "no-cache";
      request.headers.common["Accept-Expires"] = "0";
    }
    // request.headers.common["Accept-Encoding"] = "gzip";

    if (request.params.landing) {
      delete request.params.lang;
    }

    const { isAuth } = useUser();
    if (
      isAuth.value &&
      typeof refresh_token !== "undefined" &&
      refresh_token !== null &&
      typeof access_token !== "undefined" &&
      access_token !== null
    ) {
      request.headers.common.Authorization = `Bearer ${access_token}`;
    }
    // request.headers.common.Authorization = `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxOTc3NDk2MDYyLCJqdGkiOiI3YzMzYmYyODUwNDM0NmRmYjEzNTVhN2ZmMDMwMDM5OSIsInVzZXJfaWQiOjU5MDF9.CAa40V1yReF0hFoWaZVWvgePWG4lK4yZR0XWEdiF9xY${access_token}`;

    // request.params.mongo = true;
    return request;
  },
  function(error) {
    // if (
    //   error &&
    //   error.response &&
    //   error.response.status &&
    //   error.response.status === 401 &&
    //   $cookie.getCookie("wegow.refresh_token")
    // ) {
    //   return $axios
    //     .post(`/jwt/token/refresh/`, {
    //       refresh: $cookie.getCookie("wegow.refresh_token")
    //     })
    //     .then(res => {
    //       let match_expression =
    //         process.env.VUE_APP_NUXT_ENV !== "local" ? "https://www" : "http://www";
    //       app.$cookies.set("wegow.access_token", res.data.access_token, {
    //         path: "/",
    //         expire: 60 * 60 * 24 * 365,
    //         domain: process.env.VUE_APP_NUXT_HOST.replace(match_expression, ""),
    //         secure: process.env.VUE_APP_NUXT_ENV !== "local"
    //       });
    //       app.$cookies.set("wegow.expiry_token", res.data.expiry_token, {
    //         path: "/",
    //         expire: 60 * 60 * 24 * 365,
    //         domain: process.env.VUE_APP_NUXT_HOST.replace(match_expression, ""),
    //         secure: process.env.VUE_APP_NUXT_ENV !== "local"
    //       });
    //       error.response.config.headers.Authorization = `Bearer ${res.data.access_token}`;
    //       return $axios.request(error.response.config);
    //     })
    //     .catch(() => {
    //       store.dispatch("initial/clean");
    //       redirect(
    //         307,
    //         store.state.initial.detail.wegow_direct
    //           ? "/?wegow_direct=true"
    //           : "/"
    //       );
    //     });
    // } else if (
    //   error &&
    //   error.response &&
    //   error.response.status &&
    //   error.response.status === 401
    // ) {
    //   store.dispatch("initial/clean");
    //   redirect(
    //     307,
    //     store.state.initial.detail.wegow_direct ? "/?wegow_direct=true" : "/"
    //   );
    // }
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  res => {
    return Promise.resolve(res);
  },
  function(error) {
    if (
      error.response.status === 403 &&
      error.config.url !== "/auth/" &&
      !error.config.url.includes("/check-email-event/")
    ) {
      const { deleteCookies } = useUser();
      deleteCookies();
      window.location.href = process.env.VUE_APP_FRONT;
    } else {
      return Promise.reject(error.response);
    }
  }
);

export const httpClient = axios;

export default httpClient;
