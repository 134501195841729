import apiService from "@/api/ApiService";
import { checkParameterSlug } from "@/plugins/parameters";

const TRANSFET_TIER_URL = "/create-transfer-tier/";
const CREATE_PURCHASE_URL = "/purchases/";
const CREATE_PURCHASE_SEATSIO_URL = "/seatsio/purchases/";
const CREATE_TRANSFER_URL = "/transfers/";
const CREATE_PRODUCT_PURCHASE = "/cs-purchase-update/";
const GET_THANK_AD_URL = "/getthanksad/";
const ACCESS_CODE_URL = "/validate-accesscode/";
const CHECK_EMAILS_URL = "/check-email-event/";

export class PurchaseService {
  getParamsUrl(referenceParams) {
    let url = "";
    if (referenceParams.query?.r) {
      url += "r=" + referenceParams.query.r;
    }
    if (referenceParams.hash) {
      url += "#" + referenceParams.hash;
    }
    return url;
  }

  async createPurchase(params, referenceParams) {
    let newUrl = CREATE_PURCHASE_URL + "?v4=true";
    const finalUrl = this.checkParameters(params, referenceParams, newUrl);
    return await (await apiService.post(finalUrl, { ...params })).data;
  }

  checkParameters(params, referenceParams, newUrl) {
    const containsUParam = /u-param-\w+/g.test(params.parameter);
    const containsRParam = /r-param-\w+/g.test(params.parameter);
    let getItem = JSON.parse(localStorage.getItem(params.parameter));

    if (referenceParams.query || referenceParams.hash) {
      newUrl += "&" + this.getParamsUrl(referenceParams);
    }
    if (params.parameter && containsUParam) {
      newUrl += "&" + `u=${getItem.u}`;
      localStorage.removeItem(params.parameter);
    }
    if (params.parameter && containsRParam) {
      newUrl += "&" + `r=${getItem.r}`;
      localStorage.removeItem(params.parameter);
    }
    return newUrl;
  }

  async createSeatsioPurchase(params, referenceParams) {
    let newUrl = CREATE_PURCHASE_SEATSIO_URL;
    // TODO: revisar que pasa con terminos y nominativas
    // + "?v4=true";
    if (referenceParams.query || referenceParams.hash) {
      newUrl += "?" + this.getParamsUrl(referenceParams);
    }
    return await (await apiService.post(newUrl, { ...params })).data;
  }

  async createTransfer(params, referenceParams) {
    let newUrl = CREATE_TRANSFER_URL + "?v4=true";
    if (referenceParams.query || referenceParams.hash) {
      newUrl += "&" + this.getParamsUrl(referenceParams);
    }
    return await (await apiService.post(newUrl, { ...params })).data;
  }

  async getThankAd(params) {
    return (await apiService.get(GET_THANK_AD_URL, { params })).data;
  }

  async getPurchase(reference) {
    return (await apiService.get(CREATE_PURCHASE_URL + reference + "/")).data;
  }

  async validateAccessCode(params) {
    return (
      await apiService.post(`${ACCESS_CODE_URL}${params.slug}/`, params.data)
    ).data;
  }

  async editWeswapTicket(params) {
    return (await apiService.post(`${TRANSFET_TIER_URL}`, params)).data;
  }

  async checkIfEmailIsUnique(params) {
    return (
      await apiService.post(`${CHECK_EMAILS_URL}${params.id}/`, {
        emails: params.emails,
        id_numbers: params.id_numbers
      })
    ).data;
  }

  async createProductPurchase(reference, params) {
    let newUrl = `${CREATE_PRODUCT_PURCHASE}${reference}/` + "?v4=true";
    // if (referenceParams.query || referenceParams.hash) {
    //   newUrl += "&" + this.getParamsUrl(referenceParams);
    // }
    return await (await apiService.post(newUrl, params)).data;
  }
}

const purchaseService = new PurchaseService();

export default purchaseService;
