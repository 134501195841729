<template>
  <div v-if="activeModal === MOMENT_LIKES" class="moment-likes-modal">
    <modal-template
      class="modal"
      size="normal-small"
      @close="onCloseMomentLikes"
    >
      <div class="head h-12 border-b border-grayContainer"></div>
      <div ref="content" class="content">
        <div v-if="!loaded" class="w-loader">
          <w-loader />
        </div>
        <div v-else class="flex flex-col gap-2">
          <div class="body" @scroll="onContentScroll">
            <profile-card
              v-for="profile in usersLikedMoment"
              :key="profile.id"
              :profile="profile"
              type="short"
            />
            <div v-if="!nextMomentsLoaded">
              <w-loader />
            </div>
          </div>
        </div>
      </div>
    </modal-template>
  </div>
</template>
<script setup>
import WLoader from "@/components/general/ui/WegowLoader.vue";
import ProfileCard from "@/components/wegow/ui/cards/ProfileCard.vue";
import ModalTemplate from "./ModalTemplate.vue";

import { useUser } from "@/composables/useUser";
import { useModalHandler } from "@/plugins/useModalHandler";
import { ref, toRefs, watch } from "vue";
const onCloseMomentLikes = () => {
  page.value = 1;
  closeModal();
};

const props = defineProps({
  moment: {
    type: Object
  }
});

const { moment } = toRefs(props);

const { getUsersLikedMoment, usersLikedMoment } = useUser();
const { activeModal, MOMENT_LIKES, closeModal } = useModalHandler();

const page = ref(1);
const loaded = ref(false);
const nextMomentsLoaded = ref(true);
const content = ref(null);

const onContentScroll = () => {
  const isInContentEnd =
    content.value.offsetHeight + content.value.scrollTop >=
    content.value.scrollHeight;
  if (moment.value.likes_count > 8 * page.value && isInContentEnd) {
    page.value += 1;
    getNextMoments();
  }
};

const getNextMoments = () =>
  (async () => {
    nextMomentsLoaded.value = false;
    await getUsersLikedMoment({
      moment_id: moment.value?.id,
      page_size: 9 * page.value
    });
    nextMomentsLoaded.value = true;
  })();

const init = () =>
  (async () => {
    loaded.value = false;
    await getUsersLikedMoment({
      moment_id: moment.value?.id,
      page_size: 9
    });
    loaded.value = true;
  })();

watch([moment], () => init());
</script>
<style lang="scss" scoped>
.moment-likes-modal {
  .modal {
    .content {
      @apply h-128 relative overflow-hidden;
      .body {
        @apply h-128 overflow-auto;
        padding: 0.5rem;
      }
      .w-loader {
        @apply h-full flex flex-col justify-around;
      }
    }
  }
}
</style>
