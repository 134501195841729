import apiService from "@/api/ApiService";

const EVENT_URL = "/events/";
const MEDIA_URL = "/media/";
const WIDGET_URL = "/widget/";
const TICKETS_URL = "/ticket-types/";
const WESWAP_URL = "/transfer-tier/";
const USER_EVENT_URL = "/user_events/";
const CHANNELS_URL = "/streaming-channels/";
const TICKET_OFFICE_REPORT_URL = "/ticket-office-report/";
const TICKET_OFFICE_AVAILABLE_URL = "/ticket-office-available/";
const WESWAP_STATUS_URL = "/weswap-status/";
const WAITING_LIST_URL = "/weswap-wait-list/";

export class EventService {
  async getDetails(slug) {
    return (await apiService.get(`${EVENT_URL}${slug}/`)).data;
  }

  async getDetailsIframe(slug, whiteLabel) {
    return (
      await apiService.get(`${WIDGET_URL}${slug}/`, {
        params: { hb: whiteLabel }
      })
    ).data;
  }

  async addToWaitingList(payload) {
    return (
      await apiService.post(`${WAITING_LIST_URL}${payload.eventId}/`, {
        ticket_types: payload.ticket_types
      })
    ).data;
  }

  async getEvents(params) {
    return (await apiService.get(EVENT_URL, { params })).data;
  }

  async getTickets(params) {
    return (await apiService.get(TICKETS_URL, { params })).data;
  }

  async getWeswapTickets(params) {
    return (await apiService.get(WESWAP_URL, { params })).data;
  }

  async followEvent(params) {
    return await apiService.post(USER_EVENT_URL, params);
  }

  async unfollowEvent(params) {
    return await apiService.delete(USER_EVENT_URL, { params });
  }

  async getSeatsioConfig(payload) {
    return (
      await apiService.get(`/seatsio/${payload.ticket_dist}/widget_info/`, {
        params: { places: payload.places }
      })
    ).data;
  }

  async getEventMedia(params) {
    return (await apiService.get(MEDIA_URL, { params })).data.media;
  }

  async getEventChannels(params) {
    return (await apiService.get(CHANNELS_URL, { params })).data.media;
  }

  async getTicketOfficeReport(params) {
    return await apiService.get(`${TICKET_OFFICE_REPORT_URL}${params.event}/`, {
      params: params.params
    });
  }

  async getTicketOfficeAvailable() {
    return await apiService.get(`${TICKET_OFFICE_AVAILABLE_URL}`);
  }
}

const eventService = new EventService();

export default eventService;
