import { defineStore } from "pinia";

export const useCookiesStore = defineStore("cookies", {
  state: () => {
    return {
      accepted: false,
      thirdParty: false
    };
  },
  actions: {
    acceptCookie(payload) {
      this.$state.accepted = payload;
    },
    acceptCookieThirdParty(payload) {
      this.$state.thirdParty = payload;
    }
  }
});
