import { useWaitingRoomStore } from "@/stores/modules/waitingRoom";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useRoute } from "vue-router";

export const useWaitingRoom = () => {
  const waitingRoomStore = useWaitingRoomStore();
  const { event, token, tokenInfo } = storeToRefs(waitingRoomStore);
  const route = useRoute();

  const getWaitingRoomEvent = async slug => {
    try {
      return await waitingRoomStore.getWaitingRoomEvent(slug);
    } catch (error) {
      return {};
    }
  };

  const getToken = slug => {
    return waitingRoomStore.getToken(slug);
  };

  const checkToken = (slug, token) => {
    return waitingRoomStore.checkToken(slug, token);
  };

  const getIsWaitingRoom = routeName => {
    const waitingRoomPages = [
      "WaitingRoom",
      "WaitingRoomPurchasePage",
      "WaitingRoomPaymentPage",
      "WaitingRoomThankYouPage"
    ];

    return waitingRoomPages.includes(routeName);
  };

  const isWaitingRoom = computed(() => getIsWaitingRoom(route.name));

  return {
    event,
    token,
    tokenInfo,
    isWaitingRoom,
    getIsWaitingRoom,

    getWaitingRoomEvent,
    getToken,
    checkToken
  };
};
