import { defineStore } from "pinia";
import GeneralApi from "@/api/GeneralApi";

export const useGeneralStore = defineStore("general", {
  state: () => {
    return {
      cities: [],
      genres: [],
      genre: {},
      regions: [],
      footerInfo: {},
      navbarInfo: {},
      searchOptions: [],
      eventValidation: {}
    };
  },
  getters: {},
  actions: {
    async getEventValidations(validationCode) {
      this.eventValidation = (
        await GeneralApi.getEventValidation(validationCode)
      ).data;
    },
    async getLocations(payload) {
      let response = await GeneralApi.getLocations(payload);
      this.cities = response.cities;
      // TODO:
    },
    async getGenres(payload) {
      this.genres = (await GeneralApi.getGenres(payload)).genres;
    },
    async getGenreById(payload) {
      // this.genre = await GeneralApi.getGenreById(payload).cities;
    },
    async getRegions(payload) {
      this.regions = (await GeneralApi.getRegions(payload)).regions;
    },
    async getFooter() {
      try {
        let footerInfo = await GeneralApi.getFooter();
        this.footerInfo = footerInfo.footers?.length
          ? footerInfo.footers[0]
          : {};
      } catch (error) {
        this.footerInfo = {};
      }
    },
    async getNavbar() {
      try {
        let navbarData = await GeneralApi.getNavbar();
        this.navbarInfo = navbarData["menu-configs"]
          ? navbarData["menu-configs"][0]
          : {};
      } catch (error) {
        this.navbarInfo = {};
      }
    },
    async importSpotify(payload) {
      await GeneralApi.getSpotifyImports(payload);
    },
    async importAllSpotify() {
      await GeneralApi.importAllSpotify();
    },
    async getSuggestions(params) {
      this.searchOptions = (await GeneralApi.getSuggestions(params)).lista;
    }
  }
});
