import { storeToRefs } from "pinia";
import { useUser } from "./useUser";
import { useCampaignStore } from "@/stores/modules/campaign";

export const useCampaign = () => {
  const campaignStore = useCampaignStore();
  const { details, campaignItems, campaignRows, campaignMedia } = storeToRefs(
    campaignStore
  );
  const { userDetail } = useUser();

  const getCampaignMedia = async data => {
    const params = {
      campaign: data.id
    };
    await campaignStore.getCampaignMedia(params);
  };

  const followCampaign = async data => {
    const params = {
      campaign: data.id
    };
    data.followers_count += 1;
    data.user.following = true;
    userDetail.value.artists_count += 1;
    await campaignStore.followCampaign(params);
  };

  const unfollowCampaign = async data => {
    const params = {
      campaign: data.id
    };
    data.followers_count -= 1;
    data.user.following = false;
    userDetail.value.artists_count -= 1;
    await campaignStore.unfollowCampaign(params);
  };

  const getDetails = async slug => await campaignStore.getDetails(slug);
  const getCampaignItems = async slug =>
    await campaignStore.getCampaignItems(slug);
  const getCampaignRows = async slug =>
    await campaignStore.getCampaignRows(slug);
  return {
    //! Properties
    details,
    campaignItems,
    campaignRows,
    campaignMedia,

    getDetails,
    followCampaign,
    getCampaignRows,
    getCampaignMedia,
    getCampaignItems,
    unfollowCampaign
  };
};
