<template>
  <div class="grid grid-cols-2 grid-rows-2">
    <div>
      <p class="text-2xl">{{ purchase.event }}</p>
      <small>
        {{ purchase.venue }}
      </small>
    </div>
    <div class="text-right">
      <p>{{ purchase.tickets.length }} {{ $t("profiles.titles.tickets") }}</p>
      <p>
        {{ formatedPurchasePrice }}
      </p>
    </div>
    <div class="text-color-gray text-xs">
      <span class="mt-4 flex">
        <img
          :src="require('@/assets/images/ic-calendar.svg')"
          :alt="$t('wegow.altIcon')"
          class="close-icon h-5 w-5"
        />
        <span class="items-center ml-2 w-full">
          {{ eventDate }}
        </span>
      </span>
      <span class="flex mt-1">
        <img
          :src="require('@/assets/images/ic-clock.svg')"
          :alt="$t('wegow.altIcon')"
          class="close-icon h-5 w-5"
        />
        <span class="time items-center ml-2 w-full">{{ eventHour }}</span>
      </span>
    </div>
    <div class="flex flex-col justify-end">
      <div class="flex flex-row justify-end">
        <wegow-button
          background="opacity"
          color="topaz"
          type="button"
          :text="
            $t('wegow.purchase.buyInsuranceTicket', {
              price: formatedInsurancePrice
            })
          "
          :disabled="purchase.insured"
          @click="$emit('selectPurchase')"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import WegowButton from "@/components/general/forms/WegowButton";
import { toRefs, computed } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { formatLongDate, formatHour } from "@/plugins/date-formater";
import { formatCurrency } from "@/plugins/currency";

const route = useRoute();
const { t } = useI18n();

const props = defineProps({
  purchase: { type: Object, required: true }
});
defineEmits(["selectPurchase"]);

const { purchase } = toRefs(props);

const formatedInsurancePrice = computed(() => {
  return formatCurrency(
    Number(purchase.value.insurance_budget).toFixed(2),
    purchase.value.currency
  );
});

const formatedPurchasePrice = computed(() => {
  return formatCurrency(
    Number(purchase.value.price).toFixed(2),
    purchase.value.currency
  );
});

const eventHour = computed(() => {
  return formatHour(purchase.start_date, route.params.region);
});

const eventDate = computed(() => {
  let infoDate = formatLongDate(purchase.value.start_date, route.params.region);
  return t("wegow.date.dateFormat", infoDate);
});
</script>
