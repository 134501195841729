import apiService from "@/api/ApiService";

const CREATE_PAYMENT_URL = "/payment/";
const CREATE_PAYMENTS_URL = "/payments/";
const CREATE_TRANSFER_PAYMENTS_URL = "/transfer-payments/";
const PAYMENT_METHODS = "/payment-methods/";
const INSURANCE_URL = "/insurance/";
const PAY_INSURANCE_URL = "/insurance-payments/";
const DISCOUNT_URL = "/check-discount/";

export class PaymentService {
  async createPayments(params) {
    let newUrl =
      (params.f2f ? CREATE_TRANSFER_PAYMENTS_URL : CREATE_PAYMENTS_URL) +
      "?v4=true";
    return await (await apiService.post(newUrl, { ...params })).data;
  }

  async confirmPayments(params) {
    return await (
      await apiService.put(CREATE_PAYMENTS_URL + params.id + "/", {
        ...params.data
      })
    ).data;
  }

  async getPayment(params) {
    return (await apiService.get(`${CREATE_PAYMENT_URL}${params.params}/`))
      .data;
  }

  async getPaymentMethods(extraUrl) {
    return (await apiService.get(`${PAYMENT_METHODS}?${extraUrl}`)).data;
  }

  async addInsurance(params) {
    return (await apiService.post(INSURANCE_URL, { ...params })).data;
  }

  async payInsurance(params) {
    const insurance = (await apiService.post(PAY_INSURANCE_URL, { ...params }))
      .data;
    if (insurance.status === "denied")
      throw new Error("Insurance payment failed");
    return insurance;
  }

  async cancelInsurance(purchaseReference) {
    return await apiService.delete(`${INSURANCE_URL}${purchaseReference}/`);
  }

  async getInsurance(params) {
    return (
      await apiService.post(PAY_INSURANCE_URL + params.id + "/", {
        ...params.data
      })
    ).data;
  }

  async validateDiscount(data) {
    return (await apiService.post(DISCOUNT_URL + data.id + "/", data.params))
      .data;
  }
}

const paymentService = new PaymentService();

export default paymentService;
