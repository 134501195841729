import { storeToRefs } from "pinia";
import { useUser } from "./useUser";
import { useCompanyStore } from "@/stores/modules/company";

export const useCompany = () => {
  const companyStore = useCompanyStore();
  const { companies, details, media } = storeToRefs(companyStore);

  const { userDetail } = useUser();
  const getCompany = async slug => {
    await companyStore.getCompany(slug);
  };

  const getCompaniesFollowed = async data => {
    let params = {
      page_size: 20,
      page: 1,
      followers: data.id
    };
    await companyStore.getCompanies(params);
  };

  const followCompany = async data => {
    const params = {
      company: data.id
    };
    data.followers_count += 1;
    data.user.following = true;
    userDetail.value.artists_count += 1;
    await companyStore.followCompany(params);
  };

  const unfollowCompany = async data => {
    const params = {
      company: data.id
    };
    data.followers_count -= 1;
    data.user.following = false;
    userDetail.value.artists_count -= 1;
    await companyStore.unfollowCompany(params);
  };

  const getCompanyMedia = async ({ id }) =>
    await companyStore.getCompanyMedia({ company: id });

  return {
    //! Properties
    details,
    companies,
    media,
    //! Metodos
    getCompany,
    getCompaniesFollowed,
    followCompany,
    unfollowCompany,
    getCompanyMedia
  };
};
