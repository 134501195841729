<template>
  <modal-template
    v-if="activeModal === MODAL_SYNC_ARTISTS"
    size="complete"
    class="modal-container"
    @close="closeModal"
  >
    <div class="title mt-8">
      <h1 class="text-l text-center">
        {{ $t("wegow.profile.settings.selectYourFavoriteArtists") }}
      </h1>
    </div>
    <div class="users px-16 py-14">
      <wegow-loader v-if="loadingImports" />
      <carousel
        v-else-if="artistsForImport?.length"
        class="carousel fixed-carousel-arrows"
        :wrap-around="false"
        :transition="500"
        :breakpoints="breakpoints.eventCards"
      >
        <slide
          v-for="artist in artistsForImport"
          :key="artist.id"
          :aria-hidden="false"
        >
          <artist-card :artist="artist" />
        </slide>
        <template #addons>
          <navigation />
        </template>
      </carousel>
    </div>
    <div class="text-center mb-6">
      <wegow-button
        v-if="artistsForImport.length"
        button-id="sync-artists-wegow-button"
        :text="$t('wegow.profile.settings.sync')"
        class="w-1/6 mx-auto mt-3"
        background="opacity"
        color="topaz"
        type="button"
        :loading="importRunning"
        @click="syncArtists"
      />
    </div>
  </modal-template>
</template>

<script setup>
import ModalTemplate from "@/components/general/modals/ModalTemplate.vue";
import ArtistCard from "@/components/wegow/ui/cards/ArtistCard.vue";
import WegowLoader from "@/components/general/ui/WegowLoader.vue";
import WegowButton from "@/components/general/forms/WegowButton.vue";

import { useModalHandler } from "@/plugins/useModalHandler";
import { ref } from "vue";
import { useArtist } from "@/composables/useArtist";
import { useGeneral } from "@/composables/useGeneral";
import { Carousel, Navigation, Slide } from "vue3-carousel";
import { BREAKPOINTS as breakpoints } from "@/constants";

const { activeModal, MODAL_SYNC_ARTISTS, closeModal } = useModalHandler();
const { artistsForImport } = useArtist();
const { importAllSpotify } = useGeneral();

const props = defineProps({
  importType: { type: String, default: undefined }
});
const loadingImports = ref(false);
const importRunning = ref(false);

const syncArtists = async () => {
  importRunning.value = true;
  await importAllSpotify();
  artistsForImport.value.forEach(artist => {
    artist.user.following = true;
  });
  importRunning.value = false;
};
</script>
<style scoped lang="scss"></style>
