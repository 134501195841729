import { VALID_REGIONS, ESPECIAL_REGIONS } from "@/constants.js";
import { VueCookieNext } from "vue-cookie-next";
const $cookie = VueCookieNext;

export async function checkRoutes({ to, next }) {
  try {
    let match_expression =
      process.env.VUE_APP_NUXT_ENV !== "local" ? "https://www" : "http://www";
    if (
      !$cookie.getCookie("wegow.user-region") ||
      $cookie.getCookie("wegow.user-region").length > 2
    ) {
      $cookie.setCookie("wegow.user-region", to.params.region || "es", {
        path: "/",
        expire: 60 * 60 * 24 * 365,
        domain: process.env.VUE_APP_NUXT_HOST.replace(match_expression, ""),
        secure: process.env.VUE_APP_NUXT_ENV !== "local"
      });
    }

    let realRegion = to.params.region.includes("-")
      ? to.params.region.split("-")[1]
      : to.params.region;

    let newUrl = "";
    if (ESPECIAL_REGIONS[to.params.region]) {
      newUrl = to.href.replace(
        to.params.region,
        ESPECIAL_REGIONS[to.params.region]
      );
      return next(newUrl);
    } else if (to.params.region.includes("-")) {
      newUrl = to.href.replace(to.params.region, realRegion);
      return next(newUrl);
    }
    // useI18n().locale = DEFAULT_LANGUAGES[to.params.region];
    if (VALID_REGIONS.includes(realRegion)) {
      return next();
    } else {
      return next("/");
    }
  } catch (error) {
    console.error(error);
  }
}
