import apiService from "@/api/ApiService";

const COMPANY_URL = "/companies/";
const MEDIA = "/media/";
const USER_COMPANY = "/user_companies/";

export class CompanyService {
  async getCompany(slug) {
    return (await apiService.get(`${COMPANY_URL}${slug}/`)).data;
  }

  async getCompanyMedia(params) {
    return (await apiService.get(MEDIA, { params })).data.media;
  }

  async getCompanies(params) {
    return (await apiService.get(COMPANY_URL, { params })).data;
  }

  async followCompany(params) {
    return await apiService.post(USER_COMPANY, params);
  }

  async unfollowCompany(params) {
    return await apiService.delete(USER_COMPANY, { params });
  }
}

const companyService = new CompanyService();

export default companyService;
