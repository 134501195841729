import { useUser } from "@/composables/useUser";
export async function authentication({ next, store }) {
  // TODO: revisar
  try {
    const { isAuth } = useUser();
    if (isAuth.value) {
      return next();
    } else {
      return next("/");
    }
  } catch (error) {
    console.error(error);
  }
}
