import { onBeforeUnmount, onMounted } from "vue";

export default function useDetectOutsideClick(component, callback) {
  if (!component) return;

  const listener = event => {
    if (
      component.value &&
      event.target !== component.value &&
      !component.value.contains(event.target)
    ) {
      return callback();
    }
  };

  onMounted(() => {
    document.addEventListener("click", listener);
  });

  onBeforeUnmount(() => {
    document.removeEventListener("click", listener);
  });

  return {
    listener
  };
}
