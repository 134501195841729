<template>
  <modal-template
    v-if="activeModal === PROFILE_INFO"
    :size="size"
    :is-static="isStatic"
    :close="false"
  >
    <div class="modal-content mt-10">
      <div
        class="flex items-center justify-center mb-4 z-10"
        :class="step === 'genres' ? 'sticky top-10 ' : ''"
      >
        <div
          :class="{ 'bg-wegowBase': step === 'profile' }"
          class="border border-blue-500 w-3.5 h-3.5 rounded-full border-wegowBase"
        ></div>
        <div class="border-b w-5 border-wegowBase"></div>
        <div
          :class="{ 'bg-wegowBase': step === 'genres' }"
          class="border border-blue-500 w-3.5 h-3.5 rounded-full border-wegowBase"
        ></div>
        <div class="border-b w-6 border-wegowBase"></div>
        <div
          :class="{ 'bg-wegowBase': step === 'artists' }"
          class="border border-blue-500 w-3.5 h-3.5 rounded-full border-wegowBase"
        ></div>
        <div class="border-b w-6 border-wegowBase"></div>
        <div
          :class="{ 'bg-wegowBase': step === undefined }"
          class="border border-blue-500 w-3.5 h-3.5 rounded-full border-wegowBase"
        ></div>
      </div>

      <div v-if="step === 'profile'">
        <form
          class="flex flex-col gap-10 md:mx-28 mx-2 mb-10"
          @submit.prevent="setProfile(model)"
        >
          <label class="text-color-topaz-light font-museo-700 text-center">
            Tu perfil</label
          >
          <div>
            <img
              class="border-0 rounded-full object-cover cursor-pointer m-auto"
              :src="imageUrl"
              style="height: 130px; width: 130px;"
              @click="openImageSelector"
              @error="
                $event.target.src = require('@/assets/images/placeholder-user-square.svg')
              "
            />
            <input
              ref="imageInput"
              class="hidden"
              type="file"
              accept=".png, .jpg, .jpeg"
              @change="handleImageChange"
            />
          </div>
          <div class="flex flex-col gap-4">
            <wegow-input
              id="profile-user-firstname"
              v-model:value="model.first_name"
              :placeholder="$t('wegow.profile.settings.firstName')"
            />
            <wegow-input
              id="profile-user-lastname"
              v-model:value="model.last_name"
              :placeholder="$t('wegow.profile.settings.lastName')"
            />
            <wegow-input
              id="profile-user-birthdate"
              v-model:value="model.birthday"
              type="date"
            />
            <cities-search
              id="profile-user-location"
              v-model:value="model.location"
              :placeholder="$t('wegow.profile.settings.formLocation')"
            />
          </div>
          <div class="md:mx-14 mx-3 flex flex-row justify-between">
            <w-radio
              id="genre_radio_male"
              v-model:group-value="model.genre"
              value="m"
              name="genre"
              label="Hombre"
            />
            <w-radio
              id="genre_radio_female"
              v-model:group-value="model.genre"
              value="f"
              name="genre"
              label="Mujer"
            />
            <w-radio
              id="genre_radio_other"
              v-model:group-value="model.genre"
              name="genre"
              label="Otro"
            />
          </div>
          <div class="flex flex-row justify-center">
            <wegow-button
              type="submit"
              size="base-large"
              text="Siguiente"
              background="opacity"
              color="topaz-light"
            />
          </div>
        </form>
      </div>
      <div v-else-if="step === 'genres'">
        <!-- TODO: Traducción -->
        <div
          class="text-color-topaz-light text-center text-s mb-5 top-16 sticky z-10"
        >
          Selecciona tus géneros favoritos
        </div>
        <!-- TODO: Generos con el nombre muy largo? -->
        <div v-if="loaded" class="genres relative">
          <div class="grid grid-cols-3 gap-2.5 mt-4 md:px-10">
            <div
              v-for="genre in genres"
              :key="genre.id"
              class="rounded-lg hover:cursor-pointer select-none"
              @click="onClickGenre(genre)"
            >
              <div class="relative h-full w-full">
                <div class="genre-item">
                  <img
                    :src="genre.thumbnails?.s_sq"
                    class="rounded-lg object-cover h-full w-full"
                    @error="
                      $event.target.src = require('@/assets/images/genre-placeholder.svg')
                    "
                  />

                  <div
                    class="genre-label absolute inset-0 flex flex-col justify-end text-left p-4 font-museo-500 md:text-m text-s z-10"
                  >
                    {{ genre.name }}
                  </div>
                </div>

                <div
                  v-if="isGenreSelected(genre)"
                  class="selected-mask rounded-lg absolute inset-0 bg-wegowBase bg-opacity-30 flex flex-row justify-end p-2"
                >
                  <w-icon
                    :img="require('@/assets/images/check.svg')"
                    :size="20"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            class="actions absolute inset-0 h-fit top-auto pointer-events-none sticky z-10"
          >
            <div
              class="actions-container bg-gradient-to-dark-blue py-4 flex flex-row justify-center gap-2"
            >
              <wegow-button
                class="pointer-events-auto"
                background="opacity"
                color="topaz"
                :text="`${selectedGenres.length} Seleccionados`"
                :disabled="selectedGenres.length < minGenres"
              />
              <wegow-button
                class="pointer-events-auto"
                background="opacity"
                color="topaz"
                type="submit"
                :text="
                  selectedGenres.length < minGenres
                    ? `Mínimo ${minGenres}`
                    : 'Siguiente'
                "
                :disabled="selectedGenres.length < minGenres"
                @click="nextStep(step)"
              />
            </div>
          </div>
        </div>
        <wegow-loader v-else />
      </div>
      <div v-else-if="step === 'artists'">
        <div class="flex flex-col artist-box">
          <span class="text-color-topaz-light text-center m-4">
            Selecciona tus artists favoritos
          </span>
          <div class="interactions flex flex-col justify-center gap-4">
            <wegow-input :icon="require('@/assets/images/ic-search.svg')" />

            <div class="sinc-actions flex flex-row justify-center mt-5">
              <wegow-button
                :icon="require('@/assets/images/ic-spotify.svg')"
                background="transparent"
                type="button"
                text-type="img-multiple"
                :text="$t('wegow.profile.settings.syncSpotify')"
                color="topaz"
              />
            </div>

            <span class="text-center sub-title"
              >¿Quieres incluir los artistas que escuchas habitualmente?
              Sincroniza y nuestra magia hará el resto.</span
            >
          </div>
        </div>
        <div v-if="loaded" class="md:px-20 relative">
          <div
            class="hidden-scrollbars gap-2 mt-4 overflow-auto pb-20 md:px-10"
          >
            <div v-for="genre in userGenres" :key="genre.id" class="py-5">
              <div class="top-title md:text-l text-s ml-5 mb-1.5">
                Top {{ genre.name }}
              </div>

              <carousel
                class="carousel fixed-carousel-arrows"
                :wrap-around="false"
                :breakpoints="{
                  0: {
                    itemsToShow: 1.7,
                    snapAlign: 'start'
                  },
                  1024: {
                    itemsToShow: 3,
                    snapAlign: 'start'
                  }
                }"
                :transition="500"
              >
                <slide
                  v-for="artist in genresTopArtists[`${genre.id}`]"
                  :key="artist.id"
                  :aria-hidden="false"
                >
                  <artist-card :artist="artist" />
                </slide>
                <template #addons>
                  <navigation v-if="browserWidth > 700" />
                </template>
              </carousel>
            </div>
          </div>
          <div
            class="actions absolute inset-0 h-fit top-auto pointer-events-none"
          >
            <div
              class="actions-container bg-gradient-to-dark-blue py-4 flex flex-row justify-center gap-2"
            >
              <wegow-button
                class="pointer-events-auto"
                background="opacity"
                color="topaz"
                :text="
                  `${userDetail.artists_count} ${$t('wegow.login.selecteds')}`
                "
                :disabled="userDetail.artists_count < minArtists"
              />

              <wegow-button
                class="pointer-events-auto"
                background="opacity"
                color="topaz"
                :text="
                  userDetail.artists_count < minArtists
                    ? `Mínimo ${minArtists}`
                    : 'Siguiente'
                "
                :disabled="userDetail.artists_count < minArtists"
                @click="nextStep(step)"
              />
            </div>
          </div>
        </div>

        <wegow-loader v-else />

        <!-- TODO: Recomendaciones + completar info -->
      </div>
      <div v-else>
        <div class="flex flex-col justify-center items-center gap-1">
          <w-icon
            :size="90"
            :img="require('@/assets/images/wegow-conciertos.svg')"
          />
          <span class="text-m font-museo-500 mt-5"
            >Descarga la app de Wegow</span
          >
          <span class="text-xs font-museo-300 scanner-text"
            >Escanea el código QR con tu móvil para obtener nuestra app</span
          >
          <div class="apps flex flex-row gap-4 justify-around my-5">
            <img
              class="h-10"
              :src="require('@/assets/images/ic-play-store.svg')"
            />
            <img
              class="h-10"
              :src="require('@/assets/images/ic-app-store.svg')"
            />
          </div>

          <div class="relative overflow-hidden">
            <div
              class="w-full h-full absolute inset-0 m-auto mt-24 flex flex-col items-center gap-20"
            >
              <w-icon
                :size="150"
                :img="require('@/assets/images/wegow-qr.svg')"
              />
            </div>
            <img
              class="h-96"
              :src="require('@/assets/images/wegow-phone.svg')"
            />
            <div class="absolute inset-0 h-fit top-auto ">
              <div
                class="actions-container bg-gradient-to-dark-blue py-12 flex flex-row justify-center gap-2"
              >
                <wegow-button
                  class="pointer-events-auto"
                  size="base-large"
                  background="opacity"
                  color="topaz"
                  text="¡Listo!"
                  @click="closeModal"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal-template>
</template>
<script setup>
import CitiesSearch from "@/components/general/forms/selectors/CitiesSearch.vue";
import WRadio from "@/components/general/forms/WRadio.vue";
import WegowButton from "@/components/general/forms/WegowButton.vue";
import WegowInput from "@/components/general/forms/WegowInput.vue";
import ModalTemplate from "@/components/general/modals/ModalTemplate.vue";
import WegowLoader from "@/components/general/ui/WegowLoader.vue";
import WIcon from "@/components/general/ui/elements/WIcon.vue";
import ArtistCard from "@/components/wegow/ui/cards/ArtistCard.vue";

import { useArtist } from "@/composables/useArtist";
import { useGeneral } from "@/composables/useGeneral";
import { useUser } from "@/composables/useUser";
import { useModalHandler } from "@/plugins/useModalHandler";
import imageCompression from "browser-image-compression";
import { computed, reactive, ref, watch } from "vue";
import { Carousel, Navigation, Slide } from "vue3-carousel";

const { activeModal, PROFILE_INFO, closeModal } = useModalHandler();
const { setProfile, followGenres, userStatus, userDetail } = useUser();
const { genres, getGenres } = useGeneral();
const { genresTopArtists, getGenresTopArtists } = useArtist();

const minGenres = 1;
const minArtists = 1;
const imageInput = ref(null);
const selectedGenres = ref([]);
const loaded = ref(false);
const model = reactive({
  image: undefined,
  location: null,
  first_name: undefined,
  last_name: undefined,
  gender: undefined,
  birthday: undefined
});

const imageUrl = computed(() =>
  model.image ? URL.createObjectURL(model.image) : ""
);

const userGenres = computed(() =>
  selectedGenres.value.length ? selectedGenres.value : userDetail.value.genres
);

const step = computed(() => {
  if (!userStatus.value.profile) return "profile";
  if (!userStatus.value.genres) return "genres";
  if (!userStatus.value.artists) return "artists";
  return undefined;
});

const size = computed(() => {
  if (
    step.value === "artists" ||
    (step.value === "genres" && browserWidth.value < 700) ||
    (step.value === "" && browserWidth.value < 700)
  )
    return "extra-large";
  return "intermedium-small";
});

const openImageSelector = () => imageInput.value.click();

const handleImageChange = async ({
  target: {
    files: [fileInput]
  }
}) => {
  if (!fileInput) return;
  const blob = await imageCompression(fileInput, {
    maxSizeMB: 0.3,
    maxWidthOrHeight: 400,
    fileType: "image/jpeg"
  });

  model.image = new File(
    [blob],
    `${blob.name.replace(/[.]\w{3,}$/gm, ".jpeg")}`,
    { type: "image/jpeg", lastModified: new Date() }
  );
};

const browserWidth = ref(
  window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth
);
const isGenreSelected = genre =>
  selectedGenres.value.some(({ id }) => id === genre.id);

const onClickGenre = genre => {
  if (isGenreSelected(genre)) {
    const genrePosition = selectedGenres.value.findIndex(
      ({ id }) => id === genre.id
    );
    selectedGenres.value.splice(genrePosition, 1);
  } else {
    selectedGenres.value.push(genre);
  }
};

const nextStep = current => {
  if (current === "genres") followGenres(userGenres.value.map(({ id }) => id));
  userStatus.value[current] = true;
};

watch(step, async newValue => {
  loaded.value = false;
  if (newValue === "genres") await getGenres();
  if (newValue === "artists")
    await getGenresTopArtists(userGenres.value.map(({ id }) => id));
  loaded.value = true;
});
</script>
<style lang="scss" scoped>
.hidden-scrollbars {
  max-height: 600px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.hidden-scrollbars::-webkit-scrollbar {
  display: none;
}
.artist-box {
  margin: 0 34%;
  @media screen and (max-width: $screen-md) {
    margin: 0 30px;
  }
}
.sub-title {
  @media screen and (max-width: $screen-md) {
    font-size: 11px;
  }
}
.scanner-text {
  @media screen and (max-width: $screen-md) {
    padding: 8px 13%;
  }
}
</style>
