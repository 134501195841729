import { VueCookieNext } from "vue-cookie-next";

const $cookie = VueCookieNext;
export const setCookie = async (name, value, expire = 60 * 60 * 24 * 365) => {
  const match_expression =
    process.env.VUE_APP_NUXT_ENV !== "local" ? "https://www" : "http://www";

  const config = {
    path: "/",
    expire: expire,
    domain: process.env.VUE_APP_NUXT_HOST.replace(match_expression, ""),
    secure: process.env.VUE_APP_NUXT_ENV !== "local"
  };
  $cookie.setCookie(name, value, config);
};

export const deleteCookie = cookie => {
  const match_expression =
    process.env.VUE_APP_NUXT_ENV !== "local" ? "https://www" : "http://www";

  if (!$cookie.getCookie(cookie)) return false;
  $cookie.removeCookie(cookie, {
    path: "/",
    expires: new Date(0),
    domain: process.env.VUE_APP_NUXT_HOST.replace(match_expression, ""),
    secure: process.env.VUE_APP_NUXT_ENV !== "local"
  });
  return true;
};
