<template>
  <!-- v-if="!object.permalink && params" -->
  <a
    v-if="!isLink"
    :href="undefined"
    :class="[full ? 'w-full flex flex-col' : '', isEvent ? 'h-full' : '']"
    :style="style"
    :target="blank ? '_blank' : ''"
  >
    <slot name="link" />
  </a>
  <router-link
    v-else-if="
      !object.permalink &&
        !object.companion_permalink &&
        link === '' &&
        params.name
    "
    :class="[full ? 'w-full flex flex-col' : '', isEvent ? 'h-full' : '']"
    :style="style"
    :to="parsedParams"
    :target="blank ? '_blank' : ''"
  >
    <slot name="link" />
  </router-link>
  <router-link
    v-else-if="isFromWegow"
    :to="cutUrl"
    :class="[full ? 'w-full flex flex-col' : '', isEvent ? 'h-full' : '']"
    :style="style"
    :target="blank ? '_blank' : ''"
  >
    <slot name="link" />
  </router-link>
  <a
    v-else
    :href="object.permalink || object.companion_permalink || link"
    :class="[full ? 'w-full flex flex-col' : '', isEvent ? 'h-full' : '']"
    :style="style"
    :target="blank ? '_blank' : ''"
  >
    <slot name="link" />
  </a>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useUser } from "@/composables/useUser";
import { useRouter, useRoute } from "vue-router";
import { LG } from "@/constants.js";

const { userDetail } = useUser();
const router = useRouter();
const route = useRoute();

const props = defineProps({
  isLink: {
    type: Boolean,
    default: true
  },
  object: {
    type: Object,
    required: false,
    default: () => {
      return {};
    }
  },
  params: {
    type: Object,
    default: () => {
      return {};
    }
  },
  style: {
    type: String,
    default: ""
  },
  link: {
    type: String,
    default: ""
  },
  specialClass: {
    type: String,
    default: ""
  },
  full: {
    type: Boolean,
    default: true
  },
  blank: {
    type: Boolean,
    default: false
  },
  isEvent: {
    type: Boolean,
    default: false
  }
});

const routerPath = ref("");

const parsedParams = computed(() => {
  let newParams = props.params;
  if (routerPath.value) {
    newParams.path = routerPath.value;
    newParams.name = undefined;
    newParams.params = undefined;
  } else {
    if (!newParams.params) {
      newParams.params = {
        region: userDetail.value.region || route.params.region || "es"
      };
    } else if (!newParams.params.region) {
      newParams.params.region =
        userDetail.value.region || route.params.region || "es";
    }
  }
  return newParams;
});

const isFromWegow = computed(() => {
  let url =
    props.link ||
    props.object.permalink ||
    props.object.companion_permalink ||
    "";
  return url.includes(process.env.VUE_APP_FRONT);
});

const cutUrl = computed(() => {
  if (isFromWegow.value) {
    let url =
      props.link || props.object.permalink || props.object.companion_permalink;
    return url.replace(process.env.VUE_APP_FRONT, "");
  } else {
    return "";
  }
});

onMounted(() => {
  if (
    props.params.name &&
    !props.object.permalink &&
    !props.object.companion_permalink &&
    props.link === ""
  ) {
    let routes = router
      .getRoutes()
      .filter(routeObj => routeObj.name === props.params.name);
    if (routes.length >= Object.keys(LG).length) {
      let routeIndex = 0;
      for (let key in LG) {
        let region = userDetail.value.region || route.params.region || "es";
        const index = LG[key].indexOf(region);
        if (index !== -1) {
          routerPath.value = routes[routeIndex].path;
          let paramsNames = Object.keys(props.params.params);
          for (const paramName of paramsNames) {
            routerPath.value = routerPath.value.replace(
              ":" + paramName,
              props.params.params[paramName]
            );
          }
          return;
        }
        routeIndex++;
      }
    } else if (routes.length === 0) {
      routerPath.value = routes[0].path;
      let paramsNames = Object.keys(props.params.params);
      for (const paramName of paramsNames) {
        routerPath.value = routerPath.value.replace(
          ":" + paramName,
          props.params.params[paramName]
        );
      }
    }
  }
});
</script>

<style></style>
