<template>
  <div />
</template>

<script setup>
import { usePixelStore } from "@/stores/modules/pixels";
import { onBeforeMount, ref, toRefs, watch } from "vue";
import { useUser } from "@/composables/useUser";

const { userDetail } = useUser();

const props = defineProps({
  pixel: {
    type: String,
    default: undefined
  }
});

const { setGaPixel, isPixelIncluded } = usePixelStore();
const { pixel } = toRefs(props);

const checkIfAnalyticsLoaded = () => {
  if (!pixel.value) return;
  const pixelformated = pixel.value.split(";");

  for (const value of pixelformated) {
    if (value.includes("ga('create'")) {
      let finalValue = value.match(/', '.*', '/);
      finalValue = value?.length
        ? finalValue[0].replace(/', '|', '/g, "").trim()
        : undefined;
      if (isPixelIncluded(finalValue)) return;
      addPixel(finalValue);
      setGaPixel(finalValue);
    }
  }
};

const addPixel = value => {
  window.gtag("config", value, {
    page_path: window.location.pathname,
    page_title: window.title,
    page_location: window.location.href
  });
};

onBeforeMount(() => checkIfAnalyticsLoaded());
watch(pixel, () => checkIfAnalyticsLoaded());
</script>

<style></style>
