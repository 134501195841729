import apiService from "@/api/ApiService";

const REFRESH_TOKEN_URL = "/jwt/token/refresh/";
const INITIAL_URL = "/initial/";
const AUTH_URL = "/auth/";
const LOGOUT_URL = "/auth/logout/";
const VALIDATE_PROMOTER_URL = `/promoter-registration/`;
const ACCOUNT_URL = "/account/";
const USERS_URL = "/users/";
const MY_USER_URL = "/users/me/";
const LOCATION_URL = "/locations/";
const TICKET_URL = "/my-tickets/";
const WESWAP_TICKET_URL = "/weswap-tickets/";
const USER_STATUS = "/user-status/";
const DELETE_ACCOUNT_URL = "/auth/me/";
const SET_FRIENDSHIP_STATUS = "/friendship/";
const GET_PERFECT_COMPANION = "/perfect-companion/";
const UPDATE_PRIVACY_CONFIG = "/user_privacy_configuration/";
const INVOICES_URL = "/purchases/invoices/";
const COVERAGES_URL = "/purchases/coverage/";
const BUYER_URL = "/buyers/";
const FORGET_PASSWORD_URL = "/password-reset/";
const VALIDATE_PASSWORD_URL = "/password-reset/validate_token/";
const CHANGE_PASSWORD_URL = "/password-reset/confirm/";
const VALIDATE_UNSUBSCRIBE_URL = "/check-unsuscribe/";
const UNSUBSCRIBE_URL = "/cancel-subscription/";
const CONFIRM_EMAIL = "/confirm-email/";

export class UserService {
  async refreshToken(params) {
    return (await apiService.post(REFRESH_TOKEN_URL, { ...params })).data;
  }

  async getInitial() {
    return (await apiService.get(INITIAL_URL)).data;
  }

  async auth(params) {
    return (await apiService.post(AUTH_URL, params)).data;
  }

  async closeSession() {
    return await apiService.post(LOGOUT_URL);
  }

  async validatePromoter() {
    await apiService.post(VALIDATE_PROMOTER_URL);
  }

  async getChatMembers(params) {
    return (await apiService.get(USERS_URL, { params })).data.users;
  }

  async followGenre(params) {
    return await apiService.post(USERS_URL + params.id + "/genres/", {
      genres: params.genres
    });
  }

  async getUsers(params) {
    return (await apiService.get(USERS_URL, { params })).data;
  }

  async getUser(slug) {
    return (await apiService.get(USERS_URL + slug + "/")).data;
  }

  async getUserStatus() {
    return (await apiService.get(USER_STATUS)).data;
  }

  async getMyUser() {
    return (await apiService.get(MY_USER_URL)).data;
  }

  async updateUser(params) {
    return (await apiService.patch(USERS_URL + params.id + "/", { ...params }))
      .data;
  }

  async updateAccount(params) {
    return (await apiService.patch(ACCOUNT_URL, { ...params })).data;
  }

  async updatePrivacy(params) {
    return await apiService.post(UPDATE_PRIVACY_CONFIG, { params });
  }

  async updateImageUser(params) {
    return (
      await apiService.post(USERS_URL + params.id + "/image/", params.data)
    ).data;
  }

  async deleteLocation(id) {
    return await apiService.delete(`${LOCATION_URL}${id}/`);
  }

  async addLocation(params) {
    return await apiService.post(LOCATION_URL, params);
  }

  async getMyTickets(params) {
    return (await apiService.get(TICKET_URL, { params })).data;
  }

  async getTicketsWeswap(params) {
    let newParam = "";
    if (
      process.env.VUE_APP_FRONT_VERSION &&
      Number(process.env.VUE_APP_FRONT_VERSION) >= 1
    ) {
      newParam = "?v4=True";
    }

    return (await apiService.get(WESWAP_TICKET_URL + newParam, { params }))
      .data;
  }

  async getEventInvoices(params) {
    return (await apiService.get(INVOICES_URL, { params })).data;
  }

  async getEventPurchasesCoverage(params) {
    return (await apiService.get(COVERAGES_URL, { params })).data;
  }

  async deleteAccount() {
    return await apiService.delete(DELETE_ACCOUNT_URL);
  }

  async setFriendshipStatus(params) {
    return await apiService.post(SET_FRIENDSHIP_STATUS, { ...params });
  }

  async deleteFriendship(params) {
    return await apiService.delete(SET_FRIENDSHIP_STATUS, { params });
  }

  async getPerfectCompanion() {
    return (await apiService.get(GET_PERFECT_COMPANION)).data;
  }

  async sendBuyer(payload) {
    return (await apiService.post(BUYER_URL, payload)).data;
  }

  async forgetPassword(params) {
    return await apiService.post(FORGET_PASSWORD_URL, params);
  }

  async validateTokenPassword(payload) {
    return await apiService.post(VALIDATE_PASSWORD_URL, payload);
  }

  async changePassword(params) {
    return await apiService.post(CHANGE_PASSWORD_URL, params);
  }

  async validateTokenUnsubscribe(payload) {
    return await apiService.post(VALIDATE_UNSUBSCRIBE_URL, payload);
  }

  async unsubscribeEmails(params) {
    return await apiService.post(UNSUBSCRIBE_URL, params);
  }

  async confirmEmail(code) {
    return (await apiService.get(CONFIRM_EMAIL + code)).data;
  }
}

const userService = new UserService();

export default userService;
