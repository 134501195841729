<template>
  <div
    v-if="type !== 'large' && showAd"
    class="event-card"
    :class="[type, { 'is-company': isCompany }]"
  >
    <w-navigation
      v-if="!event.add"
      :object="event"
      :params="{
        name: 'EventProfile',
        params: {
          slug: event.slug
        }
      }"
      :is-event="true"
      :blank="event.has_waiting_room"
      :is-link="isLink"
      class="event-card-navigation"
    >
      <template #link>
        <div class="card-image relative">
          <img
            :src="getImageUrl(event)"
            :alt="nameFormatted"
            class="lazyload main-image"
            @error="
              $event.target.src = require('@/assets/images/placeholder-event.svg')
            "
          />
          <img
            v-if="hasCulturalBonus(event, timezone)"
            :src="require('@/assets/images/cultural-bonus.png')"
            :alt="$t('wegow.altIcon')"
            class="cultural-icon absolute top-1.5 left-1.5 lazyload"
          />
          <div v-if="event.price || event.price === 0" class="price">
            <span class="font-museo-700 align-middle">
              {{
                formatCurrency(Number(event.price).toFixed(2), event.currency)
              }}
              <!-- TODO: revisar si es necesario el fixed -->
            </span>
          </div>

          <div
            v-if="event.user?.waiting_weswap"
            class="waiting-weswap flex items-start space-x-2 cursor-pointer absolute bottom-1.5 left-1.5"
            @click.prevent="goOutWaitingList"
          >
            <img
              :src="require('@/assets/images/ic-history-yellow.svg')"
              class="w-5"
            />
            <span>
              {{ $t("wegow.purchase.inWaitingList") }}
            </span>
          </div>
          <!-- <div
            v-if="
              event.cancelation_status &&
                event.cancelation_status !== 0 &&
                event.cancelation_status !== 4
            "
            class="event-status-container"
          >
            {{ $t("wegow.events.status" + event.cancelation_status) }}
          </div> -->
        </div>
        <div class="card-info">
          <span class="event-date font-museo-300">{{ eventDate }}</span>
          <h2 class="font-museo-500 text-left event-name">
            {{ nameFormatted }}
          </h2>
          <span
            class="followers-container text-s font-museo-700 flex-row gap-2 justify-end w-full md:flex hidden items-center"
          >
            <img
              :src="require('@/assets/images/ic-followers.svg')"
              :alt="$t('wegow.altIcon')"
              class="people-icon lazyload"
              loadign="lazy"
            />
            {{ event.followers_count }}
          </span>
        </div>
      </template>
    </w-navigation>
    <wegow-ads-card
      v-else
      :card="true"
      :position="event.add.wegow_position"
      :values="event.add"
      :adstype="event.adType"
      :class="event.adClass"
      @empty-ad="emptyAd"
    />
  </div>
  <div
    v-else-if="type === 'large' && showAd"
    class="event-card flex-1 large flex md:flex-row flex-col"
    :class="subtype"
  >
    <div class="card-image relative">
      <w-navigation
        :object="event"
        :params="{
          name: 'EventProfile',
          params: {
            slug: event.slug
          }
        }"
        :blank="event.has_waiting_room"
        :is-link="isLink"
        :is-event="true"
        class="h-full"
      >
        <template #link>
          <img
            :src="getImageUrl(event)"
            :alt="event.title"
            class="main-image"
            loading="lazy"
            @error="
              $event.target.src = require('@/assets/images/placeholder-event.svg')
            "
          />
        </template>
      </w-navigation>
    </div>

    <div class="card-info flex-1">
      <div class="info flex flex-col justify-between">
        <div class="header flex flex-col">
          <w-navigation
            :object="event"
            :params="{
              name: 'EventProfile',
              params: {
                slug: event.slug
              }
            }"
            :blank="event.has_waiting_room"
            :is-link="isLink"
          >
            <template #link>
              <span
                class="font-museo-500"
                :class="subtype !== 'profiles' ? 'text-m' : 'title-card'"
                v-html="nameFormatted"
              ></span>
            </template>
          </w-navigation>

          <w-navigation
            v-if="event.venue"
            :object="event.venue"
            :params="{
              name: 'VenueProfile',
              params: {
                slug: event.venue.venue
              }
            }"
            :blank="openExternal"
            :is-link="isLink"
          >
            <template #link>
              <span
                class="font-museo-500 text-color-topaz-light"
                :class="subtype !== 'profiles' ? 'text-s' : 'subtitle-card'"
                >{{ event.venue?.name }}
                {{ event.city?.name ? "- " + event.city.name : "" }}</span
              >
            </template>
          </w-navigation>
        </div>
        <div
          class="date-info text-color-gray font-museo-500 flex flex-row gap-x-6 md:mt-0 mt-4 flex-wrap"
          :class="subtype !== 'profiles' ? 'text-s' : 'subtitle-card'"
        >
          <div class="day">
            <img
              :src="require('@/assets/images/ic-calendar.svg')"
              :alt="$t('wegow.altIcon')"
              class="close-icon cursor-pointer lazyload w-[0.875rem] h-[0.875rem]"
            />
            <span>{{ eventDate }}</span>
          </div>
          <div class="hour">
            <img
              :src="require('@/assets/images/ic-clock.svg')"
              :alt="$t('wegow.altIcon')"
              class="close-icon cursor-pointer lazyload w-[0.875rem] h-[0.875rem]"
            />
            <span>{{ eventHour }}</span>
          </div>
        </div>
      </div>
      <div
        class="payment flex md:flex-col flex-row justify-between text-right md:mt-0 mt-1"
      >
        <div
          v-if="event.price || event.price === 0"
          class="price font-museo-500"
        >
          {{ formatCurrency(Number(event.price).toFixed(2), event.currency) }}
          <!-- TODO: revisar si es necesario el fixed -->
        </div>
        <div v-if="!event.queued">
          <div
            v-if="
              event.enabled &&
                (event.ticket_types_count > 0 || event.has_options)
            "
            class="purchase"
          >
            <w-navigation
              v-if="!createPurchase"
              :object="{}"
              :params="{
                name: 'PurchasePage',
                params: {
                  slug: event.slug
                }
              }"
              :blank="openExternal"
            >
              <template #link>
                <wegow-button
                  :button-id="`purchase-button-${event.id}`"
                  background="opacity"
                  color="topaz-light"
                  type="button"
                  :text="$t('wegow.purchase.buy')"
                />
              </template>
            </w-navigation>
            <wegow-button
              v-else
              :button-id="`purchase-button-${event.id}`"
              background="opacity"
              color="topaz-light"
              type="button"
              :text="$t('wegow.purchase.buy')"
              @click="$emit('clickPurchase')"
            />
          </div>
        </div>
        <div v-else>
          <wegow-button
            :button-id="`purchase-queued-button-${event.id}`"
            background="opacity"
            color="topaz-light"
            type="button"
            :text="$t('wegow.purchase.buy')"
            @click="onClickQueued(event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import WegowButton from "@/components/general/forms/WegowButton";
import WNavigation from "@/components/general/ui/WNavigation";

import WegowAdsCard from "@/components/general/ads/smartclip/WegowAdsCard.vue";

import { useEvent } from "@/composables/useEvent";
import { formatCurrency } from "@/plugins/currency";
import {
  formatLongDate,
  formatHour,
  formatDate
} from "@/plugins/date-formater";
import { computed, onMounted, toRefs, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useUser } from "@/composables/useUser";
import { useModalHandler } from "@/plugins/useModalHandler";

const props = defineProps({
  isLink: {
    type: Boolean,
    default: true
  },
  event: {
    type: Object,
    required: true
  },
  type: {
    type: String,
    default: undefined
  },
  subtype: {
    type: String,
    default: undefined
  },
  openExternal: {
    type: Boolean,
    default: false
  },
  createPurchase: {
    type: Boolean,
    default: false
  },
  imgHeight: {
    type: String,
    default: "51%"
  }
});

const emit = defineEmits("emptyAd", "clickPurchase");

const { event, type } = toRefs(props);
const route = useRoute();
const { selectedRegion } = useUser();
const { openErrorModal } = useModalHandler();
const { t } = useI18n();
const timezone = ref("Europe/Madrid");
const showAd = ref(true);
const isCompany = ref(false);

const { hasCulturalBonus, addToWaitingList } = useEvent();

onMounted(async () => {
  timezone.value = (await Intl.DateTimeFormat())
    .resolvedOptions()
    .timeZone.trim();
  // if (props.event.add) {
  //   showAd.value = false;
  // }
  if (route.name === "CompanyPage") {
    isCompany.value = true;
  }
});

const eventDate = computed(() =>
  formatDate(event.value.start_date, selectedRegion())
);
const eventHour = computed(() =>
  formatHour(event.value.start_date, route.params.region)
);

const getImageUrl = element =>
  element?.image_url ||
  element?.thumbnails?.s_cd ||
  require("@/assets/images/placeholder-event.svg");

const nameFormatted = computed(() => {
  let title = event.value.title;
  const replaceStrings = [
    t("wegow.headerDetails.start"),
    t("wegow.headerDetails.startStreaming")
  ];
  const replace = new RegExp(`(${replaceStrings.join(")|(")})`);
  return title?.replace(replace, "");
});

const emptyAd = $event => {
  emit("emptyAd", $event);
};

const goOutWaitingList = () => {
  openErrorModal({
    modal: {
      imageType: "none",
      showClose: false,
      title: t("wegow.purchase.goOutWaitingList"),
      btn_message: t("general.accept"),
      btn_red_message: t("general.cancel"),
      button_method: () => {
        addToWaitingList(event.value.id);
        event.value.user.waiting_weswap = false;
      }
    }
  });
};

const onClickQueued = event => {
  window.open(event.queue_link, "_blank");
};
</script>

<style lang="scss" scoped>
.payment {
  .price {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    @media screen and (max-width: $screen-md) {
      font-size: 18px;
    }
  }
}

.people-icon {
  width: 23px;
  height: 23px;
}
.event-card:not(.large) {
  width: 280px;
  max-height: 276px;
  height: auto;
  aspect-ratio: 280/276;
  border-radius: 5px;
  overflow: hidden;
  &.small {
    height: 150px;
    width: 163px;
    font-size: 12px;
    @media screen and (min-width: $screen-md) {
      width: 150px;
      &.is-company {
        height: 167px;
      }
      &:not(.is-company) {
        height: 160px;
      }
    }
    .followers-container {
      align-items: center;
      font-size: 12px;
      margin-top: auto;
      .people-icon {
        width: 18px;
        height: 18px;
      }
    }
    .event-date {
      font-size: 10px;
    }
    .event-name {
      font-size: 11px;
      @media screen and (min-width: $screen-md) {
        font-size: 12px;
        -webkit-line-clamp: 1 !important;
      }
    }
    .price {
      min-width: 46px !important;
      height: 24px !important;
      font-size: 11px !important;
      @media screen and (min-width: $screen-md) {
        min-width: 60px !important;
        height: 31px !important;
        font-size: 12px !important;
      }
    }

    // .event-date {
    //   font-size: 10px;
    // }
    // .event-name {
    //   font-size: 11px;
    //   @media screen and (min-width: $screen-md) {
    //     font-size: 1rem;
    //     -webkit-line-clamp: 1 !important;
    //   }
    // }
    // .people-icon {
    //   width: 11px;
    //   height: 11px;
    //   font-size: 8px;
    // }
    .cultural-icon {
      width: 24px;
      height: 23px;
    }
  }
  &.main-small {
    height: 161px;
    width: 163.58px;
    font-size: 11px;
    @media screen and (min-width: $screen-md) {
      width: 211px;
      height: 208px;
      font-size: 12px;
    }
    .price {
      min-width: 46px !important;
      height: 24px !important;
      font-size: 11px !important;
      @media screen and (min-width: $screen-md) {
        min-width: 60px !important;
        height: 31px !important;
        font-size: 12px !important;
      }
    }
    .event-date {
      font-size: 10px;
    }
    .event-name {
      font-size: 11px;
      @media screen and (min-width: $screen-md) {
        font-size: 12px;
        -webkit-line-clamp: 1 !important;
      }
    }
    .followers-container {
      align-items: center;
      font-size: 12px;
      margin-top: auto;
      .people-icon {
        width: 18px;
        height: 18px;
      }
    }

    .cultural-icon {
      width: 27px;
      height: 25px;
    }
  }
  &.big {
    font-size: 11px;
    height: 220px;
    width: 100%;
    @media screen and (min-width: $screen-md) {
      font-size: 1rem;
      height: 326px;
    }
    .followers-container {
      align-items: center;
      font-size: 12px;
      margin-top: auto;
      .people-icon {
        width: 18px;
        height: 18px;
      }
    }
    .event-date {
      font-size: 10px;
    }
    .event-name {
      font-size: 11px;
      @media screen and (min-width: $screen-md) {
        font-size: 12px;
        -webkit-line-clamp: 1 !important;
      }
    }
    .price {
      min-width: 46px !important;
      height: 24px !important;
      font-size: 11px !important;
      @media screen and (min-width: $screen-md) {
        min-width: 60px !important;
        height: 31px !important;
        font-size: 12px !important;
      }
    }
  }
  &.special {
    .price {
      min-width: 46px !important;
      height: 24px !important;
      font-size: 11px !important;
      @media screen and (min-width: $screen-md) {
        min-width: 60px !important;
        height: 31px !important;
        font-size: 12px !important;
      }
    }
    .card-info {
      background: rgb(28, 29, 41);
      background: linear-gradient(56deg, #1c1d29 -5%, #2effec 190%);
      font-size: 11px;
      @media screen and (min-width: $screen-md) {
        font-size: 12px;
      }
      .price {
        min-width: 46px !important;
        height: 24px !important;
        font-size: 11px !important;
        @media screen and (min-width: $screen-md) {
          min-width: 60px !important;
          height: 31px !important;
          font-size: 12px !important;
        }
      }
      .followers-container {
        align-items: center;
        font-size: 12px;
        margin-top: auto;
        .people-icon {
          width: 18px;
          height: 18px;
        }
      }
    }
  }

  .event-card-navigation {
    display: flex;
    flex-direction: column;
  }
  .card-image {
    height: v-bind(imgHeight);
    .main-image {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 5px 5px 0 0;
    }
    .cultural-icon {
      width: 32px;
      height: 30px;
    }
    .price {
      @apply flex justify-center items-center;
      min-width: 80px;
      height: 42px;
      position: absolute;
      right: 0;
      top: 10px;
      background: #1c1d29;
      opacity: 80%;
      padding: 12px 6px;
    }
    .event-status-container {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 80px;
      height: 42px;
      position: absolute;
      right: 0;
      bottom: 10px;
      background: #1c1d29;
      opacity: 80%;
      padding: 12px 6px;
    }
    .waiting-weswap {
      font-size: 14px;
      color: $yellowTag;
    }
  }
  .card-info {
    background: #1c1d29;
    border-radius: 0px 0px 5px 5px;
    color: $white;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;

    .event-title {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      text-align: left;
    }
    .event-name {
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: start;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-size: 11px;
      @media screen and (min-width: $screen-md) {
        font-size: 12px;
        -webkit-line-clamp: 1 !important;
      }
    }
    .event-date {
      font-size: 10px;
    }
    .followers-container {
      align-items: center;
      font-size: 12px;
      margin-top: auto;
      .people-icon {
        width: 18px;
        height: 18px;
      }
    }
    .followers {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      text-align: right;

      display: flex;
      width: 100%;
      justify-content: flex-end;
      align-items: baseline;
      img {
        margin-right: 6px;
        width: 18px;
      }
    }
  }
  .event-date {
    font-size: 11px;
    @media screen and (min-width: $screen-md) {
      font-size: 13px;
    }
  }
}

.large {
  background-color: #1c1d29;
  border-radius: 6px;
  overflow: hidden;
  min-width: 100%;
  &.small {
    @media screen and (min-width: $screen-md) {
      min-width: unset;
      max-width: 565px;
      height: 120px;
      flex-direction: row;
    }
    .event-date {
      font-size: 10px;
    }
    .event-name {
      font-size: 11px;
      @media screen and (min-width: $screen-md) {
        font-size: 1rem;
      }
    }
    .people-icon {
      width: 11px;
      height: 11px;
      font-size: 8px;
    }
    .cultural-icon {
      width: 24px;
      height: 23px;
    }
    .card-info {
      width: 70%;
      display: flex;
      flex-direction: column;
    }
    .card-image {
      min-height: 100%;
      width: 116px;
      height: 120px;
    }
  }
  .event-card-navigation {
    display: flex;
    flex-direction: row;
  }
  .card-image {
    width: 100%;
    height: 160px;
    @media screen and (min-width: $screen-md) {
      height: auto;
      width: 170px;
    }
    @media screen and (min-width: $screen-lg) {
      height: 160px;
      min-height: 160px;
      width: 160px;
    }
    img:not(.cultural-icon) {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .card-info {
    @apply m-6 flex md:flex-row flex-col justify-between;
    .day,
    .hour {
      @apply flex flex-row gap-2 items-center;
    }
    .purchase {
      margin-top: auto;
    }
  }
}
.title-card {
  font-size: 15px;
}
.subtitle-card {
  font-size: 12px;
}
.profiles {
  height: 120px;
  .card-image {
    min-height: 120px;
    height: 120px;
    width: 120px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .card-info {
    margin: 0;
    padding: 1.188rem;
  }
}
</style>
<style lang="scss">
.large {
  small {
    color: $textSubtitle;
  }
}
</style>
