<template>
  <modal-template
    v-if="activeModal === CREATE_CHAT_MODAL"
    size="small"
    class="modal-container"
    @close="closeModal"
  >
    <div v-if="loading" class="loading">
      <w-loader />
    </div>
    <div v-else class="form">
      <form @submit.prevent="create">
        <div class="header flex flex-row justify-around">
          <button
            type="button"
            class="clear-button rounded-full"
            @click.stop="openImageSelector"
          >
            <img
              width="70"
              height="70"
              class="border-0 rounded-full object-cover cursor-pointer"
              :src="imageUrl"
              @error="
                $event.target.src = require('@/assets/images/placeholder-user.svg')
              "
            />
            <input
              ref="imageInput"
              class="hidden"
              type="file"
              accept=".png, .jpg, .jpeg"
              @change="handleImageChange"
            />
          </button>
          <div class="chat-title m-auto">
            <h1 class="text-lg text-left">Título del chat</h1>
            <wegow-input
              v-model:value="model.title"
              placeholder="Escribe aquí"
            />
          </div>
        </div>
        <div class="friend-selector ">
          <div class="title flex flex-row justify-between my-2">
            <h1 class="text-lg text-left">
              Selecciona a tus amigos
            </h1>
            <small class="text-grayDetailsEmptyView mt-auto"
              >{{ model.members.length }}/{{ maxSelectableFriends }}</small
            >
          </div>
          <wegow-input
            id="search-friends-input"
            v-model:value="friendSearch"
            class="flex-1"
            background-input="bg-grayish-blue"
            focused
            :error-message="errorMessage"
            :focus-class="false"
            @update:value="afterFriendSearchUpdate"
          />
          <div
            v-if="friends.users.length"
            class="friends"
            @scroll="scrollEndHandler"
          >
            <button
              v-for="friend in friends.users"
              :key="friend.id"
              type="button"
              class="clear-button flex flex-row gap-4 relative py-1 px-5"
              :class="{ 'bg-wegowBase': isFriendSelected(friend) }"
              @click.stop="selectFriend(friend)"
            >
              <div class="img">
                <img
                  width="40"
                  height="40"
                  :src="getFriendImage(friend)"
                  :alt="friend?.permalink"
                  @error="
                    $event.target.src = require('@/assets/images/placeholder-user.svg')
                  "
                />
              </div>
              <div
                class="name my-auto text-sm font-museo-700"
                :class="{ 'text-black': isFriendSelected(friend) }"
              >
                <p>{{ getFriendName(friend) }}</p>
              </div>
              <div
                v-if="isFriendSelected(friend)"
                class="selected-friend absolute right-5 text-xl h-full top-1/4"
              >
                <w-icon
                  size="20"
                  img="https://cdn.wegow.com/no-delete/wegow/check.svg"
                />
              </div>
            </button>
          </div>
        </div>
        <wegow-button
          type="submit"
          text="Crear"
          size="xs"
          class="mt-4"
          background="opacity"
          color="topaz"
          :disabled="disabled"
        />
      </form>
    </div>
  </modal-template>
</template>
<script setup>
import WegowButton from "@/components/general/forms/WegowButton.vue";
import ModalTemplate from "@/components/general/modals/ModalTemplate.vue";
import WegowInput from "@/components/general/forms/WegowInput.vue";
import WLoader from "../ui/WegowLoader.vue";
import WIcon from "@/components/general/ui/elements/WIcon.vue";

import imageCompression from "browser-image-compression";
import cloneDeep from "lodash/cloneDeep";

import { computed, reactive, ref, watch } from "vue";
import { useModalHandler } from "@/plugins/useModalHandler";
import { useUser } from "@/composables/useUser";
import { useChat } from "@/composables/useChat";

const { CREATE_CHAT_MODAL, activeModal, closeModal } = useModalHandler();
const { getFriends, userDetail, list: friends } = useUser();
const { createChat, TYPE_GROUP } = useChat();

const baseModel = { image: null, title: null, members: [] };
const model = reactive({ ...cloneDeep(baseModel) });

const maxSelectableFriends = 30;
const pageSize = 5;

const imageInput = ref(null);
const friendsPage = ref(1);
const friendSearch = ref(null);
const loading = ref(false);

const imageUrl = computed(() =>
  model.image ? URL.createObjectURL(model.image) : ""
);

const disabled = computed(() => !model.title || model.members.length === 0);

const getFriendName = friend => {
  const name = `${friend.first_name} ${friend.last_name}`;
  if (name.length > 23) return `${name.slice(0, 23)}...`;
  else return name;
};

const create = async () => {
  if (disabled.value) return;
  loading.value = true;
  const payload = {
    title: model.title,
    type: TYPE_GROUP,
    members: model.members
  };
  await createChat(payload);
  loading.value = false;
  closeModal();
};

const scrollEndHandler = async ({ srcElement }) => {
  if (!friends.value.next_page) return;
  const isInContentEnd =
    srcElement.offsetHeight + srcElement.scrollTop >= srcElement.scrollHeight;
  if (isInContentEnd) {
    const oldFriendsList = friends.value.users;
    friendsPage.value += 1;
    await getNextFriends();
    friends.value.users = [...oldFriendsList, ...friends.value.users];
  }
};

const openImageSelector = () => imageInput.value.click();

const handleImageChange = async ({
  target: {
    files: [fileInput]
  }
}) => {
  if (!fileInput) return;
  const blob = await imageCompression(fileInput, {
    maxSizeMB: 0.3,
    maxWidthOrHeight: 400,
    fileType: "image/jpeg"
  });

  model.image = new File(
    [blob],
    `${blob.name.replace(/[.]\w{3,}$/gm, ".jpeg")}`,
    { type: "image/jpeg", lastModified: new Date() }
  );
};

const getFriendImage = friend =>
  friend?.thumbnails?.s_sq || require("@/assets/images/placeholder-user.svg");

const afterFriendSearchUpdate = async () =>
  setTimeout(async () => await getNextFriends(), 200);

const isFriendSelected = ({ id }) =>
  model.members.some(friend => friend === id);

const selectFriend = friend => {
  const allFriendsAded = model.members.length === maxSelectableFriends;
  const friendSelected = isFriendSelected(friend);
  if (!friendSelected && !allFriendsAded) {
    model.members.push(friend.id);
  } else if (friendSelected)
    model.members = model.members.filter(id => id !== friend.id);
};

const getNextFriends = async () => {
  await getFriends({
    id: userDetail.value.id,
    page_size: pageSize,
    page: friendsPage.value,
    search: friendSearch.value
  });
};

watch(activeModal, async (newValue, oldValue) => {
  if (newValue === oldValue) return;
  if (newValue === CREATE_CHAT_MODAL) await getNextFriends();
  else if (oldValue === CREATE_CHAT_MODAL)
    Object.assign(model, cloneDeep(baseModel));
});
</script>
<style lang="scss">
.friends {
  @apply flex flex-col max-h-44 overflow-auto hidden-scrollbars
  scroll-smooth bg-grayish-blue border-t-0 border border-gray-details
  rounded-sm;
}
</style>
