<template>
  <div />
</template>

<script setup>
import { usePixelStore } from "@/stores/modules/pixels";
import { onBeforeMount, toRefs, watch } from "vue";
import { useUser } from "@/composables/useUser";

const { userDetail } = useUser();
const { setFbPixel, isFbPixelIncluded } = usePixelStore();

const props = defineProps({
  pixel: {
    type: String,
    default: undefined
  }
});

const { pixel } = toRefs(props);

const checkIfFbqLoaded = () => {
  if (!pixel?.value) return;
  const pixelformated = pixel.value.split(";");
  for (const value of pixelformated) {
    let finalValue = pixel?.value;
    let regex = /'([^']+)'/g;
    finalValue = value.match(regex);

    if (finalValue && finalValue.length >= 2) {
      // Mostramos los finalValue obtenidos
      finalValue = finalValue[1].replace(/'/g, "");
      if (isFbPixelIncluded(finalValue)) return;
      setFbPixel(finalValue);

      window.fbq("init", finalValue);
      window.fbq("track", "PageView");
    }
  }
};

onBeforeMount(() => checkIfFbqLoaded());
watch(pixel, () => checkIfFbqLoaded());
</script>

<style></style>
