<template>
  <modal-template
    v-if="activeModal === CALENDAR"
    size="large"
    :is-static="isStatic"
    @close="closeModal"
  >
    <div class="flex flex-col md:flex-row calendar-container p-0 pt-4 md:p-8">
      <div class="w-full md:w-1/3 calendar">
        <h3 class="ml-4">{{ $t("wegow.recommendations.nexConcerts") }}</h3>
        <table v-if="calendar" class="table-condensed">
          <thead>
            <tr class="h-20">
              <th colspan="5" class="pl-4 text-left">
                {{ $t(MONTH_TRADUCTIONS[selectedMonth]) }} {{ selectedYear }}
              </th>
              <th colspan="2">
                <div class="flex justify-around">
                  <span
                    class="change-month"
                    :class="isThisMonth ? 'disabled-month' : 'cursor-pointer'"
                    @click="!isThisMonth ? subtractMonth() : ''"
                  >
                    &lt;
                  </span>
                  <span class="change-month cursor-pointer" @click="addMonth()">
                    >
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th
                v-for="weekDay in DAYS_TRADUCTIONS"
                :key="weekDay"
                class="week-day"
              >
                {{ $t(weekDay).substring(0, 3) }}
              </th>
            </tr>
            <tr
              v-for="(dateRow, index) in !loadMonth ? calendar : []"
              :key="index"
            >
              <slot v-for="(date, idx) in dateRow" name="date-slot">
                <td
                  :key="idx"
                  class="number-day"
                  :class="dayClass(date)"
                  @click="dayClass(date)['with-events'] ? setDay(date) : ''"
                >
                  {{ date.date() }}
                </td>
              </slot>
            </tr>
          </tbody>
        </table>
        <div v-if="loadMonth" class="loader-month">
          <wegow-loader />
        </div>
      </div>
      <div class="w-full md:w-2/3 recommendations">
        <p
          v-if="!loadDay && dayRecommendations.length === 0"
          class="text-center mt-8"
        >
          {{ $t("wegow.recommendations.noRecommendationsDay") }}
        </p>
        <div v-else-if="!loadDay" class="flex flex-col space-y-6 px-9 pb-9">
          <event-card
            v-for="event in dayRecommendations"
            :key="event._id"
            :event="event.event"
            type="large"
            :open-external="true"
          />
        </div>
        <div v-else class="pt-20">
          <wegow-loader />
        </div>
      </div>
    </div>
  </modal-template>
</template>

<script setup>
import { useRecommendation } from "@/composables/useRecommendation";
import { useModalHandler } from "@/plugins/useModalHandler";

import EventCard from "@/components/wegow/ui/cards/EventCard";
import ModalTemplate from "@/components/general/modals/ModalTemplate.vue";
import WegowLoader from "@/components/general/ui/WegowLoader";

import { DAYS_TRADUCTIONS, MONTH_TRADUCTIONS } from "@/constants.js";
import { computed, onMounted, ref, watch } from "vue";

import moment from "moment";

const { activeModal, CALENDAR, closeModal } = useModalHandler();

const {
  dayRecommendations,
  selectedYear,
  selectedMonth,
  calendar,
  loadDay,
  loadMonth,
  setDay,
  dayClass,
  addMonth,
  setDate,
  subtractMonth,
  getDayRecommendations,
  getMonthRecommendations
} = useRecommendation();

const props = defineProps({
  isStatic: {
    type: Boolean,
    default: false
  },
  origin: {
    type: String,
    default: ""
  }
});
const thisMonth = ref("");

watch(activeModal, async (newValue, oldValue) => {
  if (newValue === CALENDAR) {
    setDate(moment());
    await getMonthRecommendations();
    thisMonth.value =
      MONTH_TRADUCTIONS[selectedMonth.value] + selectedYear.value;
    await getDayRecommendations();
  }
});

const isThisMonth = computed(() => {
  return (
    thisMonth.value ===
    MONTH_TRADUCTIONS[selectedMonth.value] + selectedYear.value
  );
});

const emits = defineEmits(["close"]);
</script>
<style lang="scss" scoped>
.calendar-container {
  max-height: 500px;
  @media screen and (max-width: $screen-md) {
    max-height: 90%;
    .calendar {
      border-color: transparent !important;
    }
  }
  .table-condensed {
    width: 100%;
  }
  // Cambiar en version movil
  .calendar {
    border-right: 1px solid $textSubtitle;
    h3 {
      font-weight: 600;
      font-size: 25px;
    }
    .change-month {
      width: 24px;
      height: 24px;
      background-color: #404256;
      border-radius: 100%;
      text-align: center;
      color: $topaz;
      &.disabled-month {
        color: $backgroundBlack;
      }
    }

    .week-day {
      font-weight: 400;
      font-size: 11.7428px;
      line-height: 14px;
      color: #e4e4e4;
    }
    .number-day {
      color: $textSubtitle;
      padding: 5px 7px;
      border: 10px solid transparent;
      border-radius: 100%;
      text-align: center;
      cursor: not-allowed;
      &.off {
        color: #7f82955b;
      }
      &.with-events:not(.off) {
        color: $topaz;
        cursor: pointer;
      }
      &.today:not(.active) {
        background-color: $grey;
        color: $white !important;
        cursor: pointer;
      }
      &.active {
        background-color: $topaz;
        color: $backgroundBlack !important;
        cursor: pointer;
      }
    }
  }
  .recommendations {
    overflow-y: auto;
    min-height: 200px;
  }
  .loader-month {
    height: 300px;
  }
}
</style>
