import { storeToRefs } from "pinia";

import { useRecommendationStore } from "@/stores/modules/recommendation";
import { useUserStore } from "@/stores/modules/user";
import { ref, computed } from "vue";

import moment from "moment";

export const useRecommendation = () => {
  const recommendationStore = useRecommendationStore();
  const {
    recommendations,
    monthRecommendations,
    dayRecommendations,
    loadDay,
    loadMonth
  } = storeToRefs(recommendationStore);
  const userStore = useUserStore();
  const { userDetail } = storeToRefs(userStore);

  // local data
  const selectedYear = ref(2023);
  const selectedMonth = ref(0);
  const selectedDay = ref(1);
  const firstDayWeek = ref(1);

  const getRecommendations = async params => {
    await recommendationStore.getRecommendations(params);
    let landingAdd = {
      add: {
        wegow_position: 2,
        wegow_environment: "prod",
        wegow_section: "desktop-conciertos-destacados-para-ti",
        country: 1,
        id: `wegow-ads-container-` + parseInt(Math.random() * 1000)
      }
    };
    if (userDetail.value && userDetail.value.id) {
      landingAdd = {
        ...landingAdd,
        adType: "wegow-web-landing-ad",
        adClass: "wegow-web-home-event-ad"
      };
    } else {
      landingAdd = {
        ...landingAdd,
        adType: "wegow-web-landing-anonymous-ad",
        adClass: "wegow-web-home-event-ad"
      };
    }
    if (
      recommendations.value[0] &&
      recommendations.value[0]?.features.length > 0 &&
      recommendations.value[0].type === "event"
    ) {
      recommendations.value[0].features.splice(1, 0, landingAdd);
    }
    for (let index = 1; index < recommendations.value.length; index++) {
      if (
        recommendations.value[index] &&
        recommendations.value[index]?.features.length > 4 &&
        recommendations.value[index].type === "event" &&
        // recommendations.value[index].style_type !== 2 &&
        recommendations.value[index].style_type !== 3 &&
        recommendations.value[index].style_type !== 5
      ) {
        let position = parseInt(Math.random() * 3) + 1;
        let cardAd = {
          add: {
            wegow_position: position + 1,
            wegow_environment: "prod",
            wegow_section: recommendations.value[index].slug,
            country: 1,
            id: `wegow-ads-container-` + parseInt(Math.random() * 1000)
          },
          adType: "wegow-web-event-ad",
          adClass: "wegow-web-home-event-ad"
        };
        recommendations.value[index].features.splice(position, 0, cardAd);
      }
    }
  };

  const deleteAd = id => {
    for (let index = 0; index < recommendations.value.length; index++) {
      if (
        recommendations.value[index] &&
        recommendations.value[index]?.features.length > 0 &&
        recommendations.value[index].type === "event"
      ) {
        let indexDelete = recommendations.value[index].features.findIndex(
          ad => {
            return ad.add?.id === id;
          }
        );
        if (indexDelete !== -1) {
          recommendations.value[index].features.splice(indexDelete, 1);
        }
      }
    }
  };

  const getDayRecommendations = async () => {
    loadDay.value = true;
    let parsedDay =
      selectedDay.value < 10 ? "0" + selectedDay.value : selectedDay.value;
    let parsedMonth =
      selectedMonth.value + 1 < 10
        ? "0" + (selectedMonth.value + 1)
        : selectedMonth.value + 1;
    let payload = {
      date: selectedYear.value + "-" + parsedMonth + "-" + parsedDay
    };
    await recommendationStore.getDayRecommendations(payload);
    loadDay.value = false;
  };

  const getMonthRecommendations = async () => {
    loadMonth.value = true;
    let parsedMonth =
      selectedMonth.value + 1 < 10
        ? "0" + (selectedMonth.value + 1)
        : selectedMonth.value + 1;
    let payload = {
      date: selectedYear.value + "-" + parsedMonth
    };
    await recommendationStore.getMonthRecommendations(payload);
    loadMonth.value = false;
  };

  const dayClass = date => {
    let dt = new Date(date);
    dt.setHours(0, 0, 0, 0);
    let start = new Date(new Date());
    start.setHours(0, 0, 0, 0);
    let end = new Date(new Date());
    end.setHours(0, 0, 0, 0);
    return {
      off: date.month() !== selectedMonth.value,
      // weekend: date.isoWeekday() > 5,
      today: dt.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0),
      active:
        dt.setHours(0, 0, 0, 0) ===
        new Date(
          selectedYear.value,
          selectedMonth.value,
          selectedDay.value
        ).setHours(0, 0, 0, 0),
      "with-events": hasEvents(dt),
      // TODO: comprobar si hay recomendaciones
      disabled: date.month() !== selectedMonth.value
    };
  };

  const hasEvents = dt => {
    if (monthRecommendations.value) {
      for (const recomendation of monthRecommendations.value) {
        if (dt.getDate() === Number(recomendation)) {
          return true;
        }
      }
    }
    return false;
  };

  const subtractMonth = async () => {
    let firstDay = new Date(selectedYear.value, selectedMonth.value, 1);
    let lastMonth = moment(firstDay)
      .subtract(1, "months")
      .isBefore(moment())
      ? moment()
      : moment(firstDay).subtract(1, "months");
    await setDate(lastMonth);
    await getMonthRecommendations();
    await getDayRecommendations();
  };
  const addMonth = async () => {
    let firstDay = new Date(selectedYear.value, selectedMonth.value, 1);
    let nextMonth = moment(firstDay).add(1, "months");
    await setDate(nextMonth);
    await getMonthRecommendations();
    await getDayRecommendations();
  };
  const setDay = async day => {
    await setDate(day);
    await getDayRecommendations();
  };
  const setDate = async date => {
    selectedYear.value = date.year();
    selectedMonth.value = date.month();
    selectedDay.value = date.date();
  };

  return {
    //! Properties
    recommendations,
    dayRecommendations,
    monthRecommendations,
    selectedYear,
    selectedMonth,
    selectedDay,
    firstDayWeek,
    loadDay,
    loadMonth,

    // computed
    calendar: computed(() => {
      let firstDay = new Date(selectedYear.value, selectedMonth.value, 1);
      let lastMonth = moment(firstDay)
        .subtract(1, "month")
        .month();
      let lastYear = moment(firstDay)
        .subtract(1, "month")
        .year();
      let daysInLastMonth = moment([lastYear, lastMonth]).daysInMonth();

      let dayOfWeek = firstDay.getDay();

      let calendar = [];

      for (let i = 0; i < 6; i++) {
        calendar[i] = [];
      }

      let startDay = daysInLastMonth - dayOfWeek + firstDayWeek.value + 1;
      if (startDay > daysInLastMonth) startDay -= 7;

      if (dayOfWeek === firstDayWeek.value) startDay = daysInLastMonth - 6;

      let curDate = moment([lastYear, lastMonth, startDay, 12, 0, 0]);
      for (
        let i = 0, col = 0, row = 0;
        i < 6 * 7;
        i++, col++, curDate = moment(curDate).add(1, "day")
      ) {
        if (i > 0 && col % 7 === 0) {
          col = 0;
          row++;
        }
        calendar[row][col] = curDate.clone();
        curDate.hour(12);
      }

      return calendar;
    }),

    //! Metodos
    dayClass,
    subtractMonth,
    setDay,
    addMonth,
    setDate,
    getRecommendations,
    getDayRecommendations,
    getMonthRecommendations,
    deleteAd
  };
};
