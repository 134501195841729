<template>
  <modal-template
    v-if="activeModal === UNSUBSCRIBE_EMAILS"
    size="normal"
    :is-static="false"
    @close="closeModal"
  >
    <wegow-loader v-if="loading" class="load-more-loader" :middle="true" />
    <div v-else id="container-unsubscribe">
      <div class="pt-4 md:pt-12 pb-4 md:pb-12 md:mx-16">
        <h3 class="mb-4">
          {{ $t("wegow.profile.settings.unsubscribeTitle") }}
        </h3>
        <p class="mb-8">
          {{ $t("wegow.profile.settings.unsubscribeSubtitle") }}
        </p>
        <div class="flex space-x-4 justify-center">
          <wegow-button
            color="white"
            border="topaz"
            :text="$t('general.cancel')"
            @click="closeModal()"
          />
          <wegow-button
            color="topaz"
            :text="$t('general.accept')"
            @click="unsubscribe()"
          />
        </div>
        <p v-if="isAuth" class="text-center mt-4 cursor-pointer settings-title">
          <w-navigation
            :params="{
              name: 'ProfileSettingsPage',
              params: {
                region: userDetail.region
              }
            }"
            :full="false"
            @click="close()"
          >
            <template #link>
              {{ $t("wegow.profile.settings.manageNotifications") }}
            </template>
          </w-navigation>
        </p>
      </div>
    </div>
  </modal-template>
</template>

<script setup>
import { useUser } from "@/composables/useUser";

import WegowButton from "@/components/general/forms/WegowButton";
import ModalTemplate from "@/components/general/modals/ModalTemplate.vue";
import WegowLoader from "@/components/general/ui/WegowLoader";
import WNavigation from "@/components/general/ui/WNavigation";

import { useModalHandler } from "@/plugins/useModalHandler";
import { ref, watch } from "vue";
import { useRoute } from "vue-router";

const { isAuth, userDetail, unsubscribeEmails } = useUser();

const route = useRoute();

const { activeModal, UNSUBSCRIBE_EMAILS, closeModal } = useModalHandler();

const loading = ref(false);
const token = ref("");

watch(activeModal, async (newValue, _oldValue) => {
  if (newValue === UNSUBSCRIBE_EMAILS) {
    token.value = route.query.token;
  }
});

const unsubscribe = () => {
  unsubscribeEmails(token.value);
};

const close = () => {
  setTimeout(() => {
    closeModal();
  }, 250);
};
</script>

<style lang="scss" scoped>
#container-unsubscribe {
  h3 {
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
  }
  .settings-title {
    color: $topaz;
  }
}
</style>
