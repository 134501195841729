const routesName = [
  "u-param-EventProfile",
  "u-param-ArtistProfile",
  "u-param-VenueProfile",
  "u-param-PurchasePage",
  "u-param-CompanyPage",
  "r-param-EventProfile",
  "r-param-ArtistProfile",
  "r-param-VenueProfile",
  "r-param-PurchasePage",
  "r-param-CompanyPage"
];
export function setParameters(key, route, type) {
  let param = "";
  // Compruebo si existe algún parámetro, en ese caso, lo sustituyo por el nuevo
  checkExistsParameter();
  if (type === "r") {
    param = JSON.stringify({ r: route.query.r, slug: route.params.slug });
  } else if (type === "u") {
    param = JSON.stringify({ u: route.query.u, slug: route.params.slug });
  }
  localStorage.setItem(key, param);
}
function checkExistsParameter() {
  for (const route of routesName) {
    const parameter = localStorage.getItem(route);
    if (parameter) {
      deleteParameter(route);
    }
  }
}

function deleteParameter(key) {
  localStorage.removeItem(key);
}

export function checkParameterSlug(slug) {
  for (const route of routesName) {
    const jsonData = localStorage.getItem(route);
    if (jsonData) {
      const parsedData = JSON.parse(jsonData);
      const regex = new RegExp(`${parsedData.slug}\\w*`, "g");
      if (regex.test(slug)) {
        return route;
      }
    }
  }
  return null;
}
