import apiService from "@/api/ApiService";

const MOMENT_URL = "/moments/";
const MOMENT_TAGS_URL = "/moment-tag/";
const MOMENT_COMMENT_URL = "/moment-comment/";
const MOMENT_LIKE_URL = "/moment-like/";
const MOMENT_REPORT_URL = "/moment-report/";
const MOMENT_MEDIA_URL = "/moment-media/";

export class MomentService {
  async getMoments(params) {
    return (await apiService.get(MOMENT_URL, { params })).data;
  }

  async getMoment(id) {
    return (await apiService.get(`${MOMENT_URL}${id}/`)).data;
  }

  async getMomentTag(params) {
    return (await apiService.get(MOMENT_TAGS_URL, { params })).data;
  }

  async removeMoment(id) {
    return (await apiService.delete(`${MOMENT_URL}${id}/`)).data;
  }

  async postMoment(params) {
    return (await apiService.post(MOMENT_URL, params)).data;
  }

  // async postMomentImage(params) {
  //   return (
  //     await apiService.post(MOMENT_URL + params.id + "/", params.data)
  //   ).data;
  // }

  async postComment(params) {
    return (await apiService.post(MOMENT_COMMENT_URL, { ...params })).data;
  }

  async postLike(params) {
    return (await apiService.post(MOMENT_LIKE_URL, { ...params })).data;
  }

  async reportMoment(params) {
    return (await apiService.post(MOMENT_REPORT_URL, { ...params })).data;
  }
}

const momentService = new MomentService();

export default momentService;
