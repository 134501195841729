import apiService from "@/api/ApiService";

const ARTIST_URL = "/artists/";
const MEDIA = "/media/";
const USER_ARTIST = "/user_artists/";
const LOCATIONS_URL = "/hightlight-locations/";

export class ArtistService {
  async getArtist(data) {
    return (
      await apiService.get(`${ARTIST_URL}${data.slug}/`, {
        params: data.params
      })
    ).data;
  }

  async getArtists(params) {
    return (await apiService.get(ARTIST_URL, { params })).data;
  }

  async getArtistMedia(params) {
    return (await apiService.get(MEDIA, { params })).data;
  }

  async followArtist(params) {
    return await apiService.post(USER_ARTIST, params);
  }

  async unfollowArtist(params) {
    return await apiService.delete(USER_ARTIST, { params });
  }

  async getLocations(params) {
    return (await apiService.get(LOCATIONS_URL, { params })).data;
  }
}

const artistService = new ArtistService();

export default artistService;
