import { storeToRefs } from "pinia";

import { usePaymentStore } from "@/stores/modules/payment";
import { useModalHandler } from "@/plugins/useModalHandler";

import { ref } from "vue";

export const useInsurance = () => {
  const { openErrorModal } = useModalHandler();

  const paymentStore = usePaymentStore();
  const { thereIsInsurance, insuranceData } = storeToRefs(paymentStore);

  const addInsurance = (insuranceDataLocal, insured) => {
    thereIsInsurance.value = true;
    insuranceData.value = { ...insuranceDataLocal, insured: insured };
  };

  const createInsurance = async payload => {
    try {
      await paymentStore.createInsurance(payload);
      return true;
    } catch (e) {
      thereIsInsurance.value = false;
      insuranceData.value.insured = undefined;
      return false;
    }
  };

  const payInsurance = async params => {
    await paymentStore.payInsurance(params);
  };

  const getInsurance = payload => {
    // TODO: insuranceDetail complete
  };

  const cancelInsurance = async (purchaseReference, showSuccess = false) => {
    try {
      await paymentStore.cancelInsurance(purchaseReference);
      thereIsInsurance.value = false;
      insuranceData.value.insured = undefined;
      if (showSuccess) {
        openErrorModal({
          modal: {
            imageType: "accept",
            title: "wegow.payment.insurance.cancelSuccess",
            btn_message: "wegow.error.accept"
          }
        });
      }
    } catch (e) {
      console.log("error al cancelar el seguro:", e);
    }
  };

  return {
    insuranceData,
    thereIsInsurance,

    addInsurance,
    createInsurance,
    payInsurance,
    getInsurance,
    cancelInsurance
  };
};
