<template>
  <modal-template
    v-if="activeModal === INSURANCE_MODAL && !showPurchses"
    title="Asegura tu entrada"
    @close="loading ? '' : closeModal()"
  >
    <div
      v-for="(simplePurchase, index) in purchasesCoverage"
      :key="index"
      class="m-10"
    >
      <purchase-insurance-component
        :purchase="simplePurchase"
        @select-purchase="selectPurchase(simplePurchase)"
      />
      <div
        v-if="index < purchasesCoverage.length - 1"
        class="flex-grow border-t border-grayInputBorder my-8"
      />
    </div>
  </modal-template>
  <insurance-modal
    v-else-if="activeModal === INSURANCE_MODAL"
    :my-tickets="purchaseToInsure"
    @close-insurance-modal="
      showPurchses = false;
      closeModal();
    "
  >
  </insurance-modal>
</template>
<script setup>
import ModalTemplate from "@/components/general/modals/ModalTemplate.vue";
import PurchaseInsuranceComponent from "@/components/general/ui/elements/PurchaseInsuranceComponent.vue";
import InsuranceModal from "@/components/wegow/modals/InsuranceModal";
import { useModalHandler } from "@/plugins/useModalHandler";
import { ref, toRefs, watch } from "vue";
import { useUser } from "@/composables/useUser";

const { purchasesCoverage, getEventPurchasesCoverage } = useUser();
const { activeModal, closeModal, INSURANCE_MODAL } = useModalHandler();

const props = defineProps({
  eventId: { type: String, required: false, default: null },
  purchase: { type: String, required: false, default: null }
});
const { eventId } = toRefs(props);

const showPurchses = ref(false);
const loading = ref(false);
const purchaseToInsure = ref({});

const selectPurchase = purchase => {
  loading.value = true;
  purchaseToInsure.value = purchase;
  purchaseToInsure.value.event_id = eventId.value;
  purchaseToInsure.value.insurance_premium = purchase.insurance_budget;
  showPurchses.value = true;
  setTimeout(() => {
    loading.value = false;
  }, 100);
};

const init = () =>
  (async () => {
    if (props.purchase) {
      purchaseToInsure.value = props.purchase;
      purchaseToInsure.value.event_id = eventId.value;
      showPurchses.value = true;
    } else {
      await getEventPurchasesCoverage(eventId.value);
      if (purchasesCoverage.value.length === 1) {
        purchaseToInsure.value = purchasesCoverage.value[0];
        purchaseToInsure.value.event_id = eventId.value;
        purchaseToInsure.value.insurance_premium =
          purchasesCoverage.value[0].insurance_budget;
        showPurchses.value = true;
      }
    }
  })();
watch(eventId, () => init());
</script>
