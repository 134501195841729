import apiService from "@/api/ApiService";

const RECOMMENDATION_URL = "/recommendations/";
const DATE_RECOMMENDATION_URL = "/recommendations-by-date/";

export class RecommendationService {
  async getRecommendations(params) {
    return (await apiService.get(RECOMMENDATION_URL, { params })).data;
  }

  async getDayRecommendations(params) {
    return (await apiService.get(DATE_RECOMMENDATION_URL, { params })).data;
  }
}

const recommendationService = new RecommendationService();

export default recommendationService;
