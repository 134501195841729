<template>
  <modal-template
    v-if="activeModal === PERFECT_COMPANION"
    size="intermedium"
    close-color="black"
    @close="closeModal"
  >
    <div class="friend-modal">
      <div class="perfect-friend flex flex-col justify-evenly">
        <h3>{{ $t("wegow.profile.perfectCompanion.title") }}</h3>
        <w-navigation
          v-if="perfectCompanion.user && !gettingPerfectCompanion"
          :object="perfectCompanion"
          :full="false"
          :params="{
            name: 'ProfilePage',
            params: { slug: perfectCompanion.slug }
          }"
          @click="close()"
        >
          <template #link>
            <span
              v-if="perfectCompanion"
              class="post-header flex justify-center"
            >
              <img
                :src="getUserImageUrl(perfectCompanion)"
                :alt="perfectCompanion.companion_full_name"
                class="object-cover"
                @error="
                  $event.target.src = require('@/assets/images/placeholder-user.svg')
                "
              />
              <div class="flex flex-col justify-center user-data">
                <p class="friend-name">
                  <strong>{{ perfectCompanion.companion_full_name }}</strong>
                </p>
                <!-- TODO: comprobar si el usuario es privado -->
                <p
                  v-if="
                    perfectCompanion.companion_location &&
                      perfectCompanion.companion_location.name
                  "
                  class="user-info flex"
                >
                  <img
                    :src="require('@/assets/images/ic-location-black.svg')"
                    :alt="$t('wegow.altIcon')"
                    class="w-8"
                  />
                  <span class="location">
                    {{ perfectCompanion.companion_location.name }}
                  </span>
                </p>
              </div>
            </span>
          </template>
        </w-navigation>
        <div v-else-if="gettingPerfectCompanion">
          <span class="text-black">{{
            $t("wegow.profile.perfectCompanion.searching")
          }}</span>
          <wegow-loader color="black" />
        </div>
        <div v-else class="text-black px-4">
          {{ $t("wegow.profile.perfectCompanion.notFound") }}
        </div>
      </div>
      <div
        v-if="perfectCompanion.companion_events"
        class="concerts-together mb-5 mt-7"
      >
        {{
          $t("wegow.profile.concertsTogether", {
            concerts: perfectCompanion.companion_events.length
          })
        }}
        <p v-if="perfectCompanion.companion_events.length === 0">
          {{ $t("wegow.profile.likeSameMusic") }}
        </p>
      </div>
      <!-- TODO: edit to make carousel like figma -->
      <div
        v-if="
          perfectCompanion.user &&
            !isPrivate &&
            perfectCompanion.companion_events.length > 0
        "
        style="width: 400px; margin-right: auto; margin-left: auto;"
        class="mb-7"
      >
        <carousel :items-to-show="2" :wrap-around="false" :transition="500">
          <slide
            v-for="event in perfectCompanion.companion_events"
            :key="event.id"
            :aria-hidden="false"
            class="py-8"
          >
            <event-card v-if="event" :event="event" />
          </slide>
          <template #addons>
            <navigation />
          </template>
        </carousel>
      </div>
    </div>
  </modal-template>
</template>

<script setup>
import ModalTemplate from "@/components/general/modals/ModalTemplate.vue";
import WNavigation from "@/components/general/ui/WNavigation";
import WegowLoader from "@/components/general/ui/WegowLoader";
import EventCard from "@/components/wegow/ui/cards/EventCard";

import { useUser } from "@/composables/useUser";
import { useModalHandler } from "@/plugins/useModalHandler";
import { computed, watch } from "vue";
import { Carousel, Navigation, Slide } from "vue3-carousel";

const { activeModal, PERFECT_COMPANION, closeModal } = useModalHandler();

const isPrivate = computed(() => {
  // TODO: revisar para el acompañante perfecto
  // const isPublic =
  //   perfectCompanion.value.privacy_configuration?.profile_status === "public";
  // const areFriends = perfectCompanion.value?.friendship?.status === "friends";

  // return !isPublic && !areFriends;
  return false;
});

defineEmits(["closeFriendModal"]);

const {
  perfectCompanion,
  gettingPerfectCompanion,
  getPerfectCompanion
} = useUser();

watch(activeModal, async (newValue, _oldValue) => {
  if (newValue === PERFECT_COMPANION) {
    await getPerfectCompanion();
  }
});

const getUserImageUrl = element => {
  if (element.companion_image_url) {
    return element.companion_image_url;
  }
  return require("@/assets/images/placeholder-user.svg");
};
const close = () => {
  setTimeout(() => {
    closeModal();
  }, 600);
};
</script>

<style lang="scss" scoped>
.friend-modal {
  margin: -25px;
  .text-black {
    color: $black;
  }
  .event-card {
    border: 5px solid $backgroundBlack;
  }
  .perfect-friend {
    height: 250px;
    background-color: $topaz;
    border-radius: 5px;
    position: relative;
    h3,
    .friend-name {
      font-weight: 400;
      font-size: 25px;
      line-height: 30px;
      color: $black;
    }

    .post-header {
      width: 100%;
      img {
        height: 67px;
        width: 67px;
        border-radius: 50%;
      }
      .user-data {
        margin-left: 10px;
        & img {
          width: 17px;
          height: 17px;
          margin-right: 4px;
        }
        & .location {
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          color: $black;
        }
      }
    }
  }
  .concerts-together {
    color: $topaz;
  }
}
</style>

<style lang="scss">
.friend-modal {
  .carousel__track {
    transform-style: preserve-3d;
  }

  .carousel__slide--sliding {
    transition: 0.5s;
  }

  .carousel__slide {
    opacity: 0.9;
    transform: rotateY(-20deg) scale(0.9);
  }

  .carousel__slide--active ~ .carousel__slide {
    transform: rotateY(20deg) scale(0.9);
  }

  .carousel__slide--prev {
    opacity: 1;
    transform: rotateY(-10deg) scale(0.95);
  }

  .carousel__slide--next {
    opacity: 1;
    transform: rotateY(10deg) scale(0.95);
  }

  .carousel__slide--active {
    opacity: 1;
    transform: rotateY(0) scale(1.1);
  }

  .carousel__icon {
    color: $white;
  }
  .carousel__next {
    margin-right: -30px;
  }
  .carousel__prev {
    margin-left: -30px;
  }
}
</style>
