<template>
  <!-- TODO: revisar version movil -->
  <modal-template
    :title="$t('wegow.payment.insurance.insuredTicket')"
    @close="closeModal"
  >
    <div class="insurance-modal">
      <p class="title-principal">
        {{ $t("wegow.payment.insurance.insuranceModal.fillFields") }}
      </p>
      <div class="insurance-btn">
        <div v-if="purchase.id" class="w-full">
          <div class="data">
            <insurance-form
              id="payment_insurance"
              ref="insuranceForm"
              class="mb-1"
              dont-auto-send
            />
            <card-pay-form
              v-model:valid-card="validCard"
              v-model:valid-date="validDate"
              v-model:valid-cvc="validCvc"
              class="payment-credit-card"
              text-button="Pagar seguro"
              @card-payment="manageData(false)"
              @card-data="card = $event"
            >
              <template #top-extra> </template>

              <template #extra>
                <p class="ingood-info">
                  <span
                    v-html="$t('wegow.payment.insurance.ingoodInfo')"
                  ></span>
                </p>
              </template>
            </card-pay-form>
          </div>
          <div></div>
        </div>
        <div v-else>
          <wegow-button
            type="submit"
            :text="
              cancelInsured
                ? $t('wegow.payment.insurance.insuranceModal.editInsurance')
                : $t('wegow.payment.insurance.insuranceModal.addInsurance')
            "
            size="xl"
            color="topaz-light"
            background="opacity"
          />
        </div>
      </div>
    </div>
  </modal-template>

  <ga-pixel v-if="event?.google_pixel_js" :pixel="event.google_pixel_js" />
  <fb-pixel v-if="event?.facebook_pixel_js" :pixel="event.facebook_pixel_js" />
</template>

<script setup>
import { useEvent } from "@/composables/useEvent";
import { useInsurance } from "@/composables/useInsurance";
import { usePayment } from "@/composables/usePayment";
import { usePurchase } from "@/composables/usePurchase";
import { useModalHandler } from "@/plugins/useModalHandler";

import FbPixel from "@/components/general/FbPixel.vue";
import GaPixel from "@/components/general/GaPixel.vue";
import CardPayForm from "@/components/wegow/payment/CardPayForm";
import WegowButton from "@/components/general/forms/WegowButton";
import ModalTemplate from "@/components/general/modals/ModalTemplate";
import InsuranceForm from "@/components/wegow/payment/InsuranceForm.vue";

import { ref, watch, toRefs, onMounted } from "vue";
import { formatCurrency } from "@/plugins/currency";

const { payInsurance } = useInsurance();
const { paymentData, insuranceData } = usePayment();
const { details: event } = useEvent();
const { openErrorModal, closeBigLoader, errorModal } = useModalHandler();
const { purchaseData } = usePurchase();

const emit = defineEmits(["closeInsuranceModal"]);
const props = defineProps({
  myTickets: {
    type: Object,
    default: () => {
      return {};
    }
  }
});

const { myTickets: purchase } = toRefs(props);

const card = ref({});
const validCard = ref(false);
const validCvc = ref(false);
const validDate = ref(false);
const cancelInsured = ref(false);
const insuranceForm = ref(null);
const response = ref(null);
const showError = ref(false);

const closeModal = () => {
  if (showError.value) return;
  emit("closeInsuranceModal");
};

const manageData = async invalid => {
  response.value = null;
  try {
    if (!insuranceForm.value.valid) throw Error("Invalid insurance");
    let details = paymentData.value.details;
    details.policy = insuranceForm.value.policy;
    const payload = {
      details: details,
      event_id: purchase.value.event_id,
      policy: insuranceForm.value.policy,
      purchase: purchase.value.id,
      f2f: false,
      promoter_terms: true
    };
    await payInsurance(payload);
    await _managePayment();
  } catch (error) {
    response.value = {
      modal: {
        title: "wegow.error.invoiceError",
        description: "wegow.error.tryAgainLater",
        btn_message: "wegow.error.accept"
      }
    };
  } finally {
    closeBigLoader();
    openErrorModal(response.value);
  }
};

const _managePayment = async () => {
  if (paymentData.value.accepted && paymentData.value.error_code === null) {
    response.value = {
      modal: {
        imageType: "accept",
        title: "wegow.payment.insurance.success",
        btn_message: "wegow.error.accept"
      }
    };
    emit("closeInsuranceModal");
  } else if (
    paymentData.value.accepted &&
    paymentData.value.error_code === "0"
  ) {
    response.value = {
      modal: {
        imageType: "accept",
        title: "wegow.payment.insurance.success",
        btn_message: "wegow.error.accept"
      }
    };
    emit("closeInsuranceModal");
  } else {
    if (paymentData.value.retry) {
      // TODO:: revisar
      if (paymentData.value.retry_type === "stripe_fallback") {
        paymentData.value.type = paymentData.value.retry_type;
        paymentData.value.save = false;
      }
    } else {
      if (
        paymentData.value.status === "needs_info" &&
        !!paymentData.value.procedure_data &&
        paymentData.value.procedure
      ) {
        if (paymentData.value.procedure === "challenge_request") {
          if (paymentData.value.procedure_data.protocolVersion === "1.0.2") {
            const data = {
              PaReq: paymentData.value.procedure_data.PAReq,
              MD: paymentData.value.procedure_data.MD,
              TermUrl: buildTermUrl(paymentData.value.procedure_data.termURL)
            };

            _formPost(paymentData.value.procedure_data.acsURL, data, "POST");
            loading.value = false;
          } else {
            const data = {
              creq: paymentData.value.procedure_data.creq
            };
            _formPost(paymentData.value.procedure_data.acsURL, data, "POST");
          }
        }
      } else {
        showPaymentError();
      }
    }
  }
};

const _formPost = (path, params, method = "post") => {
  // The rest of this code assumes you are not using a library.
  // It can be made less wordy if you use one.
  const form = document.createElement("form");
  form.method = method;
  form.action = path;
  for (const key in params) {
    if (params[key]) {
      const hiddenField = document.createElement("input");
      hiddenField.type = "hidden";
      hiddenField.name = key;
      hiddenField.value = params[key];

      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  form.submit();
};

const showPaymentError = () => {
  let errorMessage = "";

  if (
    typeof paymentData.value.detail !== "undefined" &&
    paymentData.value.detail.error_message
  ) {
    errorMessage = paymentData.value.detail.error_message;
  } else {
    if (paymentData.value.error_date === "WEGOW004") {
      errorMessage = t("wegow.purchase.status.paid");
    } else {
      errorMessage =
        type.value === "stripe" || type.value === "stripe_3ds"
          ? t("wegow.payment.errors.errorStripe")
          : t("wegow.payment.errors.error");
    }
  }

  response.value = {
    modal: {
      title: errorMessage,
      btn_message: t("wegow.error.accept")
    }
  };
};
onMounted(() => {
  purchaseData.value = purchase.value;
});

// This function avoids the form to be closed when
// the payment or the insurance form are not well formed.
watch(errorModal, () => {
  setTimeout(() => {
    showError.value = Boolean(errorModal.value);
  }, 50);
});
</script>

<style lang="scss" scoped>
.select-wrapper,
.col-3 {
  width: 30%;
}

.form-row {
  display: flex;
}

.col-8,
.col-9 {
  width: 70%;
}

.form-group {
  input[type="text"] {
    background: $inputDiscountBackground;
  }

  margin-bottom: 4px;
}

.insurance-modal {
  position: relative;
  width: 100%;
  padding: 20px;
  padding-bottom: 150px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 140px) !important;

  .title-principal {
    @apply mb-4;
  }
  @media screen and (min-width: $screen-md) {
    max-height: calc(100vh - 280px) !important;
    padding: 20px;

    .title-principal {
      @apply mb-4;
      font-weight: 500;
      font-size: 16px;
    }

    .title-separator {
      margin-bottom: 15px;
      border-bottom: 1px solid $white;
      font-size: 14px;
      font-weight: 500;
    }

    .container-inputs {
      padding: 0 20px;
    }

    // input {
    //   width: 100%;
    //   min-width: 100%;
    // }
  }

  .title-separator {
    margin-top: 10px;
  }

  .error-container {
    border: 1px solid $red;
  }

  .text-red {
    color: $red;
  }
}

.selectError {
  border: 1px solid #f13838 !important;
}

.general-conditions-link {
  text-decoration: underline;
  color: $topazoscuro;
}

#general-conditions {
  margin-right: 10px;
}

.insurance-btn {
  display: flex;
  justify-content: center;
}

.red {
  margin-left: 18px;
}

.custom-select {
  width: 100%;
  height: 2.7em;
  box-shadow: none;
  background-color: $inputLigthBackground;
  font-size: 1rem;
  padding: 8px 10px;
  display: flex;
  align-items: center;
  border: unset;
  color: $white;
  cursor: pointer;

  @media screen and (min-width: $screen-md) {
    height: 40px;
  }

  span {
    width: 100%;
  }

  .select-default {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.select-options {
  appearance: none;
  padding-bottom: 5px;
}

.dropdown {
  overflow-y: auto;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1;
  background-color: $textInfoCard;
  max-height: 250px;
  border-radius: 2px;
  padding: 0 !important;

  .options {
    display: flex;
    align-items: center;
    border-top: 1px solid #898989;
    width: 100%;
    cursor: pointer;
  }

  .docType {
    padding: 6px 10px;

    &:not(:disabled):hover {
      background-color: $white-25-color;
    }
  }
}

.checkbox-conditions {
  margin-top: 10px;
}

.ingood-info {
  @apply text-2xs leading-3 text-color-gray my-4;
}
</style>
