import { computed, onMounted, onUnmounted, reactive, ref } from "vue";

export function useModalHandler() {
  // EVENTS
  const OPEN_MODAL = "open-modal";
  const CLOSE_MODAL = "close-modal";

  const OPEN_ERROR = "open-error";
  const CLOSE_ERROR = "close-error";

  const OPEN_PREVIEW_MODAL = "open-preview-modal";
  const CLOSE_PREVIEW_MODAL = "close-preview-modal";

  const OPEN_LOADER = "open-loader";
  const CLOSE_LOADER = "close-loader";

  const OPEN_TOOLTIP_MODAL = "open-tooltip";
  const CLOSE_TOOLTIP_MODAL = "close-tooltip";

  // MODALS
  const SIGN_IN = "sign-in";
  const SIGN_UP = "sign-up";
  const SIGN_IN_PURCHASE = "sign-in-purchase";
  const SIGN_UP_PURCHASE = "sign-up-purchase";
  const FORGET_PASSWORD = "forget-password";
  const CHANGE_PASSWORD = "change-password";
  const UNSUBSCRIBE_EMAILS = "unsubscribe-emails";
  const BUYER_MODAL = "buyer-modal";
  const CALENDAR = "calendar";
  const BASIC_INFO_MODAL = "basic-info";
  const PROFILE_INFO = "profile-info";
  const SIGN_UP_PROMOTER = "sign-up-promoter";
  const PERFECT_COMPANION = "perfect-companion";
  const MAP = "map-modal";
  const MOMENT = "moment-modal";
  const MOMENT_LIKES = "moment-followers-modal";
  const IMAGE_PREVIEW = "image-preview";
  const INSURANCE_MODAL = "insurance-modal";
  const CREATE_CHAT_MODAL = "create-chat";
  const MODAL_CHAT_USERS = "chat-users";
  const MODAL_SYNC_ARTISTS = "sync-artists";
  const MODAL_SUCCESS = "modal-success";

  const activeModal = ref(undefined);
  const errorModal = ref(undefined);
  const previewModal = ref(undefined);
  const tooltipModal = ref(undefined);

  const bigLoader = ref(false);

  const modalsProps = reactive({});
  const modalProps = computed(() => modalsProps[activeModal.value] ?? {});
  const previewModalProps = computed(
    () => modalsProps[previewModal.value] ?? {}
  );

  // ACTIONS
  function openModalAction(modal, props = {}, active = activeModal) {
    active.value = modal;
    modalsProps[modal] = reactive(props);
  }

  function closeModalAction(active = activeModal) {
    delete modalsProps[active.value];
    active.value = undefined;
  }

  function openErrorModalAction(modal) {
    errorModal.value = modal;
  }
  function openTooltipAction(modal) {
    tooltipModal.value = modal;
  }
  function closeTooltipAction() {
    tooltipModal.value = undefined;
  }

  function closeErrorModalAction() {
    errorModal.value = undefined;
  }

  function openBigLoaderAction() {
    bigLoader.value = true;
  }

  function closeBigLoaderAction() {
    bigLoader.value = false;
  }

  // HANDLERS
  function handleOpenModal({ detail: { modal, props } }) {
    openModalAction(modal, props);
  }

  function handleCloseModal() {
    closeModalAction();
  }

  function handleOpenPreviewModal({ detail: { modal, props } }) {
    openModalAction(modal, props, previewModal);
  }

  function handleClosePreviewModal() {
    closeModalAction(previewModal);
  }

  function handleOpenErrorModal({ detail: { modal } }) {
    openErrorModalAction(modal);
  }
  function handleOpenTooltipModal({ detail: { modal } }) {
    openTooltipAction(modal);
  }
  function handleCloseTooltipModal() {
    closeTooltipAction();
  }

  function handleCloseErrorModal() {
    closeErrorModalAction();
  }

  function handleOpenBigLoader() {
    openBigLoaderAction();
  }

  function handleCloseBigLoader() {
    closeBigLoaderAction();
  }

  // DISPATCHERS
  function modalDispatcher(event, modal = undefined, props = undefined) {
    document.dispatchEvent(
      new CustomEvent(event, { detail: { modal: modal, props: props } })
    );
  }

  function openModal(modal, props = {}) {
    modalDispatcher(OPEN_MODAL, modal, props);
  }

  function closeModal() {
    modalDispatcher(CLOSE_MODAL);
  }

  function openPreviewModal(modal, props = {}) {
    modalDispatcher(OPEN_PREVIEW_MODAL, modal, props);
  }

  function closePreviewModal() {
    modalDispatcher(CLOSE_PREVIEW_MODAL);
  }

  function openErrorModal(modal) {
    modalDispatcher(OPEN_ERROR, modal);
  }

  function closeErrorModal() {
    modalDispatcher(CLOSE_ERROR);
  }

  function openBigLoader() {
    modalDispatcher(OPEN_LOADER);
  }

  function closeBigLoader() {
    modalDispatcher(CLOSE_LOADER);
  }
  function openTooltip(modal) {
    modalDispatcher(OPEN_TOOLTIP_MODAL, modal);
  }

  function closeTooltip() {
    modalDispatcher(CLOSE_TOOLTIP_MODAL);
  }

  // HANDLERS
  const EVENT_HANDLERS = {
    [OPEN_MODAL]: handleOpenModal,
    [CLOSE_MODAL]: handleCloseModal,
    [OPEN_ERROR]: handleOpenErrorModal,
    [CLOSE_ERROR]: handleCloseErrorModal,
    [OPEN_LOADER]: handleOpenBigLoader,
    [CLOSE_LOADER]: handleCloseBigLoader,
    [OPEN_PREVIEW_MODAL]: handleOpenPreviewModal,
    [CLOSE_PREVIEW_MODAL]: handleClosePreviewModal,
    [OPEN_TOOLTIP_MODAL]: handleOpenTooltipModal,
    [CLOSE_TOOLTIP_MODAL]: handleCloseTooltipModal
  };

  onMounted(() => {
    Object.entries(EVENT_HANDLERS).forEach(([event, handler]) =>
      document.addEventListener(event, handler)
    );
  });

  onUnmounted(() => {
    Object.entries(EVENT_HANDLERS).forEach(([event, handler]) =>
      document.removeEventListener(event, handler)
    );
  });

  return {
    // Modals
    SIGN_IN,
    SIGN_UP,
    SIGN_IN_PURCHASE,
    SIGN_UP_PURCHASE,
    FORGET_PASSWORD,
    CHANGE_PASSWORD,
    UNSUBSCRIBE_EMAILS,
    BUYER_MODAL,
    CALENDAR,
    PROFILE_INFO,
    BASIC_INFO_MODAL,
    SIGN_UP_PROMOTER,
    PERFECT_COMPANION,
    MAP,
    MOMENT,
    MOMENT_LIKES,
    IMAGE_PREVIEW,
    INSURANCE_MODAL,
    CREATE_CHAT_MODAL,
    MODAL_CHAT_USERS,
    MODAL_SUCCESS,
    MODAL_SYNC_ARTISTS,
    // Variables
    activeModal,
    errorModal,
    modalProps,
    previewModal,
    previewModalProps,
    bigLoader,
    tooltipModal,

    // Actions
    openModal,
    closeModal,
    openErrorModal,
    closeErrorModal,
    openBigLoader,
    closeBigLoader,
    openPreviewModal,
    closePreviewModal,
    openTooltip,
    closeTooltip
  };
}
