import { defineStore } from "pinia";

export const usePixelStore = defineStore("pixel", {
  state: () => {
    return {
      fbPixel: [],
      gaPixel: []
    };
  },
  getters: {
    fbPixel: state => state.fbPixel,
    gaPixel: state => state.gaPixel
  },
  actions: {
    isPixelIncluded(pixel) {
      this.$state.gaPixel.includes(pixel);
    },
    isFbPixelIncluded(pixel) {
      this.$state.fbPixel.includes(pixel);
    },
    setFbPixel(pixel) {
      this.$state.fbPixel.push(pixel);
    },
    setGaPixel(pixel) {
      this.$state.gaPixel.push(pixel);
    },
    resetPixel(data) {
      if (data.fb) {
        this.$state.fbPixel = [];
      }
      if (data.ga) {
        this.$state.gaPixel = [];
      }
    }
  }
});
