<template>
  <main class="error-page-container">
    <div class="message-container px-4">
      <img
        :src="require('@/assets/images/ic-clock-white.svg')"
        class="icon"
        alt="reloj"
      />
      <p class="text-center title">
        {{ $t("wegow.error.errorServerTitle") }}
      </p>
      <img
        class="image"
        :src="require('@/assets/images/server-error-image.png')"
        :alt="$t('wegow.error.altErrorIcon')"
      />
      <p
        class="text-center subtitle"
        v-html="$t('wegow.error.serverError')"
      ></p>
    </div>
  </main>
</template>

<script setup></script>
<style lang="scss" scoped>
.error-page-container {
  width: 100%;
  height: 100vh;
  background-image: url("@/assets/images/background-server-error.png");
  background-repeat: round;
  padding-top: 93px;
  .message-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-right: auto;
    margin-left: auto;
    .icon {
      height: 83px;
      width: 83px;
    }
    .image {
      width: 737px;
      margin-top: 67px;
      margin-bottom: 56px;
    }
    p.title {
      font-family: Museo Sans;
      font-size: 40px;
      font-weight: 400;
      line-height: 48px;
      margin-top: 13px;
    }
    p.subtitle {
      font-family: Museo Sans;
      font-size: 32px;
      font-weight: 400;
      line-height: 38px;
    }
  }
}
</style>
