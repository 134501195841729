<template>
  <modal-template
    v-if="activeModal === MODAL_CHAT_USERS"
    size="complete"
    class="modal-container"
    @close="closeModal"
  >
    <div class="title mt-8">
      <h1 class="text-l text-center">Ver usuarios</h1>
    </div>
    <div class="users px-16 py-14">
      <carousel
        v-if="list.chatMembers?.length"
        class="carousel fixed-carousel-arrows"
        :wrap-around="false"
        :transition="500"
        :breakpoints="breakpoints.eventCards"
      >
        <slide
          v-for="profile in list.chatMembers"
          :key="profile.id"
          :aria-hidden="false"
        >
          <profile-card :profile="profile" />
        </slide>
        <template #addons>
          <navigation />
        </template>
      </carousel>
    </div>
  </modal-template>
</template>

<script setup>
import ModalTemplate from "@/components/general/modals/ModalTemplate.vue";
import ProfileCard from "@/components/wegow/ui/cards/ProfileCard.vue";

import { useModalHandler } from "@/plugins/useModalHandler";
import { toRefs, watch } from "vue";
import { useUser } from "@/composables/useUser";
import { Carousel, Navigation, Slide } from "vue3-carousel";
import { BREAKPOINTS as breakpoints } from "@/constants";

const { activeModal, MODAL_CHAT_USERS, closeModal } = useModalHandler();
const { getChatMembers, list } = useUser();

const props = defineProps({
  chatId: {
    type: String,
    default: undefined
  }
});
const { chatId } = toRefs(props);

watch(chatId, async (newValue, oldValue) => {
  if (newValue === oldValue) return;
  await getChatMembers({ chats: newValue });
});
</script>
<style scoped lang="scss"></style>
