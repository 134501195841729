import { storeToRefs } from "pinia";

import { useMomentStore } from "@/stores/modules/moment";
import { useUserStore } from "@/stores/modules/user";

import { useModalHandler } from "@/plugins/useModalHandler";

import { useRouter } from "vue-router";
import { ref } from "vue";

export const useMoment = () => {
  const momentStore = useMomentStore();
  const userStore = useUserStore();
  const {
    moments,
    wegowMoment,
    searchOptions,
    selectedMoment,
    sendingMoment,
    currentPage,
    nextPage,
    artistsTags,
    momentCount,
    venuesTags
  } = storeToRefs(momentStore);
  const { userDetail } = storeToRefs(userStore);
  const { openErrorModal } = useModalHandler();

  const ARTIST_TYPE = 0,
    VENUE_TYPE = 2,
    USER_TYPE = 3;
  const searchInput = ref("");
  const searchArtist = ref(false);
  const searchVenue = ref(false);
  const replaces = ref([]);
  const message = ref("");
  const image = ref(null);
  const previewImage = ref(null);

  const getMoments = async (payload = {}) => {
    await momentStore.getMoments(payload);
  };

  const getUserMoments = async data => {
    let params = {
      page_size: data.page_size ? data.page_size : 20,
      page: 1,
      followers: data.id
    };
    await momentStore.getMoments(params);
  };

  const getMoment = async id => {
    await momentStore.getMoment(id);
  };
  const getArtistsTrendingMoment = async params => {
    await momentStore.getArtistsMoment(params);
  };
  const getVenuesTrendingMoment = async params => {
    await momentStore.getVenuesMoment(params);
  };

  const removeMoment = async id => {
    await momentStore.removeMoment(id);
  };

  const postMoment = async data => {
    const fd = new FormData();
    fd.append("image", data.img);
    fd.append("text", data.text);
    // let payload = {
    //   user: userDetail.value.id,
    //   text: message.value,
    //   // image: image.value
    // };
    await momentStore.postMoment(fd);
    userDetail.value.moments_count++;
    // let payloadImg = {
    //   data: fd,
    //   id: moment.value.id
    // };
    // console.log('payloaddd', payloadImg);
    // await momentStore.postMomentImage(payloadImg);
  };

  const postComment = async comment => {
    try {
      sendingMoment.value = true;
      let payload = {
        moment: comment.moment.id,
        comment_text: comment.message,
        user: userDetail.value.id
      };
      await momentStore.postComment(payload);
      comment.moment.comments_count++;
    } catch (e) {
      openErrorModal({
        modal: {
          title: "wegow.moment.commentError",
          btn_message: "general.close"
        }
      });
    }
    sendingMoment.value = false;
  };

  const postLike = async editMoment => {
    try {
      let payload = {
        moment: editMoment.id,
        like: !editMoment.liked,
        user: userDetail.value.id
      };
      await momentStore.postLike(payload);
      editMoment.liked = payload.like;
      if (payload.like) {
        editMoment.likes_count++;
      } else {
        editMoment.likes_count--;
      }
      // if (moment.value) {
      //   moment.value.liked = payload.like;
      // }
    } catch (e) {
      openErrorModal({
        modal: {
          title: "wegow.moment.likeError",
          btn_message: "general.close"
        }
      });
    }
  };

  const getDropdownOptions = async params => {
    try {
      await momentStore.getSuggestions(params);
    } catch (e) {
      console.log("Error al obtener las recomendaciones:", searchOptions.value);
    }
  };

  const getStringTitle = title => {
    let artistRegex = /@<<artist>>(.*?)<<(.*?)>>/g;
    let userRegex = /@<<user>>(.*?)<<(.*?)>>/g;
    let venueRegex = /#<<venue>>(.*?)<<(.*?)>>/g;
    title = title.replace(artistRegex, function(match, name, slug) {
      return "@" + name;
    });

    title = title.replace(userRegex, function(match, name, slug) {
      return "@" + name;
    });

    title = title.replace(venueRegex, function(match, name, slug) {
      return "#" + name;
    });
    return title;
  };

  const getParsedTitle = (title, name = "") => {
    const router = useRouter();
    let artistRegex = /@<<artist>>(.*?)<<(.*?)>>/g;
    let userRegex = /@<<user>>(.*?)<<(.*?)>>/g;
    let venueRegex = /#<<venue>>(.*?)<<(.*?)>>/g;
    let hashtagRegex = /#<<hashtag>>(.*?)<<hashtag>>/g;

    title = title?.replace(artistRegex, function(match, nombre, slug) {
      let url = router.resolve({
        name: "ArtistProfile",
        params: { slug: slug }
      }).href;
      return '<a href="' + url + '" class="user-color">@' + nombre + "</a>";
    });

    title = title?.replace(userRegex, function(match, nombre, slug) {
      let url = router.resolve({
        name: "ProfilePage",
        params: { slug: slug }
      }).href;
      return '<a href="' + url + '" class="user-color">@' + nombre + "</a>";
    });

    title = title?.replace(venueRegex, function(match, nombre, slug) {
      let url = router.resolve({
        name: "VenueProfile",
        params: { slug: slug }
      }).href;
      return '<a href="' + url + '" class="venue-color">#' + nombre + "</a>";
    });
    // TODO: complete hastag
    title = title?.replace(
      hashtagRegex,
      // '<a href="https://wegow.com/$1" class="venue-color">#$1</a>'
      '<a href="/" class="venue-color">#$1</a>'
    );
    if (name) {
      title = "<b>" + name + "</b>    " + title;
    }

    return title;
  };

  const checkForSearch = async momentText => {
    let artistIndex = momentText.lastIndexOf("@");
    let venueIndex = momentText.lastIndexOf("#");
    let spaceIndex = momentText.lastIndexOf(" ");
    if (spaceIndex >= artistIndex && spaceIndex >= venueIndex) {
      if (searchVenue.value && searchInput.value !== "") {
        selectOption(null, true);
      } else {
        searchInput.value = "";
        searchArtist.value = false;
        searchVenue.value = false;
      }
    } else if (spaceIndex < artistIndex) {
      let search = momentText.split("@");
      searchInput.value = search[search.length - 1];
      await getDropdownOptions({ types: "0,3", query: searchInput.value });
      searchArtist.value = true;
      searchVenue.value = false;
    } else if (spaceIndex < venueIndex) {
      let search = momentText.split("#");
      searchInput.value = search[search.length - 1];
      await getDropdownOptions({ types: "2", query: searchInput.value });
      searchArtist.value = false;
      searchVenue.value = true;
    }
  };

  const selectOption = (option, hashtag = false) => {
    let origin = (searchArtist.value ? "@" : "#") + searchInput.value;
    let newText =
      (searchArtist.value ? "@" : "#") +
      (option?.slug ? option.slug : searchInput.value);
    message.value = message.value.replace(origin, newText);
    replaces.value.push({
      text: newText,
      option: option || { name: searchInput.value, slug: "hashtag" },
      type: searchArtist.value
        ? option.doc_type === ARTIST_TYPE
          ? "artist"
          : "user"
        : hashtag
        ? "hashtag"
        : "venue"
    });
    searchArtist.value = false;
    searchVenue.value = false;
    searchInput.value = "";
  };

  const parseMomentToPost = async () => {
    sendingMoment.value = true;
    let newMessage = message.value;
    for (const replaceOption of replaces.value) {
      let parsedOption =
        (replaceOption.type === "artist" || replaceOption.type === "user"
          ? "@"
          : "#") + "<<type>>name<<slug>>";
      parsedOption = parsedOption.replace("type", replaceOption.type);
      parsedOption = parsedOption.replace("name", replaceOption.option.name);
      parsedOption = parsedOption.replace("slug", replaceOption.option.slug);
      newMessage = newMessage.replace(replaceOption.text, parsedOption);
    }
    await postMoment({ img: image.value, text: newMessage });
    previewImage.value = null;
    image.value = null;
    message.value = "";
    sendingMoment.value = false;
  };

  const setMomentImage = newImage => {
    previewImage.value = URL.createObjectURL(newImage);
    image.value = newImage;
  };

  const deleteImage = () => {
    previewImage.value = null;
    image.value = null;
  };

  const reportMoment = async moment => {
    try {
      let payload = { moment: moment.id, like: userDetail.value.id };
      await momentStore.reportMoment(payload);
      openErrorModal({
        modal: {
          imageType: "accept",
          title: "wegow.moment.reportSuccess",
          btn_message: "general.accept"
        }
      });
    } catch (e) {
      openErrorModal({
        modal: {
          title: "wegow.moment.reportError",
          btn_message: "general.close"
        }
      });
    }
  };

  const changePage = async page => {
    window.scrollTo(0, 0);
    currentPage.value = page.page;
    await getMoments({
      page: currentPage.value,
      page_size: page.page_size ? page.page_size : 20
    });
  };

  // TODO: mejorar
  const selectMoment = moment => {
    momentStore.selectMoment(moment);
  };

  return {
    selectedMoment,
    selectMoment,
    //! Properties
    moments,
    wegowMoment,
    searchOptions,
    searchInput,
    searchArtist,
    searchVenue,
    replaces,
    message,
    momentCount,
    image,
    previewImage,
    sendingMoment,
    currentPage,
    nextPage,
    artistsTags,
    venuesTags,
    //! Metodos
    getMoments,
    getUserMoments,
    getMoment,
    postMoment,
    postComment,
    postLike,
    reportMoment,
    getDropdownOptions,
    setMomentImage,
    deleteImage,
    removeMoment,
    getParsedTitle,
    parseMomentToPost,
    checkForSearch,
    selectOption,
    changePage,
    getArtistsTrendingMoment,
    getVenuesTrendingMoment,
    getStringTitle
  };
};
