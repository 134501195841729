import { VALID_REGIONS } from "@/constants.js";
import { checkRoutes } from "./middleware/checkRoutes";
const FiltersPage = () => import("@/views/wegow/filters/FiltersPage.vue");

const FilterRoutes = [
  {
    path: "/:region/:type/geo/",
    name: "FiltersPage",
    redirect: to => {
      const { region, type } = to.params;
      return `${region}/${type}`;
    },
    meta: {
      middleware: [checkRoutes]
    },
    children: [
      {
        path: ":country",
        name: "FiltersPageCountry",
        component: FiltersPage,
        meta: {
          middleware: [checkRoutes]
        },
        children: [
          {
            path: ":administrative_division",
            name: "FiltersPageCountryAdministrativeDivision",
            component: FiltersPage,
            children: [
              {
                path: "",
                name: "FiltersPageCountryAdministrativeDivision",
                component: FiltersPage
              },
              {
                path: ":city",
                name: "FiltersPageCountryAdministrativeDivisionCity",
                component: FiltersPage,
                children: [
                  {
                    path: "",
                    name: "FiltersPageCountryAdministrativeDivisionCity",
                    component: FiltersPage
                  },
                  {
                    path: "cal/:date",
                    name: "FiltersPageCountryAdministrativeDivisionCityDate",
                    component: FiltersPage,
                    children: [
                      {
                        path: "",
                        name:
                          "FiltersPageCountryAdministrativeDivisionCityDate",
                        component: FiltersPage
                      },
                      {
                        path: "gen/:genre",
                        name:
                          "FiltersPageCountryAdministrativeDivisionCityDateGenre",
                        component: FiltersPage
                      }
                    ]
                  },
                  {
                    path: "gen/:genre",
                    name: "FiltersPageCountryAdministrativeDivisionCityGenre",
                    component: FiltersPage
                  }
                ]
              },
              {
                path: "cal/:date",
                name: "FiltersPageCountryAdministrativeDivisionDate",
                component: FiltersPage,
                children: [
                  {
                    path: "",
                    name: "FiltersPageCountryAdministrativeDivisionDate",
                    component: FiltersPage
                  },
                  {
                    path: "gen/:genre",
                    name: "FiltersPageCountryAdministrativeDivisionDateGenre",
                    component: FiltersPage
                  }
                ]
              },
              {
                path: "gen/:genre",
                name: "FiltersPageCountryGenre",
                component: FiltersPage
              }
            ]
          },
          {
            path: "cal/:date",
            name: "FiltersPageCountryDate",
            component: FiltersPage,
            children: [
              {
                path: "",
                name: "FiltersPageCountryDate",
                component: FiltersPage
              },
              {
                path: "gen/:genre",
                name: "FiltersPageCountryDateGenre",
                component: FiltersPage
              }
            ]
          },
          {
            path: "gen/:genre",
            name: "FiltersPageCountryGenre",
            component: FiltersPage
          }
        ]
      }
    ]
  }
];

export default FilterRoutes;

// Routes documentation
// :type/geo/ (ruta raíz): Página de filtros principal (No disponible ya que no tiene pais)
// :type/geo/:country: Página de filtros por país
// :type/geo/:country/cal/:date: Página de filtros por país y fecha
// :type/geo/:country/cal/:date/gen/:genre: Página de filtros por país, fecha y género
// :type/geo/:country/gen/:genre: Página de filtros por país y género
// :type/geo/:country/:administrative_division: Página de filtros por país y división administrativa
// :type/geo/:country/:administrative_division/cal/:date: Página de filtros por país, división administrativa y fecha
// :type/geo/:country/:administrative_division/cal/:date/gen/:genre: Página de filtros por país, división administrativa, fecha y género
// :type/geo/:country/:administrative_division/:city: Página de filtros por país, división administrativa y ciudad
// :type/geo/:country/:administrative_division/:city/cal/:date: Página de filtros por país, división administrativa, ciudad y fecha
// :type/geo/:country/:administrative_division/:city/cal/:date/gen/:genre: Página de filtros por país, división administrativa, ciudad, fecha y género
// :type/geo/:country/:administrative_division/:city/gen/:genre: Página de filtros por país, división administrativa, ciudad y género
