<template>
  <div v-if="type !== 'short'" class="profile-card flex" :class="type">
    <div class="card-image">
      <w-navigation
        :object="profile"
        :params="{
          name: 'ProfilePage',
          params: { slug: profile.slug }
        }"
      >
        <template #link>
          <img
            :src="getImageUrl(profile)"
            :alt="
              $t('magazine.widgetAlt.profileImageAlt', {
                user_name: profile.first_name
                  ? profile.first_name + ' ' + profile.last_name
                  : ' por defecto'
              })
            "
            class="main-image"
            loading="lazy"
            @error="
              $event.target.src = require('@/assets/images/placeholder-user-square.svg')
            "
          />
        </template>
      </w-navigation>
    </div>
    <div
      class="card-info w-1/2 flex flex-col justify-between pl-3 pt-3 pb-[0.563rem] flex-1"
    >
      <div class="info">
        <w-navigation
          :object="profile"
          :params="{
            name: 'ProfilePage',
            params: {
              slug: profile.slug
            }
          }"
        >
          <template #link>
            <h3 class="name text-start font-museo-700">
              {{ profile.first_name }} {{ profile.last_name }}
            </h3>
          </template>
        </w-navigation>
        <p class="profile-location text-start mt-2 text-xs font-museo-300">
          {{ profile.location }}
        </p>
      </div>
      <div class="actions font-museo-700 text-color-topaz-light mr-4">
        <div v-if="profile.friendship && profile.friendship.status == 'none'">
          <p
            class="cursor-pointer flex items-center justify-end gap-2 font-museo-700 "
            :class="
              type === 'profile-sm-card' ||
              type === 'profile-md-card' ||
              type === 'profile-lg-card'
                ? 'text-[11px]'
                : 'md:text-s text-[11px]'
            "
            @click="sendFriendshipRequest(profile)"
          >
            {{ $t("general.follow") }}
            <w-icon
              :img="require('@/assets/images/ic-follow.svg')"
              :size="windowWidth > 768 ? 20 : 15"
              :alt="$t('wegow.profile.altIcon')"
            />
          </p>
        </div>
        <div
          v-else-if="
            profile.friendship &&
              profile.friendship.status == 'request_received'
          "
        >
          <p
            class="cursor-pointer flex items-center justify-end gap-2 font-museo-700"
            :class="
              type === 'profile-sm-card' || type === 'profile-lg-card'
                ? 'text-[11px]'
                : 'md:text-s text-[11px]'
            "
            @click="acceptFriendshipRequest(profile)"
          >
            {{ $t("wegow.profile.acceptFriendship") }}
            <w-icon
              :img="require('@/assets/images/ic-follow.svg')"
              :size="windowWidth > 768 ? 20 : 15"
              :alt="$t('wegow.acceptFriendship')"
            />
          </p>
        </div>
        <div
          v-else-if="
            profile.friendship && profile.friendship.status == 'request_sent'
          "
        >
          <p
            class="text-right gap-2 font-museo-700"
            :class="
              type === 'profile-sm-card' ||
              type === 'profile-md-card' ||
              type === 'profile-lg-card'
                ? 'text-[11px]'
                : 'md:text-s text-[11px]'
            "
          >
            {{ $t("wegow.profile.sendedFriendship") }}
          </p>
        </div>
        <div
          v-else-if="profile.friendship && profile.friendship.status == 'self'"
        >
          <p
            class="text-right gap-2 font-museo-700 white"
            :class="
              type === 'profile-sm-card' ||
              type === 'profile-md-card' ||
              type === 'profile-lg-card'
                ? 'text-[11px]'
                : 'md:text-s text-[11px]'
            "
          >
            {{ $t("wegow.profile.self") }}
          </p>
        </div>
        <div v-else>
          <div class="cursor-pointer flex justify-end items-center gap-2">
            <w-navigation
              :object="profile"
              :params="{
                name: 'ProfilePage',
                params: {
                  slug: profile.slug
                }
              }"
            >
              <template #link>
                <img
                  :width="windowWidth > 768 ? '20' : '16'"
                  :height="windowWidth > 768 ? '20' : '16'"
                  style="margin-left: auto;"
                  :src="require('@/assets/images/ic-ticket-topaz.svg')"
                  :alt="$t('wegow.altIcon')"
                  class="icon-button"
                  :full="false"
                  loading="lazy"
                />
              </template>
            </w-navigation>
            <w-icon
              :img="require('@/assets/images/ic-comment-topaz.svg')"
              :size="windowWidth > 768 ? 20 : 15"
              :alt="$t('wegow.altIcon')"
              class="inline-block md:hidden message-button cursor-pointer"
              @click="sendMessage"
            />
            <w-icon
              :img="require('@/assets/images/ic-comment-topaz.svg')"
              :size="26"
              :alt="$t('wegow.altIcon')"
              class="md:inline-block hidden message-button cursor-pointer"
              @click="sendMessage"
            />

            <!-- TODO: que hace al pulsar el boton de mensajes, abrir chat?? -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="short-card">
    <div class="card-image">
      <w-navigation
        :object="profile"
        :params="{
          name: 'ProfilePage',
          params: {
            slug: profile.slug
          }
        }"
      >
        <template #link>
          <div class="mask">
            <img
              :src="getImageUrl(profile)"
              :data-alt="
                profile.first_name ? profile.first_name : 'Imagen de usuario'
              "
              class="main-image h-12 w-12"
              loading="lazy"
              @error="
                $event.target.src = require('@/assets/images/placeholder-user-square.svg')
              "
            />
          </div>
        </template>
      </w-navigation>
    </div>
    <div class="card-info">
      <p>{{ profile.first_name }} {{ profile.last_name }}</p>
      <div class="font-museo-700 text-color-topaz-light mr-4">
        <div v-if="profile.friendship && profile.friendship.status == 'none'">
          <p
            class="cursor-pointer flex items-center justify-end gap-2 font-museo-700 text-s"
            @click="sendFriendshipRequest(profile)"
          >
            {{ $t("general.follow") }}
            <w-icon
              :img="require('@/assets/images/ic-follow.svg')"
              :size="windowWidth > 768 ? 20 : 15"
              :alt="$t('wegow.profile.altIcon')"
            />
          </p>
        </div>
        <div
          v-else-if="
            profile.friendship &&
              profile.friendship.status == 'request_received'
          "
        >
          <p
            class="cursor-pointer flex items-center justify-end gap-2 font-museo-700 text-s"
            @click="acceptFriendshipRequest(profile)"
          >
            {{ $t("wegow.profile.acceptFriendship") }}
            <w-icon
              :img="require('@/assets/images/ic-follow.svg')"
              :size="windowWidth > 768 ? 20 : 15"
              :alt="$t('wegow.acceptFriendship')"
            />
          </p>
        </div>
        <div
          v-else-if="
            profile.friendship && profile.friendship.status == 'request_sent'
          "
        >
          <p class="text-right gap-2 font-museo-700 text-[11px]">
            {{ $t("wegow.profile.sendedFriendship") }}
          </p>
        </div>
        <div
          v-else-if="profile.friendship && profile.friendship.status == 'self'"
        >
          <p class="text-right gap-2 font-museo-700 text-s white">
            {{ $t("wegow.profile.self") }}
          </p>
        </div>
        <div v-else>
          <div class="cursor-pointer flex justify-center gap-2">
            <w-navigation
              :object="profile"
              :full="false"
              :params="{
                name: 'ProfilePage',
                params: {
                  slug: profile.slug
                }
              }"
            >
              <template #link>
                <w-icon
                  :img="require('@/assets/images/ic-ticket-topaz.svg')"
                  :size="windowWidth > 768 ? 23 : 15"
                  :alt="$t('wegow.altIcon')"
                  class="icon-button"
                  loading="lazy"
                />
              </template>
            </w-navigation>
            <w-icon
              v-if="windowWidth < 768"
              :img="require('@/assets/images/ic-comment-topaz.svg')"
              :size="windowWidth > 768 ? 23 : 15"
              :alt="$t('wegow.altIcon')"
              class="message-button cursor-pointer"
              @click="sendMessage"
            />
            <w-icon
              v-else
              :img="require('@/assets/images/ic-comment-topaz.svg')"
              :size="24"
              :alt="$t('wegow.altIcon')"
              class="message-button cursor-pointer"
              @click="sendMessage"
            />

            <!-- TODO: que hace al pulsar el boton de mensajes, abrir chat?? -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import WNavigation from "@/components/general/ui/WNavigation";
import WIcon from "@/components/general/ui/elements/WIcon.vue";

import { useUser } from "@/composables/useUser";
import { useRouter } from "vue-router";

const { sendFriendshipRequest, acceptFriendshipRequest } = useUser();

const router = useRouter();

const props = defineProps({
  profile: {
    type: Object,
    default: () => {
      return {};
    }
  },
  type: {
    type: String,
    default: "profile-default-card"
    // Types: profile-default-card, profile-sm-card, profile-md-card, profile-lg-card
  }
});
defineEmits(["follow"]);
const windowWidth = ref(0);
windowWidth.value = window.innerWidth;

const sendMessage = () => {
  router.push({ name: "ChatPage", query: { user: props.profile.id } });
};
const getImageUrl = element => {
  if (element?.thumbnails?.s_dt) {
    return element.thumbnails.s_dt;
  } else if (element?.image_url) {
    return element.image_url;
  } else {
    return require("@/assets/images/placeholder-user.svg");
  }
};
</script>

<style scoped lang="scss">
.profile-card {
  border-radius: 6px;
  background-color: #1c1d29;
  width: 378px;
  height: 158px;
  overflow: hidden;
  .card-image {
    img {
      height: 158px;
      width: 158px;
      object-fit: cover;
    }
  }
  .card-info {
    .actions p {
      color: $topaz;
      &.white {
        color: $white;
      }
    }
  }
  &.profile-sm-card {
    width: 197px;
    height: 78px;
    img {
      height: 78px;
      width: 78px;
    }
    .card-info {
      font-size: 11px;
    }
    .icon-button {
      width: 14px;
      height: 14px;
    }
  }
  &.profile-md-card {
    width: 180px;
    height: 62px;
    img {
      height: 62px;
      width: 62px;
    }
    .card-info {
      font-size: 11px;
    }
    .icon-button {
      width: 14px;
      height: 14px;
    }
    @media screen and (min-width: $screen-md) {
      width: 203px;
      height: 85px;
      img {
        height: 85px;
        width: 85px;
      }
      .card-info {
        font-size: 11px;
      }
      .icon-button {
        width: 14px;
        height: 14px;
      }
    }
  }
  &.profile-lg-card {
    max-width: 374px;
    width: 100%;
    height: 98px;
    @media screen and (min-width: $screen-md) {
      max-width: 283px;
      width: 100%;
      height: 120px;
    }
    .card-image {
      img {
        height: 98px;
        width: 98px;
        @media screen and (min-width: $screen-md) {
          height: 120px;
          width: 120px;
        }
      }
    }
    .card-info {
      h3 {
        font-size: 14px;
      }
      .actions {
        font-size: 12px;
      }
    }
    .icon-button {
      width: 16px;
      height: 16px;
    }
  }
  .icon-button {
    width: 20px;
    height: 20px;
    aspect-ratio: 1;
  }
}
.main-image {
  background-color: $scrollMainColor;
}

.short-card {
  @apply flex flex-row bg-grayish-blue;

  .card-image {
    @apply m-2;
    .mask {
      @apply rounded-full overflow-hidden;
      img {
        object-fit: cover;
      }
    }
  }
  .card-info {
    @apply flex-1 flex flex-row justify-between my-auto;
  }
}
</style>
