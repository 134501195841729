import { defineStore } from "pinia";
import PaymentApi from "@/api/PaymentApi";

export const usePaymentStore = defineStore("payment", {
  state: () => {
    return {
      cardData: {},
      paymentMethods: {},
      paymentData: {},
      insuranceData: {
        phone: "",
        id_card_type: "",
        id_card_letter: "",
        id_card: "",
        postal_code: ""
      },
      thereIsInsurance: false,
      ticketOfficeData: {},
      discountData: {},
      showCardForm: false,
      showOxxo: false,
      stripe: null,
      extra_config: {}
    };
  },

  actions: {
    async createPayments(params) {
      this.paymentData = await PaymentApi.createPayments(params);
    },
    async confirmPayments(params) {
      this.paymentData = await PaymentApi.confirmPayments(params);
    },
    async getPayment(params) {
      this.paymentData = await PaymentApi.getPayment(params);
    },
    async getPaymentMethods(params) {
      this.paymentMethods = await PaymentApi.getPaymentMethods(params);
    },
    async validateDiscount(data) {
      this.discountData = (await PaymentApi.validateDiscount(data)).discount;
    },

    // Insurance
    async createInsurance(params) {
      this.insuranceData = await PaymentApi.addInsurance(params);
    },
    async payInsurance(params) {
      this.paymentData = await PaymentApi.payInsurance(params);
    },
    async cancelInsurance(purchaseReference) {
      await PaymentApi.cancelInsurance(purchaseReference);
    },
    async getInsurance(params) {
      this.insuranceData = await PaymentApi.getInsurance(params);
      // TODO: complete
    }
  }
});
