import { storeToRefs } from "pinia";

import { useCookiesStore } from "@/stores/modules/cookies";

export const useCookie = () => {
  const cookieStore = useCookiesStore();
  const { accepted, thirdParty } = storeToRefs(cookieStore);

  const acceptCookie = async (payload = {}) => {
    const { accepted } = payload;
    cookieStore.acceptCookie(accepted);
  };

  const acceptCookieThirdParty = async (payload = {}) => {
    const { accepted } = payload;
    cookieStore.acceptCookieThirdParty(accepted);
  };

  return {
    //! Properties
    accepted,
    thirdParty,

    //! Metodos
    acceptCookie,
    acceptCookieThirdParty
  };
};
