import { storeToRefs } from "pinia";
import { useChatStore } from "@/stores/modules/chat";
import { VueCookieNext } from "vue-cookie-next";
import moment from "moment";
import { sanitize } from "dompurify";

export const useChat = () => {
  const TYPE_DIALOG = "dialog";
  const TYPE_GROUP = "event_group";
  const TYPE_PUBLIC = "event_public";

  const $cookie = VueCookieNext;
  const chatStore = useChatStore();
  const {
    chats,
    messages,
    chatsDetails,
    bubbleChatOpen,
    lastMessageId,
    lastChatUpdated,
    chatsByEvent,
    chatsByCompany,
    attempts,
    canSendMessage
  } = storeToRefs(chatStore);

  const getChatById = async params => {
    await chatStore.getChatById(params);
  };

  const getPublicChats = async params => {
    await chatStore.getPublicChats(params);
  };

  const getPrivateChats = async params => {
    await chatStore.getPrivateChats(params);
  };

  const createChat = async params => {
    await chatStore.createChat(params);
  };

  const removeChat = async params => {
    await chatStore.removeChat(params);
  };

  const getChatByCompany = async params => {
    await chatStore.getChatsByCompany(params);
  };

  const getChatsByEvent = async params => {
    await chatStore.getChatsByEvent(params);
  };

  const getChatMessages = async params => {
    await chatStore.getChatMessages(params);
  };

  const joinChat = async params => {
    await chatStore.joinChat(params);
  };

  const onopen = event => {
    // TODO: REMOVE LOG
    console.log("Chat open", event);
  };
  const onmessage = message => {
    if (message.type === "message" && !canSendMessage.value) {
      canSendMessage.value = true;
      const sentMessage = JSON.parse(message.data);
      if (bubbleChatOpen.value.id === sentMessage.chat) {
        messages.value.push(sentMessage);
      }
      const chats = [
        ...chatsDetails.value.privates,
        ...chatsDetails.value.publics
      ];
      const found = chats.find(({ id }) => id === sentMessage.chat);
      if (found) found.last_message = sentMessage;
    }
  };
  const onerror = () => {};
  const onclose = async event => {
    if (event.code === 1000) return;
    if (attempts.value < 4) {
      await openWS();
      attempts.value++;
    }
  };

  const closeWS = async () => {
    await chatStore.closeWS();
  };

  const openWS = async () => {
    const auth_token = $cookie.getCookie("wegow.auth_token");
    await chatStore.openWS(onopen, onmessage, onerror, onclose, auth_token);
  };
  const purifyMessage = text => {
    text = decodeURIComponent(
      JSON.parse('"' + text.replace(/\"/g, '\\"') + '"')
    );
    text = sanitize(text).replace(
      /[\u007F-\uFFFF]/g,
      chr => "\\u" + ("0000" + chr.charCodeAt(0).toString(16)).substr(-4)
    );
    return text;
  };

  const sendChatMessage = async ({ chat, user, text }) => {
    const clientref = `${user}#${new Date().getTime()}`;
    const created = moment().format();
    const message_type = "text";
    const type = "chat_message";
    text = purifyMessage(text);
    if (!text) return;
    await chatStore.sendChatMessage({
      chat,
      clientref,
      created,
      message_type,
      text,
      type
    });
  };

  const decodeChatMessage = text => {
    text = decodeString(text);
    return parseURLS(text);
  };

  const decodeString = text => {
    text = decodeURIComponent(
      JSON.parse('"' + text.replace(/"/g, '\\"') + '"')
    );
    text = sanitize(text).replace(/\\u[\dA-F]{4}/gi, match =>
      String.fromCharCode(parseInt(match.replace(/\\u/g, ""), 16))
    );
    return text;
  };

  const parseURLS = text =>
    text.replace(
      /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-.][a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?/g,
      match => {
        let exp = /^(http|https):\/\//g;
        let isMatch = exp.test(match);
        if (isMatch)
          return `<a href=${match} target="_blank" class="text-topaz-light"> ${match} </a>`;
        return `<a href=https://${match} target="_blank" class="text-topaz-light"> ${match} </a>`;
      }
    );

  return {
    chats,
    messages,
    chatsDetails,
    chatsByEvent,
    lastMessageId,
    bubbleChatOpen,
    chatsByCompany,
    lastChatUpdated,
    canSendMessage,
    TYPE_GROUP,
    TYPE_DIALOG,
    TYPE_PUBLIC,
    // methods
    openWS,
    closeWS,
    joinChat,
    createChat,
    removeChat,
    getChatById,
    decodeString,
    decodeChatMessage,
    getPublicChats,
    getPrivateChats,
    getChatByCompany,
    getChatsByEvent,
    sendChatMessage,
    getChatMessages
  };
};
