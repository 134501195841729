<template>
  <modal-template
    v-if="activeModal === BUYER_MODAL"
    size="intermedium-small"
    :is-static="false"
    :personalized-style-container="getPersonalizedBox"
    @close="handleClose"
  >
    <wegow-loader v-if="loading" class="load-more-loader" :middle="true" />
    <div
      v-else
      id="containerBuyer"
      class="container-onboarding pt-12 pb-8 md:mx-16 mx-8"
    >
      <h3 class="mb-8 text-center" :style="getPersonalizedTitle">
        {{
          isWhiteLabel
            ? $t("wegow.login.addDataTitle")
            : $t("wegow.purchase.purchaseData")
        }}
      </h3>
      <div>
        <div>
          <form class="space-y-2" @submit.prevent="signupEmail">
            <wegow-input
              v-if="!isWhiteLabel"
              id="profile-user-firstname"
              v-model:value="model.first_name"
              :placeholder="$t('wegow.profile.settings.firstName')"
              :show-error-always="showErrorAlways"
              :validator="validators.first_name"
              :input-style="roudedPersonalization"
              @valid="valid.first_name = $event"
            />
            <wegow-input
              v-if="!isWhiteLabel"
              id="profile-user-lastname"
              v-model:value="model.last_name"
              :placeholder="$t('wegow.profile.settings.lastName')"
              :show-error-always="showErrorAlways"
              :validator="validators.last_name"
              :input-style="roudedPersonalization"
              @valid="valid.last_name = $event"
            />
            <wegow-input
              id="email-signup"
              v-model:value="model.email"
              name="email"
              type="email"
              :placeholder="$t('wegow.login.email')"
              :error-message="valid.email ? '' : 'wegow.login.emailError'"
              :show-error-always="showErrorAlways"
              :validator="validators.email"
              :input-style="roudedPersonalization"
              @valid="valid.email = $event"
            />
            <wegow-input
              id="email-confirmation-signup"
              v-model:value="model.emailConfirmation"
              name="confirmation"
              :placeholder="$t('wegow.login.confirmEmail')"
              :show-error-always="showErrorAlways"
              :error-message="
                !valid.confirmation && valid.email
                  ? 'wegow.login.confirmationError'
                  : ''
              "
              :validator="validators.confirmation"
              :input-style="roudedPersonalization"
              @valid="valid.confirmation = $event"
            />
            <cities-search
              v-if="event.iframe?.type === 1"
              id="profile-user-location"
              v-model:value="model.location"
              :placeholder="$t('wegow.profile.settings.formLocation')"
              :show-error-always="showErrorAlways"
              :input-style="roudedPersonalization"
            />
            <wegow-input
              v-if="event.iframe?.type === 1"
              id="profile-user-birthdate"
              v-model:value="model.birthday"
              type="date"
              :input-style="roudedPersonalization"
            />

            <div class="pt-3 custom-control custom-checkbox">
              <wegow-checkbox
                id="terms-accepted-checkbox"
                v-model:value="model.terms_accepted"
                :html-title="true"
                :error="showErrorAlways && !model.terms_accepted"
                :title="
                  $t('wegow.login.termsAndConditions', {
                    termsUrl: termsAndConditionsUrl,
                    PrivacityUrl: PrivacyPolicyUrl,
                    personalizedStyle: getPersonalizedUrlStyle
                  })
                "
              />
            </div>
            <div class="custom-control custom-checkbox pb-4">
              <wegow-checkbox
                v-if="!isWhiteLabel"
                id="terms-accepted-checkbox"
                v-model:value="model.newsletter"
                :title="$t('wegow.login.reciveNews')"
              />
            </div>
            <wegow-error-message
              v-if="errorMessage"
              :message="errorMessage"
              :error-img="true"
            />
            <div class="flex justify-center">
              <wegow-button
                button-id="confirm-signup-button"
                background="opacity"
                color="topaz"
                :text="$t('general.next')"
                :disabled="isDisabled"
                type="submit"
                class="btn btn-signup"
                :styles="getPersonalizedButton"
                @click-disabled="checkForm()"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  </modal-template>
</template>

<script setup>
import WegowButton from "@/components/general/forms/WegowButton";
import WegowCheckbox from "@/components/general/forms/WegowCheckbox";
import WegowInput from "@/components/general/forms/WegowInput";
import ModalTemplate from "@/components/general/modals/ModalTemplate.vue";
import WegowLoader from "@/components/general/ui/WegowLoader";
import WegowErrorMessage from "@/components/general/forms/WegowErrorMessage";
import CitiesSearch from "@/components/general/forms/selectors/CitiesSearch.vue";

import { useUser } from "@/composables/useUser";
import { useEvent } from "@/composables/useEvent";
import { usePurchase } from "@/composables/usePurchase";
import { useIframeStyle } from "@/composables/useIframeStyle";
import { COUNTRIES } from "@/constants.js";
import { useModalHandler } from "@/plugins/useModalHandler";
import { computed, onMounted, reactive, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;

const router = useRouter();
const { t } = useI18n();
const { sendBuyer } = useUser();
const { details: event, iframeStyles, getTickets } = useEvent();
const { isWhiteLabel, createPurchase, createNewPurchase } = usePurchase();
const {
  getPersonalizedButton,
  getPersonalizedTitle,
  getPersonalizedAuxColor,
  getPersonalizedBox
} = useIframeStyle();

const showErrorAlways = ref(false);
const loading = ref(false);
const errorMessage = ref("");

const { activeModal, BUYER_MODAL, closeModal } = useModalHandler();

const props = defineProps({
  origin: {
    type: String,
    default: ""
  },
  newPromoter: {
    type: Boolean,
    default: false
  }
});

const modelTemplate = {
  first_name: "",
  last_name: "",
  email: undefined,
  emailConfirmation: undefined,
  birthday: undefined,
  location: {
    id: 0,
    name: "",
    place_id: "",
    longitude: 0,
    latitude: 0
  },
  newsletter: false,
  terms_accepted: false
};

const model = reactive({ ...modelTemplate });

const termsAndConditionsUrl = computed(() => {
  return router.resolve({
    name: "TermsOfUsePage",
    params: { region: "es" }
  }).href;
});

const PrivacyPolicyUrl = computed(() => {
  return router.resolve({
    name: "PrivacyPolicyPage",
    params: { region: "es" }
  }).href;
});

const getPersonalizedUrlStyle = computed(() => {
  return Object.entries(getPersonalizedAuxColor.value)
    .map(([key, value]) => `${key}: ${value}`)
    .join("; ");
});

const valid = reactive({
  first_name: isWhiteLabel.value === "",
  last_name: isWhiteLabel.value === "",
  email: false,
  confirmation: false
  //   phone: false,
  //   birthday: false
});

const validators = ref({
  email: { validate: email => EMAIL_REGEX.test(email || "") },
  confirmation: {
    depends: [model],
    validate: confirmation => confirmation === model.email && valid.email
  },
  //   password: { validate: password => /^.{8,}$/.test(password || "") },
  //   phone: { validate: phone => /^\d{4,}$/.test(phone) },
  first_name: {
    validate: first_name => first_name.length > 0
  },
  last_name: {
    validate: last_name => last_name.length > 0
  }
});

const roudedPersonalization = computed(() => {
  let style = {};
  if (iframeStyles.value.rounded_input) {
    style.borderRadius = iframeStyles.value.rounded_input;
  }
  return style;
});

const validateBirthday = birthday => {
  try {
    let fechaActual = new Date();

    let partesFecha = birthday.split("-");
    let fechaCumple = new Date(
      partesFecha[0],
      partesFecha[1] - 1,
      partesFecha[2]
    );
    let diferenciaTiempo = fechaActual - fechaCumple;

    let edadEnAnios = Math.floor(
      diferenciaTiempo / (1000 * 60 * 60 * 24 * 365.25)
    );

    if (edadEnAnios >= 0) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};

const isDisabled = computed(
  () =>
    Object.values(valid).includes(false) ||
    !model.terms_accepted ||
    (event.value.iframe?.type === 1 &&
      (!model.location ||
        model.location.name === "" ||
        !validateBirthday(model.birthday)))
);

const checkForm = () => {
  showErrorAlways.value = true;
};

const signupEmail = async () => {
  try {
    loading.value = true;
    if (isWhiteLabel.value) {
      model.first_name = t("wegow.profile.settings.firstName");
      model.last_name = t("wegow.profile.settings.lastName");
    }
    await sendBuyer(model);
    await createPurchase(true);
    closeModal();
  } catch (error) {
    if (error?.data?.error === "Can't create negative amount of tickets") {
      errorMessage.value = t("wegow.login.noTickets");
      await getTickets();
      createNewPurchase();
    } else {
      errorMessage.value = t("wegow.login.unknownError");
    }
  }
  loading.value = false;
};
const closeBuyerModal = () => {
  errorMessage.value = "";
};
const handleClose = () => {
  closeModal();
  closeBuyerModal();
};
onMounted(() => {
  //   model.phone.country = COUNTRIES.filter(country => country.iso2 === "es")[0];
  // if (event.value.iframe?.type === 1) {
  //   valid.birthday = false;
  //   model.birthday = "";
  //   model.location = {
  //     id: 0,
  //     name: "",
  //     place_id: "",
  //     longitude: 0,
  //     latitude: 0
  //   };
  // }
});
</script>

<style scoped lang="scss">
#containerBuyer {
  h3 {
    font-weight: 600;
    font-size: 22px;
    line-height: 36px;
    @media screen and (min-width: $screen-md) {
      font-size: 30px;
    }
  }
  .or-title {
    position: relative;
    display: block;
    width: 100%;
    margin: 10px 0;
    text-align: center;
    font-size: 16px;
    z-index: 1;
    overflow: hidden;
    &:before,
    &:after {
      content: "\a0";
      position: absolute;
      top: 51%;
      width: 25%;
      height: 1px;
      background-color: $white;
      overflow: hidden;
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
  }
}
</style>
