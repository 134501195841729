import { storeToRefs } from "pinia";

import { useGeneralStore } from "@/stores/modules/general";

export const useGeneral = () => {
  const generalStore = useGeneralStore();
  const {
    cities,
    genres,
    genre,
    regions,
    footerInfo,
    navbarInfo,
    searchOptions,
    eventValidation
  } = storeToRefs(generalStore);

  const getCities = async search => {
    let payload = {
      query: search
    };
    await generalStore.getLocations(payload);
  };

  const getNavbar = async () => {
    await generalStore.getNavbar();
  };

  const getGenres = async (payload = {}) => {
    await generalStore.getGenres(payload);
  };

  const getRegions = async () => {
    await generalStore.getRegions();
  };

  const getFooter = async () => {
    await generalStore.getFooter();
  };

  const getSuggestions = async (payload = {}) => {
    await generalStore.getSuggestions(payload);
  };

  const getSpotifyImports = async payload => {
    await generalStore.importSpotify(payload);
  };
  const importAllSpotify = async () => {
    await generalStore.importAllSpotify();
  };

  const getEventValidation = async validationCode => {
    await generalStore.getEventValidations(validationCode);
  };

  return {
    //! Properties
    cities,
    genres,
    genre,
    regions,
    footerInfo,
    searchOptions,
    eventValidation,
    navbarInfo,

    //! Computed

    //! Metodos
    getCities,
    getGenres,
    getRegions,
    getFooter,
    getNavbar,
    getSpotifyImports,
    importAllSpotify,
    getSuggestions,
    getEventValidation
  };
};
