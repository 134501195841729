<template>
  <modal-template
    v-if="activeModal === SIGN_UP"
    size="intermedium"
    :is-static="isStatic"
    @close="canCloseModal ? closeModal() : ''"
  >
    <wegow-loader v-if="loading" class="load-more-loader" :middle="true" />
    <div
      v-else
      id="containerSignup"
      class="container-onboarding pt-4 md:pt-12 md:mx-16"
    >
      <h3 class="mb-8">{{ $t("wegow.login.signupTitle") }}</h3>
      <div>
        <div>
          <form class="space-y-1" @submit.prevent="signupEmail">
            <wegow-input
              id="email-signup"
              v-model:value="model.email"
              name="email"
              type="email"
              :show-error-always="showErrorAlways"
              :error-message="valid.email ? '' : 'wegow.login.emailError'"
              :placeholder="$t('wegow.login.email')"
              :validator="validators.email"
              @valid="valid.email = $event"
            />
            <wegow-input
              id="email-confirmation-signup"
              v-model:value="model.emailConfirmation"
              name="confirmation"
              :show-error-always="showErrorAlways"
              :error-message="
                !valid.confirmation && valid.email
                  ? 'wegow.login.confirmationError'
                  : ''
              "
              :placeholder="$t('wegow.login.confirmEmail')"
              :validator="validators.confirmation"
              @valid="valid.confirmation = $event"
            />
            <wegow-input
              id="password-signup"
              v-model:value="model.password"
              name="password"
              type="password"
              :placeholder="$t('wegow.login.password')"
              :show-error-always="showErrorAlways"
              :error-message="valid.password ? '' : 'wegow.login.passwordError'"
              :validator="validators.password"
              @valid-change="valid.password = $event"
            />
            <phone-selector
              :phone-selected="model.phone"
              :show-error-always="showErrorAlways"
              :error-message="errors?.phone"
              :validator="validators.phone"
              :error="showErrorAlways && !(model.phone?.phone?.length > 0)"
              @select-country="model.phone.country = $event"
              @change-phone="model.phone.phone = $event"
              @valid-change="valid.phone = $event"
              @open-search="canCloseModal = !$event"
            />
            <div class="pt-3 custom-control custom-checkbox">
              <wegow-checkbox
                id="terms-accepted-checkbox"
                v-model:value="model.terms_accepted"
                :html-title="true"
                :error="showErrorAlways && !model.terms_accepted"
                :title="
                  $t('wegow.login.termsAndConditions', {
                    termsUrl: termsAndConditionsUrl,
                    PrivacityUrl: PrivacyPolicyUrl
                  })
                "
              />
            </div>
            <div class="custom-control custom-checkbox pb-4">
              <wegow-checkbox
                id="terms-accepted-checkbox"
                v-model:value="model.newsletter"
                :title="$t('wegow.login.reciveNews')"
              />
            </div>
            <span v-if="signUpError" class="text-orange-errors"
              >El email ya se encuentra en uso</span
            >
            <wegow-button
              button-id="confirm-signup-button"
              background="opacity"
              color="topaz"
              :text="$t('wegow.login.signup')"
              :disabled="isDisabled"
              type="submit"
              class="btn btn-signup"
              @click-disabled="checkForm()"
            />
          </form>
        </div>
        <!-- <div class="or-title my-5">
          <span class="or-line">{{ $t("wegow.login.or") }}</span>
        </div>
        <div class="flex justify-center space-x-4">
          <img
            :src="require('@/assets/images/login-google.svg')"
            class="external-login-icon"
            alt="SignUp con Google"
          />
          <img
            :src="require('@/assets/images/login-spotify.svg')"
            class="external-login-icon"
            alt="SignUp con Spotify"
          />
          <img
            :src="require('@/assets/images/login-youtube.svg')"
            class="external-login-icon"
            alt="SignUp con Youtube"
          />
        </div> -->
        <div class="text-center">
          <p
            class="text-signup underline cursor-pointer mt-6"
            @click="openModal(SIGN_IN)"
          >
            {{ $t("wegow.login.haveAccount") }}
          </p>
        </div>
      </div>
    </div>
  </modal-template>
  <tiktok-pixel
    v-if="tiktokBeginPixel && env !== 'local'"
    :pixel="tiktokBeginPixel"
    type="Inicio Registro"
  />
  <tiktok-pixel
    v-if="tiktokPixel && env !== 'local'"
    :pixel="tiktokPixel"
    type="CompleteRegistration"
  />
</template>

<script setup>
import WegowButton from "@/components/general/forms/WegowButton";
import WegowCheckbox from "@/components/general/forms/WegowCheckbox";
import WegowInput from "@/components/general/forms/WegowInput";
import PhoneSelector from "@/components/general/forms/complex-forms/PhoneSelector";
import ModalTemplate from "@/components/general/modals/ModalTemplate.vue";
import WegowLoader from "@/components/general/ui/WegowLoader";
import TiktokPixel from "@/components/general/TiktokPixel.vue";

import { useUser } from "@/composables/useUser";
import { COUNTRIES } from "@/constants.js";
import { useModalHandler } from "@/plugins/useModalHandler";
import { computed, onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const { signUp } = useUser();
const router = useRouter();

const {
  activeModal,
  SIGN_UP,
  SIGN_IN,
  PROFILE_INFO,
  openModal,
  closeModal
} = useModalHandler();

const props = defineProps({
  isStatic: {
    type: Boolean,
    default: false
  },
  origin: {
    type: String,
    default: ""
  },
  newPromoter: {
    type: Boolean,
    default: false
  }
});

const modelTemplate = {
  email: undefined,
  emailConfirmation: undefined,
  password: undefined,
  phone: {
    country: {},
    phone: undefined
  },
  location: {
    id: 0,
    name: undefined,
    place_id: undefined,
    longitude: 0,
    latitude: 0
  },
  newsletter: false,
  terms_accepted: false
};

const model = reactive({ ...modelTemplate });

const loading = ref(false);
const canCloseModal = ref(true);
const showErrorAlways = ref(false);
const tiktokBeginPixel = ref(undefined);
const tiktokPixel = ref(undefined);
const env = ref(process.env.VUE_APP_NUXT_ENV);

const valid = reactive({
  email: false,
  password: false,
  confirmation: false,
  phone: false
});

const validators = {
  email: { validate: email => EMAIL_REGEX.test(email || "") },
  confirmation: {
    depends: [model],
    validate: confirmation => confirmation === model.email && valid.email
  },
  password: { validate: password => /^.{8,}$/.test(password || "") },
  phone: { validate: phone => /^\d{4,}$/.test(phone) }
};

const isDisabled = computed(
  () => Object.values(valid).includes(false) || !model.terms_accepted
);

const termsAndConditionsUrl = computed(() => {
  return router.resolve({
    name: "TermsOfUsePage",
    params: { region: "es" }
  }).href;
});

const PrivacyPolicyUrl = computed(() => {
  return router.resolve({
    name: "PrivacyPolicyPage",
    params: { region: "es" }
  }).href;
});

const resetModel = () => Object.assign(model, modelTemplate);
const signUpError = ref(null);

const checkForm = () => {
  showErrorAlways.value = true;
};

const signupEmail = async () => {
  try {
    loading.value = true;
    await signUp(model);
    gtag_report_conversion();
    tiktokPixel.value = {};
    resetModel();
    openModal(PROFILE_INFO);
  } catch (error) {
    signUpError.value = error;
  }
  loading.value = false;
};

onMounted(() => {
  model.phone.country = COUNTRIES.filter(country => country.iso2 === "es")[0];
  tiktokBeginPixel.value = {};
});

const gtag_report_conversion = () => {
  gtag("event", "conversion", {
    send_to: "AW-966208932/N2nbCKys5XAQpNvczAM",
    event_callback: () => {}
  });
};
</script>

<style scoped lang="scss">
#containerSignup {
  h3 {
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
  }
  .or-title {
    position: relative;
    display: block;
    width: 100%;
    margin: 10px 0;
    text-align: center;
    font-size: 16px;
    z-index: 1;
    overflow: hidden;
    &:before,
    &:after {
      content: "\a0";
      position: absolute;
      top: 51%;
      width: 25%;
      height: 1px;
      background-color: $white;
      overflow: hidden;
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
  }
}
</style>
<style lang="scss">
.checkbox-title a {
  color: $topaz;
}
</style>
