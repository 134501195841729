import { defineStore } from "pinia";

import WaitingRoomApi from "@/api/WaitingRoomApi";
import { deleteCookie, setCookie } from "@/plugins/utils/cookies";
import { PURCHASE_EXPIRED, PURCHASE_FINISHED } from "@/constants";

export const useWaitingRoomStore = defineStore("waitingRoom", {
  state: () => {
    return {
      token: undefined,
      event: null,
      tokenInfo: null
    };
  },
  actions: {
    async checkToken(slug, token) {
      try {
        this.tokenInfo = await WaitingRoomApi.checkToken(slug, token);
        if (this.tokenInfo?.purchase_status === PURCHASE_EXPIRED)
          throw Error("Invalid expired token");
        if (this.tokenInfo?.purchase_status === PURCHASE_FINISHED)
          throw Error("Invalid finished token");
      } catch {
        deleteCookie("wegow.wr-token");
        this.token = undefined;
        this.tokenInfo = null;
      }
      return this.tokenInfo;
    },
    async getToken(slug) {
      this.tokenInfo = await WaitingRoomApi.getToken(slug);
      setCookie("wegow.wr-token", this.tokenInfo.token);
      return (this.token = this.tokenInfo.token);
    },
    async getWaitingRoomEvent(slug) {
      return (this.event = await WaitingRoomApi.getWaitingRoomEvent(slug));
    }
  }
});
