<template>
    <div v-if="tooltipModal" class="tooltip" 
         :style="`left: ${tooltipModal?.position?.left}px; top: ${tooltipModal?.position?.top}px`">
        <span>{{ tooltipModal?.text }}</span>
    </div>
</template>

<script setup>
import { useModalHandler } from "@/plugins/useModalHandler";
const { tooltipModal } = useModalHandler();
</script>

<style lang="scss" scoped>
.tooltip {
  position: absolute;
  background-color: $white;
  color: $black;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  padding: 5px;
  border-radius: 5px;
  z-index: 20000;
  pointer-events: none;
  overflow: visible;
  font-weight: 200;
  padding: 20px;
}
</style>