import { defineStore } from "pinia";
import CampaignApi from "@/api/CampaignApi";

export const useCampaignStore = defineStore("campaign", {
  state: () => {
    return {
      details: {},
      campaignRows: [],
      campaignItems: [],
      campaignMedia: []
    };
  },
  getters: {},
  actions: {
    async getDetails(slug) {
      this.details = await CampaignApi.getCampaign(slug);
    },
    async getCampaignRows(slug) {
      this.campaignRows = await CampaignApi.getCampaignRows(slug);
    },
    async getCampaignItems(slug) {
      this.campaignItems = await CampaignApi.getCampaignItems(slug);
    },
    async getCampaignMedia(params) {
      this.campaignMedia = (await CampaignApi.getCampaignMedia(params)).media;
    },
    async followCampaign(params) {
      await CampaignApi.followCampaign(params);
    },
    async unfollowCampaign(params) {
      await CampaignApi.unfollowCampaign(params);
    }
  }
});
