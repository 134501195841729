import apiService from "@/api/ApiService";

const VENUE_URL = "/venues/";
const MEDIA_URL = "/media/";
const USER_VENUES_URL = "/user_venues/";

export class VenueService {
  async getVenue(slug) {
    return (await apiService.get(`${VENUE_URL}${slug}/`)).data;
  }

  async getVenues(params) {
    return (await apiService.get(VENUE_URL, { params })).data;
  }

  async getUserVenues(params) {
    return (await apiService.get(USER_VENUES_URL, { params })).data;
  }

  async followVenue(params) {
    return await apiService.post(USER_VENUES_URL, params);
  }

  async unfollowVenue(params) {
    return await apiService.delete(USER_VENUES_URL, { params });
  }

  async getVenueMedia(params) {
    return (await apiService.get(MEDIA_URL, { params })).data.media;
  }
}

const venueService = new VenueService();

export default venueService;
