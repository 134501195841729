import axios from "axios";

const BASE_URL =
  "https://5wrgpkrxj5.execute-api.us-east-1.amazonaws.com/waiting-room";

export class WaitingRoomService {
  async getToken(slug) {
    const response = await axios.get(`${BASE_URL}/${slug}/token`);
    return response.data;
  }

  async checkToken(slug, token) {
    const response = await axios.get(`${BASE_URL}/${slug}/token/${token}`);
    return response.data;
  }

  async getWaitingRoomEvent(slug) {
    const response = await axios.get(`${BASE_URL}/${slug}`);
    return response.data;
  }
}

const waitingRoomService = new WaitingRoomService();

export default waitingRoomService;
