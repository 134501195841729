import { defineStore } from "pinia";
import CompanyApi from "@/api/CompanyApi";

export const useCompanyStore = defineStore("company", {
  state: () => {
    return {
      details: {},
      companies: [],
      media: []
    };
  },

  actions: {
    async getCompany(slug) {
      this.details = await CompanyApi.getCompany(slug);
      const today = new Date();
      this.details.events = this.details.events.filter(
        event =>
          event.enabled &&
          !event.closed &&
          new Date(event.end_date || event.start_date) > today
      );
      this.details.events.sort(
        (a, b) => new Date(a.start_date) - new Date(b.start_date)
      );
    },
    async getCompanies(params) {
      this.companies = await CompanyApi.getCompanies(params);
    },
    async followCompany(params) {
      await CompanyApi.followCompany(params);
    },
    async unfollowCompany(params) {
      await CompanyApi.unfollowCompany(params);
    },
    async getCompanyMedia(params) {
      this.media = await CompanyApi.getCompanyMedia(params);
    }
  }
});
