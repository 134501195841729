import { storeToRefs } from "pinia";
import { computed } from "vue";
import { VueCookieNext } from "vue-cookie-next";
import moment from "moment";
import { useRouter } from "vue-router";

import { useUserStore } from "@/stores/modules/user";
import { useGeneralStore } from "@/stores/modules/general";
import { useModalHandler } from "@/plugins/useModalHandler";

export const useUser = () => {
  const $cookie = VueCookieNext;
  const router = useRouter();

  const userStore = useUserStore();
  const {
    loadingInit,
    loadingTickets,
    gettingPerfectCompanion,
    userTokens,
    userDetail,
    user,
    list,
    listUsers,
    genres,
    userStatus,
    artistFollowers,
    eventFollowers,
    venueFollowers,
    companyFollowers,
    campaignFollowers,
    usersLikedMoment,
    cards,
    location,
    started,
    perfectCompanion,
    myTickets,
    weswapEvents,
    invoices,
    purchasesCoverage,
    serverError,
    maintenancePage
  } = storeToRefs(userStore);
  const generalStore = useGeneralStore();
  const { openErrorModal, closeModal } = useModalHandler();

  const PRIVACY_PUBLIC = "0";
  const PRIVACY_ONLY_FRIENDS = "1";
  const PRIVACY_PRIVATE = "2";

  const initUser = async () => {
    // if (!$cookie.getCookie("wegow.new_cache")) {
    //   deleteCookies();
    //   let match_expression =
    //     process.env.VUE_APP_NUXT_ENV !== "local" ? "https://www" : "http://www";

    //   $cookie.setCookie("wegow.new_cache", true, {
    //     path: "/",
    //     expire: 60 * 60 * 24 * 365,
    //     domain: process.env.VUE_APP_NUXT_HOST.replace(match_expression, ""),
    //     secure: process.env.VUE_APP_NUXT_ENV !== "local"
    //   });
    // }
    try {
      let expiryToken =
        new Date($cookie.getCookie("wegow.expiry_token")).getTime() - 30000;
      if (
        $cookie.getCookie("wegow.refresh_token") &&
        $cookie.getCookie("wegow.expiry_token") &&
        expiryToken < new Date().getTime()
      ) {
        let refresParams = {
          refresh: $cookie.getCookie("wegow.refresh_token")
        };
        let newCookies = await userStore.refresh_token(refresParams);

        let match_expression =
          process.env.VUE_APP_NUXT_ENV !== "local"
            ? "https://www"
            : "http://www";
        $cookie.setCookie("wegow.access_token", newCookies.access_token, {
          path: "/",
          expire: 60 * 60 * 24 * 365,
          domain: process.env.VUE_APP_NUXT_HOST.replace(match_expression, ""),
          secure: process.env.VUE_APP_NUXT_ENV !== "local"
        });
        $cookie.setCookie("wegow.expiry_token", newCookies.expiry_token, {
          path: "/",
          expire: 60 * 60 * 24 * 365,
          domain: process.env.VUE_APP_NUXT_HOST.replace(match_expression, ""),
          secure: process.env.VUE_APP_NUXT_ENV !== "local"
        });
      }
      if ($cookie.getCookie("wegow.access_token")) {
        await userStore.getMyUser();
      }
    } catch (error) {
      deleteCookies();
    }
  };

  const showMaintenancePage = () => {
    userStore.showMaintenancePage();
  };

  const init = async (isEvent = undefined) => {
    loadingInit.value = true;
    // if (isEvent !== undefined) {
    await userStore.init();
    // }
    // if (!serverError.value && isEvent !== true) {
    await initUser();
    await generalStore.getNavbar();
    await generalStore.getFooter();
    // }
    loadingInit.value = false;
  };
  const simpleInit = async () => {
    loadingInit.value = true;
    await userStore.init();
    loadingInit.value = false;
  };
  const signIn = async data => {
    let payload = {};
    if (data.email) {
      payload = {
        email: data.email.toLowerCase(),
        password: data.password,
        type: "email",
        sign_up: false,
        first_name: "",
        last_name: ""
      };
    } else if (data.authResponse) {
      // TODO: añadir google
      // payload = {
      //   fb_token: response.authResponse.accessToken,
      //   type: "facebook",
      //   first_name: "",
      //   last_name: ""
      // };
    }
    await userStore.auth(payload);

    setCookies();

    await userStore.getMyUser();

    let match_expression =
      process.env.VUE_APP_NUXT_ENV !== "local" ? "https://www" : "http://www";
    $cookie.setCookie(
      "wegow.user-region",
      userDetail.value.region || userDetail.value.language || "es",
      {
        path: "/",
        expire: 60 * 60 * 24 * 365,
        domain: process.env.VUE_APP_NUXT_HOST.replace(match_expression, ""),
        secure: process.env.VUE_APP_NUXT_ENV !== "local"
      }
    );

    // .catch(res => {
    //   this.userIsAuthenticated = false;
    //   var errorMessage =
    //     res &&
    //     res.response.data &&
    //     res.response.data.detail &&
    //     res.response.data.detail.length > 0
    //       ? res.response.data.detail
    //       : "Ups, algo no ha ido bien";

    //   if (typeof window !== "undefined") {
    //     if (typeof CustomEvent === "function") {
    //       var event = new CustomEvent("showError", {
    //         detail: {
    //           text: errorMessage
    //         }
    //       });
    //       window.dispatchEvent(event);
    //     }
    //   }
    // });
  };

  const sendBuyer = async data => {
    var payload = {};
    if (data.email) {
      payload = {
        email: data?.email?.toLowerCase() || "",
        first_name: data.first_name,
        last_name: data.last_name,
        newsletter: data.newsletter,
        privacy_policy: data.terms_accepted,
        birthday: data.birthday
          ? moment(data.birthday).format("YYYY-MM-DD")
          : undefined,
        location: data.location
      };
    } else {
      payload = {
        first_name: data.first_name,
        last_name: data.last_name,
        newsletter: data.newsletter,
        privacy_policy: data.terms_accepted,
        birthday: data.birthday
          ? moment(data.birthday).format("YYYY-MM-DD")
          : undefined,
        location: data.location
      };
    }

    await userStore.sendBuyer(payload);
  };

  const signUp = async (data, purchase = false) => {
    let payload = {};
    if (data.email) {
      payload = {
        data: {
          email: data.email.toLowerCase(),
          password: data.password,
          newsletter: data.newsletter,
          terms_accepted: data.terms_accepted,
          location: data.location ? data.location : undefined,
          sign_up: true,
          first_name: data.first_name || "",
          last_name: data.last_name || "",
          birthday: data.birthday || undefined,
          genre: data.genre || undefined,
          type: "email",
          phone_number: data.phone.phone,
          phone_cou_code: data.phone.country.iso2,
          phone_prefix: data.phone.country.dialCode
        },
        purchase: purchase
      };
    }
    await userStore.auth(payload.data);

    setCookies();

    await userStore.getMyUser();

    let match_expression =
      process.env.VUE_APP_NUXT_ENV !== "local" ? "https://www" : "http://www";
    $cookie.setCookie(
      "wegow.user-region",
      userDetail.value.region || userDetail.value.language || "es",
      {
        path: "/",
        expire: 60 * 60 * 24 * 365,
        domain: process.env.VUE_APP_NUXT_HOST.replace(match_expression, ""),
        secure: process.env.VUE_APP_NUXT_ENV !== "local"
      }
    );
    // if (data.location) {
    //   await userStore.validatePromoter();
    // }

    // .catch(res => {
    //   this.userIsAuthenticated = false;
    //   var errorMessage =
    //     res &&
    //     res.response.data &&
    //     res.response.data.detail &&
    //     res.response.data.detail.length > 0
    //       ? res.response.data.detail
    //       : "Ups, algo no ha ido bien";

    //   if (typeof window !== "undefined") {
    //     if (typeof CustomEvent === "function") {
    //       var event = new CustomEvent("showError", {
    //         detail: {
    //           text: errorMessage
    //         }
    //       });
    //       window.dispatchEvent(event);
    //     }
    //   }
    // });
  };

  const updateUser = async data => {
    let payload = {
      id: data.id,
      email: data?.email?.toLowerCase(),
      password: data?.password,
      newsletter: data?.newsletter,
      terms_accepted: data?.terms_accepted,
      location: data?.location ? data?.location : undefined,
      first_name: data?.first_name,
      last_name: data?.last_name,
      gender: data?.gender,
      birthday: data?.birthday,
      phone_number: data?.phone?.phone,
      phone_cou_code: data?.phone?.country?.iso2,
      phone_prefix: data?.phone?.country?.dialCode,
      language: data?.language,
      region: data?.region
    };
    await userStore.updateUser(payload);
    if (data.newImage) {
      const img = new FormData();
      img.append("image", data.newImage);
      payload = {
        id: data.id,
        data: img
      };
      await userStore.updateImageUser(payload);
    }
  };

  const validatePromoter = async () => {
    await userStore.validatePromoter();
  };

  const getChatMembers = async params => {
    await userStore.getChatMembers(params);
  };

  const setProfile = async data => {
    let payload = {
      id: userDetail.value.id,
      first_name: data.first_name,
      last_name: data.last_name,
      genre: data.genre,
      birthday: data.birthday,
      location: data.location
    };
    await userStore.updateUser(payload);
    userStatus.value.profile = true;
    if (!data.image) return;
    const img = new FormData();
    img.append("image", data.image);
    payload = { id: userDetail.value.id, data: img };
    await userStore.updateImageUser(payload);
  };

  const updateSettings = async data => {
    let payload = {
      new_password: data.new_password,
      old_password: data.old_password
    };
    await userStore.updateAccount(payload);
  };

  const updatePrivacy = async newStatus => {
    const payload = { profile_status: newStatus };
    await userStore.updatePrivacy(payload);
  };

  const closeSession = async () => {
    deleteCookies();
    await userStore.closeSession();
    window.location.reload();
  };

  const setCookies = () => {
    const match_expression =
      process.env.VUE_APP_NUXT_ENV !== "local" ? "https://www" : "http://www";
    const config = {
      path: "/",
      expire: 60 * 60 * 24 * 365,
      domain: process.env.VUE_APP_NUXT_HOST.replace(match_expression, ""),
      secure: process.env.VUE_APP_NUXT_ENV !== "local"
    };
    $cookie.setCookie("wegow.auth_token", userTokens.value.token, config);
    $cookie.setCookie(
      "wegow.access_token",
      userTokens.value.access_token,
      config
    );
    $cookie.setCookie(
      "wegow.expiry_token",
      userTokens.value.expiry_token,
      config
    );
    $cookie.setCookie(
      "wegow.refresh_token",
      userTokens.value.refresh_token,
      config
    );
  };

  const deleteCookie = (domain, cookie) => {
    if (!$cookie.getCookie(cookie)) return false;
    $cookie.removeCookie(cookie, {
      path: "/",
      expires: new Date(0),
      domain: domain,
      secure: process.env.VUE_APP_NUXT_ENV !== "local"
    });
    return true;
  };

  const deleteCookies = () => {
    const domain = process.env.VUE_APP_NUXT_HOST.replace(
      process.env.VUE_APP_NUXT_ENV !== "local" ? "https://www" : "http://www",
      ""
    );
    const cookies = [
      "wegow.auth_token",
      "wegow.access_token",
      "wegow.refresh_token",
      "wegow.expiry_token",
      "csrftoken",
      "sessionid"
    ];
    cookies.forEach(cookie => deleteCookie(domain, cookie));
    userDetail.value = {};
    userStatus.value = {};
  };

  const followGenre = async id => {
    const payload = { id: userDetail.value.id, genres: [] };

    payload.genres = userDetail.value.genres.map(genre => genre.id);

    if (payload.genres.includes(id)) {
      payload.genres.splice(payload.genres.indexOf(id), 1);
    } else {
      payload.genres.push(id);
    }
    await userStore.followGenre(payload);
  };

  const followGenres = async genres => {
    const payload = { id: userDetail.value.id, genres };
    await userStore.followGenre(payload);
    userStatus.value.genres = true;
  };

  const getFriends = async data => {
    let params = {
      page_size: data.page_size || 20,
      page: data.page || 1,
      friends_with: data.id,
      "friend-name": data.search
    };
    await userStore.getFriends(params);
  };
  const getSearchUsers = async data => {
    let params = {
      page_size: data.page_size || 20,
      page: data.page || 1,
      "friend-name": data.search
    };
    await userStore.getSearchUsers(params);
  };

  const getUser = async slug => {
    await userStore.getUser(slug);
  };
  const getUsers = async () => {
    await userStore.getUsers();
  };

  const deleteLocation = async payload => {
    await userStore.deleteLocation(payload);
  };

  const deleteAccount = async () => {
    await userStore.deleteAccount();
    deleteCookies();
    router.push({
      name: "home"
    });
  };

  const getArtistFollowers = async data => {
    const params = {
      artists: data.id
    };
    await userStore.getArtistFollowers(params);
  };

  const getUsersLikedMoment = async params => {
    await userStore.getUsersLikedMoment(params);
  };

  const getEventFollowers = async data => {
    const params = {
      events: data.id,
      page_size: 20,
      count: true
    };
    await userStore.getEventFollowers(params);
  };

  const getVenueFollowers = async data => {
    const params = {
      venue: data.id
    };
    await userStore.getVenueFollowers(params);
  };

  const getCampaignFollowers = async data => {
    const params = {
      campaigns: data.id
    };
    await userStore.getCampaignFollowers(params);
  };

  const getMyTickets = async (params = undefined) => {
    loadingTickets.value = true;
    await userStore.getMyTickets(params);
    loadingTickets.value = false;
  };

  const getTicketsWeswap = async (
    groupByEvent = false,
    params,
    loading = true
  ) => {
    if (loading !== false) {
      loadingTickets.value = true;
    }
    try {
      await userStore.getTicketsWeswap(groupByEvent, params);
    } catch (error) {
      console.error(error);
    }
    if (loading) {
      loadingTickets.value = false;
    }
  };

  const getEventInvoices = async eventId => {
    await userStore.getEventInvoices({ event: eventId });
  };

  const getEventPurchasesCoverage = async eventId => {
    await userStore.getEventPurchasesCoverage({ event: eventId });
  };

  const sendFriendshipRequest = async user => {
    let params = {
      other: user.id,
      status: "request_sent",
      newStatus: "request_sent"
    };
    await userStore.setFriendshipStatus(params);
    if (user.friendship) {
      user.friendship.status = "request_sent";
    } else {
      user.friendship = {
        status: "request_sent"
      };
    }
  };
  const acceptFriendshipRequest = async user => {
    let params = {
      other: user.id,
      status: "friends",
      newStatus: "friends"
    };
    await userStore.setFriendshipStatus(params);
    if (user.friendship) {
      user.friendship.status = "friends";
    } else {
      user.friendship = {
        status: "friends"
      };
    }
  };
  const deleteFriendship = async user => {
    let params = {
      other: user.id
    };
    await userStore.deleteFriendship(params);
    if (user.friendship) {
      user.friendship.status = "none";
    } else {
      user.friendship = {
        status: "none"
      };
    }
  };

  const getPerfectCompanion = async () => {
    gettingPerfectCompanion.value = true;
    await userStore.getPerfectCompanion();
    gettingPerfectCompanion.value = false;
  };

  const addLocation = async payload => {
    await userStore.addLocation(payload);
  };

  const forgetPassword = async email => {
    let payload = {
      email: email
    };
    try {
      await userStore.forgetPassword(payload);
      openErrorModal({
        modal: {
          imageType: "accept",
          title: "wegow.login.sendEmail",
          description: "wegow.login.sendEmailSubtitle",
          close_method: () => {
            closeModal();
          }
        }
      });
    } catch (error) {
      openErrorModal({
        modal: {
          title: "general.error",
          description: "wegow.login.noResetPassword"
        }
      });
    }
  };

  const validateTokenPassword = async token => {
    let payload = {
      token: token
    };
    await userStore.validateTokenPassword(payload);
  };

  const changePassword = async (password, token) => {
    let payload = {
      password: password,
      token: token
    };
    try {
      await userStore.changePassword(payload);
      openErrorModal({
        modal: {
          title: "wegow.login.passwordChanged",
          description: "wegow.login.passwordChangedSubtitle",
          btn_message: "wegow.headers.goToWegow",
          close_method: () => {
            closeModal();
          },
          button_method: () => {
            closeModal();
          }
        }
      });
    } catch (error) {
      openErrorModal({
        modal: {
          title: "general.error",
          description: "wegow.login.notValidPassword",
          btn_message: "general.accept"
        }
      });
    }
  };

  const validateTokenUnsubscribe = async token => {
    let payload = {
      token: token
    };
    await userStore.validateTokenUnsubscribe(payload);
  };

  const unsubscribeEmails = async token => {
    let payload = {
      token: token
    };
    try {
      await userStore.unsubscribeEmails(payload);
      openErrorModal({
        modal: {
          title: "wegow.login.unsubscribeCorrect",
          btn_message: "wegow.headers.goToWegow",
          close_method: () => {
            closeModal();
          },
          button_method: () => {
            closeModal();
          }
        }
      });
    } catch (error) {
      openErrorModal({
        modal: {
          title: "general.error"
        }
      });
    }
  };

  const confirmEmail = async code => {
    try {
      let message = await userStore.confirmEmail(code);
      openErrorModal({
        modal: {
          imageType: "info",
          title: message
        }
      });
    } catch (error) {
      openErrorModal({
        modal: {
          title: "general.error"
        }
      });
    }
  };
  const selectedRegion = () => {
    // Realmente es selectedLanguaje
    if ($cookie.getCookie("wegow.user-language")) {
      return $cookie.getCookie("wegow.user-language");
    } else if (userDetail.value.language) {
      return userDetail.value.language;
    } else if (
      $cookie.getCookie("wegow.user-region") &&
      $cookie.getCookie("wegow.user-region").length === 2
    ) {
      return $cookie.getCookie("wegow.user-region");
    } else {
      return "es";
    }
  };

  return {
    PRIVACY_ONLY_FRIENDS,
    PRIVACY_PRIVATE,
    PRIVACY_PUBLIC,
    loadingInit,
    loadingTickets,
    gettingPerfectCompanion,
    //! Properties
    userDetail,
    user,
    list,
    listUsers,
    genres,
    userStatus,
    artistFollowers,
    eventFollowers,
    venueFollowers,
    companyFollowers,
    campaignFollowers,
    usersLikedMoment,
    cards,
    location,
    started,
    perfectCompanion,
    myTickets,
    weswapEvents,
    invoices,
    purchasesCoverage,
    serverError,
    maintenancePage,

    //! Computed
    isAuth: computed(() => JSON.stringify(userDetail.value) !== "{}"),
    // imgSrc: computed(
    //   () =>
    //     `https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/user/other/dream-world/${user.value?.id}.svg`
    // ),

    //! Metodos
    showMaintenancePage,
    initUser,
    init,
    simpleInit,
    signIn,
    signUp,
    closeSession,
    getUser,
    getUsers,
    setProfile,
    updateUser,
    updatePrivacy,
    updateSettings,
    followGenre,
    followGenres,
    getChatMembers,
    getFriends,
    getSearchUsers,
    deleteLocation,
    addLocation,
    deleteAccount,
    getArtistFollowers,
    getEventFollowers,
    getCampaignFollowers,
    getVenueFollowers,
    getMyTickets,
    getTicketsWeswap,
    getEventInvoices,
    getEventPurchasesCoverage,
    sendFriendshipRequest,
    acceptFriendshipRequest,
    deleteFriendship,
    getPerfectCompanion,
    sendBuyer,
    getUsersLikedMoment,
    forgetPassword,
    validateTokenPassword,
    changePassword,
    validateTokenUnsubscribe,
    unsubscribeEmails,
    confirmEmail,
    validatePromoter,
    selectedRegion,
    deleteCookies
  };
};
