import apiService from "@/api/ApiService";

const CAMPAIGNS = "/campaigns/";
const CAMPAIGN_ROWS = "/campaign-rows/";
const CAMPAIGN_ITEMS = "/campaign-items/";
const MEDIA = "/media/";
const USER_CAMPAIGN = "/user_campaigns/";

export class CampaignService {
  async getCampaign(slug) {
    return (await apiService.get(`${CAMPAIGNS}${slug}/`)).data;
  }

  async getCampaignRows(slug) {
    return (await apiService.get(`${CAMPAIGN_ROWS}${slug}/`)).data;
  }

  async getCampaignItems(slug) {
    return (await apiService.get(`${CAMPAIGN_ITEMS}${slug}/`)).data;
  }

  async getCampaignMedia(params) {
    return (await apiService.get(MEDIA, { params })).data;
  }

  async followCampaign(params) {
    return await apiService.post(USER_CAMPAIGN, params);
  }

  async unfollowCampaign(params) {
    return await apiService.delete(USER_CAMPAIGN, { params });
  }
}

const campaignService = new CampaignService();

export default campaignService;
