<template>
  <modal-template
    v-if="activeModal === BASIC_INFO_MODAL"
    size="intermedium-small"
    :is-static="false"
    @close="closeModal"
  >
    <wegow-loader v-if="loading" class="load-more-loader" :middle="true" />
    <div
      v-else
      id="containerBuyer"
      class="container-onboarding mx-4 pb-8 pt-12 md:mx-16"
    >
      <h3 class="mb-8 text-center">
        {{ $t("wegow.login.completeProfiletitle") }}
      </h3>
      <div>
        <div>
          <form class="space-y-2" @submit.prevent="signupEmail">
            <wegow-input
              id="profile-user-firstname"
              v-model:value="model.first_name"
              :placeholder="$t('wegow.profile.settings.firstName')"
              :show-error-always="showErrorAlways"
              :validator="validators.first_name"
              @valid="valid.first_name = $event"
            />
            <wegow-input
              id="profile-user-lastname"
              v-model:value="model.last_name"
              :placeholder="$t('wegow.profile.settings.lastName')"
              :show-error-always="showErrorAlways"
              :validator="validators.last_name"
              @valid="valid.last_name = $event"
            />
            <wegow-input
              id="profile-user-birthdate"
              v-model:value="model.birthday"
              type="date"
              :error-message="valid.birthday ? '' : 'wegow.login.birthdayError'"
              :show-error-always="showErrorAlways"
              :validator="validators.birthday"
              @valid="valid.birthday = $event"
            />
            <cities-search
              id="profile-user-location"
              v-model:value="model.location"
              :placeholder="$t('wegow.profile.settings.formLocation')"
            />
            <div class="md:mx-14 mx-3 py-4 flex flex-row justify-between">
              <w-radio
                id="genre_radio_male"
                v-model:group-value="model.genre"
                value="m"
                name="genre"
                label="Hombre"
              />
              <w-radio
                id="genre_radio_female"
                v-model:group-value="model.genre"
                value="f"
                name="genre"
                label="Mujer"
              />
              <w-radio
                id="genre_radio_other"
                v-model:group-value="model.genre"
                name="genre"
                label="Otro"
              />
            </div>
            <div class="flex justify-center">
              <wegow-button
                button-id="confirm-signup-button"
                background="opacity"
                color="topaz"
                :text="$t('general.next')"
                :disabled="isDisabled"
                type="submit"
                class="btn btn-signup"
                @click-disabled="checkForm()"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  </modal-template>
</template>

<script setup>
import WegowButton from "@/components/general/forms/WegowButton";
import WRadio from "@/components/general/forms/WRadio.vue";
import WegowInput from "@/components/general/forms/WegowInput";
import ModalTemplate from "@/components/general/modals/ModalTemplate.vue";
import WegowLoader from "@/components/general/ui/WegowLoader";
import CitiesSearch from "@/components/general/forms/selectors/CitiesSearch.vue";

import { useUser } from "@/composables/useUser";
import { usePurchase } from "@/composables/usePurchase";
import { useModalHandler } from "@/plugins/useModalHandler";
import { computed, reactive, ref, watch } from "vue";

const { userDetail, setProfile } = useUser();
const { createPurchase } = usePurchase();

const { activeModal, BASIC_INFO_MODAL, closeModal } = useModalHandler();

const modelTemplate = ref({
  first_name: userDetail.value.first_name,
  last_name: userDetail.value.last_name,
  birthday: userDetail.value.birthday || undefined,
  location: userDetail.value.location || {
    id: 0,
    name: "",
    place_id: "",
    longitude: 0,
    latitude: 0
  },
  gender: userDetail.value.gender || undefined
});

const model = reactive({ ...modelTemplate.value });
const loading = ref(false);
const showErrorAlways = ref(false);

const valid = reactive({
  first_name: false,
  last_name: false,
  birthday: false
});

const validators = ref({
  first_name: { validate: first_name => first_name.length > 0 },
  last_name: { validate: last_name => last_name.length > 0 },
  birthday: { validate: birthday => validateBirthday(birthday) }
});
const validateBirthday = birthday => {
  try {
    let fechaActual = new Date();

    let partesFecha = birthday.split("-");
    let fechaCumple = new Date(
      partesFecha[0],
      partesFecha[1] - 1,
      partesFecha[2]
    );
    let diferenciaTiempo = fechaActual - fechaCumple;

    let edadEnAnios = Math.floor(
      diferenciaTiempo / (1000 * 60 * 60 * 24 * 365.25)
    );

    if (edadEnAnios >= 0) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};

const isDisabled = computed(
  () =>
    Object.values(valid).includes(false) ||
    !model.location ||
    model.location.name === ""
);

const checkForm = () => {
  showErrorAlways.value = true;
};

const signupEmail = async () => {
  try {
    loading.value = true;
    await setProfile(model);
    await createPurchase(true);
    closeModal();
  } catch (error) {
    console.log("error::", error);
  }
  loading.value = false;
};

watch(activeModal, async (newValue, _oldValue) => {
  if (newValue === BASIC_INFO_MODAL) {
    if (userDetail.value.first_name) {
      model.first_name = userDetail.value.first_name;
      valid.first_name = true;
    }
    if (userDetail.value.last_name) {
      model.last_name = userDetail.value.last_name;
      valid.last_name = true;
    }
    model.birthday = userDetail.value.birthday || undefined;
    model.location = userDetail.value.location || {
      id: 0,
      name: "",
      place_id: "",
      longitude: 0,
      latitude: 0
    };
    model.gender = userDetail.value.gender || undefined;
  }
});
</script>

<style scoped lang="scss">
#containerBuyer {
  h3 {
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
  }
}
</style>
